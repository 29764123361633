import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import {
  Box,
  Button,
  Container,
  Input,
  Pagination,
  Stack,
} from "@mui/material";
import { Typography } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { format } from "date-fns";
import Swal from "sweetalert2";
import Invalid from "../../images/undraw/undraw_warning_re_eoyh.svg";
import ReConfirm from "../../images/undraw/undraw_confirmation_re_b6q5.svg";

const Traveller = () => {
  const users = secureLocalStorage.getItem("user-info");
  const navigate = useNavigate();
  const agentID = users?.user?.agentId;
  const [travellers, setTravellers] = useState([]);
  const [search, setSearch] = useState([]);
  // todo: pagination handle
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  let size = 20;

  useEffect(() => {
    let url = `https://api.flyjatt.com/v1/Traveller/index.php?agentId=${agentID}`;

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        data.data?.map((item, index) => (item.serial = index + 1));
        const count = data.data.length;
        const pageNumber = Math.ceil(count / size);
        setPageCount(pageNumber);
        setTravellers(data.data);
        setSearch(data.data);
      });
  }, [agentID, size]);
  //todo: Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setSearch(travellers?.slice((value - 1) * size, value * size));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  //  todo:search functionality handle
  // const handelDelete = (e) => {
  //   console.log("click");
  // };

  async function handelDelete(id, paxId) {
    Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      title: "Are you sure?",
      text: "You wants to delete this traveller ?",
      showCancelButton: true,
      confirmButtonColor: "var(--primary-color)",
      confirmButtonText: "Yes delete it !",
      cancelButtonColor: "crimson",
      cancelButtonText: "Don't delete it !",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        // setIsDone(false);
        // setOpen(false);
        // setIsLoading(false);
        let url = `https://api.flyjatt.com/v1/Traveller/index.php?delete`;
        let body = {
          id: id,
          agentId: agentID,
          paxId: paxId,
        };
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data?.status.toLowerCase() === "success") {
              Swal.fire({
                // imageUrl: Issue,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: "Custom image",
                title: "Delete this traveller",
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              }).then(function () {
                // setIsDone(true);
                // setIssueLoading(false);
                navigate(0);
              });
            } else {
              Swal.fire({
                imageUrl: Invalid,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: "Custom image",
                title: `${data?.message}`,
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              }).then(function () {
                // setIsDone(true);
                // setIssueLoading(false);
                navigate(0);
              });
            }
            // setIsLoading(false);
          })
          .catch((err) => {
            Swal.fire({
              imageUrl: Invalid,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              title: "Issue Ticket Failed!",
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            }).then(function () {
              // setIsDone(true);
              // setIssueLoading(false);
              navigate(0);
            });
          });
      }
    });
  }

  const handelSearchItems = (e) => {
    let searchInput = e.target.value;
    if (searchInput !== "") {
      const filterData = travellers.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });

      setSearch(filterData);
    } else if (searchInput === "") {
      setSearch(travellers);
    }
  };

  return (
    <Box>
      <Header />
      <Container>
        <Typography
          variant="span"
          sx={{
            fontWeight: 500,
            fontSize: "24px",
            margin: "30px 0px",
            color: "#222222",
            fontFamily: "poppins",
          }}
        >
          Travelers
        </Typography>
        <Box
          sx={{
            margin: "10px 0px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="span"
            sx={{
              fontWeight: 400,
              fontSize: "16px",
              color: "var(--primary-color)",
              fontFamily: "poppins",
            }}
          >
            You can find your all travelers here
          </Typography>

          <Box sx={{ display: "flex", gap: 5 }}>
            <Button
              sx={{
                width: "161px",
                fontSize: "14px",
                textTransform: "capitalize",
                height: "36px",
                background: "var(--secondary-color)",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "var(--secondary-color)",
                },
              }}
            >
              <Link
                style={{ textDecoration: "none", color: "#fff" }}
                to={"/user/addtraveller"}
              >
                Add Travelers
              </Link>
            </Button>
          </Box>
        </Box>
        <Box className="balance-transaction" marginTop={"20px"}>
          <table>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th>Gender</th>
              <th>Type</th>
              <th>DOB</th>
              <th>Nationality</th>
              <th>Passport No</th>
              <th>Passport Expire Date</th>
              {/* <th>Passport Copy</th>
              <th>Emali</th> */}
              <th>Action </th>
              <th>Phone </th>
            </tr>
            {search?.slice(0, size).map((traveller, index) => (
              <tbody>
                <tr>
                  <td>{traveller?.serial}</td>
                  <td className="fixed-table-row">
                    {traveller?.fName || "Name"} {traveller?.lName}{" "}
                  </td>
                  <td>{traveller?.gender || "gender"}</td>
                  <td>
                    {traveller?.type === "ADT" ? (
                      <>Adult</>
                    ) : traveller?.type === "CNN" ? (
                      <>Child</>
                    ) : (
                      <>Infant</> || "Type"
                    )}
                  </td>
                  <td>
                    {traveller?.dob !== "0000-00-00"
                      ? traveller?.dob
                        ? format(new Date(traveller?.dob), "dd MMM yyyy")
                        : "DOB"
                      : "Invalid Date"}
                  </td>
                  <td>{traveller?.passNation || "Nationality"}</td>
                  <td>{traveller?.passNo || "Passport No"}</td>
                  <td>
                    {traveller?.passEx !== "0000-00-00"
                      ? traveller?.passEx
                        ? format(new Date(traveller?.passEx), "dd MMM yyyy")
                        : "Expire Date"
                      : "Invalid Date"}
                  </td>
                  <td>
                    <Button
                      size="small"
                      onClick={() =>
                        handelDelete(traveller.id, traveller.paxId)
                      }
                    >
                      Delete
                    </Button>
                  </td>
                  {/* <td>{traveller?.email || "Email"}</td> */}
                  <td>{traveller?.phone || "Phone"}</td>
                </tr>
              </tbody>
            ))}
          </table>

          <Box
            sx={{
              width: "100%",
              my: 3,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack spacing={2}>
              <Pagination
                count={pageCount}
                onChange={handlePageChange}
                shape="rounded"
              />
            </Stack>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Traveller;
