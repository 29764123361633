import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useNavigate, Outlet, Link, NavLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ViewDayIcon from "@mui/icons-material/ViewDay";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import LogoutIcon from "@mui/icons-material/Logout";
import TollIcon from "@mui/icons-material/Toll";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";

import SignUp from "../SignUp/SignUp";
import SignIn from "../SignIn/SignIn";
// import QueuesPage from "../Queues/QueuesPage";
import AuthProvider from "../Contexts/AuthProvider";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "../../App";
import { borderRadius, color, width } from "@mui/system";
import useAuthentication from "../../hooks/useAuthentication";
import "./UserDashboardSideBar.css";

const drawerWidth = 230;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 20px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(["width", "margin"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(["width", "margin"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const UserDashboardSideBar = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const [subManu, setSubmenu] = useState("");
  const [subManuActive, setSubMenuActive] = useState("");
  const [active, setactive] = useState("");

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const { logout } = useAuthentication();

  return (
    <Box sx={{ display: "flex" }} className="sideBar-user">
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          style={{
            background: "var(--secondary-color)",
            display: "block",
            // borderRight: "5px solid var(--secondary-color)",
          }}
        >
          <Box onClick={() => setOpen(!open)}>
            <MenuIcon
              style={{
                color: "var(--white)",
                fontSize: "28px",
                margin: "20px 15px 10px 15px",
              }}
            />
          </Box>
        </DrawerHeader>

        <List
          style={{
            height: "100vh",
            overflowY: "hidden",
            overflowX: "hidden",
            background: "var(--secondary-color",
            borderRight: "5px solid var(--secondary-color)",
          }}
        >
          {/* dashboard  home */}

          <ListItem disablePadding sx={{ display: "block" }}>
            <NavLink
              to="/user/dashboard"
              className={({ isActive }) =>
                isActive ? "active-nav" : "normal-nav"
              }
              onClick={() => setSubmenu("Dashboard")}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  width: "90%",
                  borderTopRightRadius: "5px",
                  borderBottomRightRadius: "5px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <HomeIcon
                    id="NavIcon"
                    style={{
                      fontSize: "28px",
                      margin: "10px 0px",
                      color: "var(--white)",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id="NavText"
                  primary="Dashboard"
                  sx={{
                    opacity: open ? 1 : 0,
                    color: "var(--white)",
                  }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>

          {/* Booking  */}
          <Box>
            <ListItem disablePadding sx={{ display: "block" }}>
              <NavLink
                to={"/user/queues"}
                className={({ isActive }) =>
                  isActive ? "active-nav" : "normal-nav"
                }
                onClick={() => {
                  setSubmenu("Booking");
                  setSubMenuActive("AirTicket");
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    width: "90%",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <TollIcon
                      onClick={() => setOpen(!open)}
                      id="NavIcon"
                      style={{
                        fontSize: "28px",
                        margin: "10px 0px",
                        color: "var(--white)",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id="NavText"
                    primary="Booking"
                    sx={{
                      opacity: open ? 1 : 0,
                      color: "var(--white)",
                    }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
            <Box
              style={{
                display: subManu === "Booking" ? "" : "none",
                background: open ? "var(--white)" : "",
              }}
              className="user-DashSubManu"
            >
              <Box
                className="user-DashSubManuChild"
                sx={{ opacity: open ? 1 : 0 }}
              >
                <NavLink
                  to={"/user/queues"}
                  style={{
                    display: "block",
                    background:
                      subManuActive === "AirTicket"
                        ? "var(--secondary-color)"
                        : "",
                    color: subManuActive === "AirTicket" ? "var(--white)" : "",
                  }}
                  onClick={() => {
                    setSubMenuActive("AirTicket");
                  }}
                >
                  Air Ticket
                </NavLink>
                <NavLink
                  to={"/user/others"}
                  style={{
                    display: "block",
                    background:
                      subManuActive === "Other" ? "var(--secondary-color)" : "",
                    color: subManuActive === "Other" ? "var(--white)" : "",
                  }}
                  onClick={() => {
                    setSubMenuActive("Other");
                  }}
                >
                  Other
                </NavLink>

                <NavLink
                  style={{
                    display: "block",
                    background:
                      subManuActive === "Hotel" ? "var(--secondary-color)" : "",
                    color: subManuActive === "Hotel" ? "var(--white)" : "",
                  }}
                  onClick={() => {
                    setSubMenuActive("Hotel");
                  }}
                >
                  Hotel
                </NavLink>
                <NavLink
                  style={{
                    display: "block",
                    background:
                      subManuActive === "Tour" ? "var(--secondary-color)" : "",
                    color: subManuActive === "Tour" ? "var(--white)" : "",
                  }}
                  onClick={() => {
                    setSubMenuActive("Tour");
                  }}
                >
                  Tour
                </NavLink>
              </Box>
            </Box>
          </Box>

          {/* manage  */}
          <Box>
            <ListItem disablePadding sx={{ display: "block" }}>
              <NavLink
                to={"/user/traveller"}
                className={({ isActive }) =>
                  isActive ? "active-nav" : "normal-nav"
                }
                onClick={() => {
                  setSubmenu("Manage");
                  setSubMenuActive("Traveler");
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    width: "90%",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ManageAccountsIcon
                      onClick={() => setOpen(!open)}
                      id="NavIcon"
                      style={{
                        fontSize: "28px",
                        margin: "10px 0px",
                        color: "var(--white)",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id="NavText"
                    primary="Manage"
                    sx={{
                      opacity: open ? 1 : 0,
                      color: "var(--white)",
                    }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
            <Box
              style={{
                display: subManu === "Manage" ? "" : "none",
                background: open ? "var(--white)" : "",
              }}
              className="user-DashSubManu"
            >
              <Box
                className="user-DashSubManuChild"
                sx={{ opacity: open ? 1 : 0 }}
              >
                <NavLink
                  to={"/user/traveller"}
                  style={{
                    display: "block",
                    background:
                      subManuActive === "Traveler"
                        ? "var(--secondary-color)"
                        : "",
                    color: subManuActive === "Traveler" ? "var(--white)" : "",
                  }}
                  onClick={() => {
                    setSubMenuActive("Traveler");
                  }}
                >
                  Traveler
                </NavLink>
                <NavLink
                  style={{
                    display: "block",
                    background:
                      subManuActive === "Markup"
                        ? "var(--secondary-color)"
                        : "",
                    color: subManuActive === "Markup" ? "var(--white)" : "",
                  }}
                  onClick={() => {
                    setSubMenuActive("Markup");
                  }}
                >
                  Markup
                </NavLink>
                <NavLink
                  style={{
                    display: "block",
                    background:
                      subManuActive === "PartialPayment"
                        ? "var(--secondary-color)"
                        : "",
                    color:
                      subManuActive === "PartialPayment" ? "var(--white)" : "",
                  }}
                  onClick={() => {
                    setSubMenuActive("PartialPayment");
                  }}
                >
                  Partial Payment
                </NavLink>
              </Box>
            </Box>
          </Box>

          {/* Account */}
          <Box>
            <ListItem disablePadding sx={{ display: "block" }}>
              <NavLink
                to="/user/bankaccount"
                className={({ isActive }) =>
                  isActive ? "active-nav" : "normal-nav"
                }
                onClick={() => {
                  setSubmenu("Account");
                  setSubMenuActive("AddBank");
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    width: "90%",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <AccountBalanceIcon
                      onClick={() => setOpen(!open)}
                      id="NavIcon"
                      style={{
                        fontSize: "26px",
                        margin: "10px 0px",
                        color: "var(--white)",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id="NavText"
                    primary="Account"
                    sx={{
                      opacity: open ? 1 : 0,
                      color: "var(--white)",
                    }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>

            <Box
              style={{
                display: subManu === "Account" ? "" : "none",
                background: open ? "var(--white)" : "",
              }}
              className="user-DashSubManu"
            >
              <Box
                className="user-DashSubManuChild"
                sx={{ opacity: open ? 1 : 0 }}
              >
                <NavLink
                  to={"/user/bankaccount"}
                  style={{
                    display: "block",
                    background:
                      subManuActive === "AddBank"
                        ? "var(--secondary-color)"
                        : "",
                    color: subManuActive === "AddBank" ? "var(--white)" : "",
                  }}
                  onClick={() => {
                    setSubMenuActive("AddBank");
                  }}
                >
                  Add Bank
                </NavLink>
                <NavLink
                  to={"/user/deposit"}
                  style={{
                    display: "block",
                    background:
                      subManuActive === "Deposit"
                        ? "var(--secondary-color)"
                        : "",
                    color: subManuActive === "Deposit" ? "var(--white)" : "",
                  }}
                  onClick={() => {
                    setSubMenuActive("Deposit");
                  }}
                >
                  Deposit
                </NavLink>
              </Box>
            </Box>
          </Box>

          {/* Report  */}
          <Box>
            <ListItem disablePadding sx={{ display: "block" }}>
              <NavLink
                to={"/user/transection"}
                className={({ isActive }) =>
                  isActive ? "active-nav" : "normal-nav"
                }
                onClick={() => {
                  setSubmenu("Report");
                  setSubMenuActive("Transection");
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    width: "90%",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <AssessmentIcon
                      onClick={() => setOpen(!open)}
                      id="NavIcon"
                      style={{
                        fontSize: "28px",
                        margin: "10px 0px",
                        color: "var(--white)",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id="NavText"
                    primary="Report"
                    sx={{
                      opacity: open ? 1 : 0,
                      color: "var(--white)",
                    }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
            <Box
              style={{
                display: subManu === "Report" ? "" : "none",
                background: open ? "var(--white)" : "",
              }}
              className="user-DashSubManu"
            >
              <Box
                className="user-DashSubManuChild"
                sx={{ opacity: open ? 1 : 0 }}
              >
                <NavLink
                  to={"/user/transection"}
                  style={{
                    display: "block",
                    background:
                      subManuActive === "Transection"
                        ? "var(--secondary-color)"
                        : "",
                    color:
                      subManuActive === "Transection" ? "var(--white)" : "",
                  }}
                  onClick={() => {
                    setSubMenuActive("Transection");
                  }}
                >
                  Transection
                </NavLink>
                <NavLink
                  to={"/user/ledger"}
                  style={{
                    display: "block",
                    background:
                      subManuActive === "Ledger"
                        ? "var(--secondary-color)"
                        : "",
                    color: subManuActive === "Ledger" ? "var(--white)" : "",
                  }}
                  onClick={() => {
                    setSubMenuActive("Ledger");
                  }}
                >
                  Ledger
                </NavLink>
              </Box>
            </Box>
          </Box>

          {/* logout */}
          <ListItem disablePadding sx={{ display: "block" }}>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-nav" : "normal-nav"
              }
              to={"/"}
              onClick={() => {
                setSubMenuActive("Logout");
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  width: "90%",
                  borderTopRightRadius: "5px",
                  borderBottomRightRadius: "5px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <LogoutIcon
                    id="NavIcon"
                    style={{
                      fontSize: "28px",
                      margin: "10px 0px",
                      color: "var(--white)",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id="NavText"
                  primary="Logout"
                  sx={{
                    opacity: open ? 1 : 0,
                    color: "var(--white)",
                  }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
        </List>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1 }}>
        <Outlet></Outlet>
      </Box>
    </Box>
  );
};

export default UserDashboardSideBar;
