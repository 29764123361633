import { Skeleton } from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Slider from "react-slick";
import "./FullScreenSlider.css";

const FullScreenSlider = () => {
  const [agentId, setAgentId] = useState("");
  const [siteConfig, setSiteConfig] = useState({});
  useEffect(() => {
    axios
      .get(
        `https://api.flyjatt.com/v.1.0.0/WhiteLabel/MyAccount/all.php?website=${window.location.hostname.replace(
          "www.",
          ""
        )}`
      )
      .then((res) => {
        setSiteConfig(res.data);
        setAgentId(res?.data?.agentId);
      });
  }, []);

  const settings = {
    arrows: false,
    dots: true,
    fade: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <Container>
      <Box
        sx={{
          margin: "50px 0px",
          display: { md: "block", sm: "none", xs: "none" },
        }}
        className="full-screen-slider"
      >
        <Slider {...settings}>
          {[...new Array(5)].map((item, index) => (
            <Box key={index} className="slider-item">
              {siteConfig[`slider_img${index + 1}`] ? (
                <img
                  src={`http://cdn.flyfarint.com/WL/${agentId}/${
                    siteConfig[`slider_img${index + 1}`]
                  }`}
                  alt="..."
                />
              ) : (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                  }}
                />
              )}
            </Box>
          ))}
        </Slider>
      </Box>
    </Container>
  );
};

export default FullScreenSlider;
