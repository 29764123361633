/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from "react";
import { Box, Button, Grid, Tab, Tabs, Container } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import moment from "moment";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--transit)",
    maxWidth: 300,
    padding: "8px",
  },
}));

const Transit = ({ transit }) => {
  const transitCalculation = (date1, date2) => {
    const duration = moment.duration(moment(date1).diff(moment(date2)));

    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) - hours * 60;
    const transit = `${Math.abs(hours)}h:${Math.abs(minutes)}min`;
    return transit;
  };
  const depTime = transit?.Segments[0]?.DepTime?.split("+")[0];
  const arrTime =
    transit?.Segments[transit?.Segments?.length - 1]?.ArrTime?.split("+")[0];
  const data = transit?.Segments;

  return (
    <Box textAlign={"center"} pr={1}>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Box display={"flex"}>
              {transit?.Segments?.map((item, index, arr) => (
                <Box
                  borderRight={
                    index === arr?.length - 2
                      ? "0"
                      : "2px solid var(--secondary-color)"
                  }
                  px={1}
                  sx={{
                    display: `${index === arr?.length - 1 ? "none" : "block"}`,
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--secondary-color)",
                      fontSize: "12px",
                      textAlign: "center",
                      fontWeight: 500,
                    }}
                  >
                    Layover Time:{" "}
                    {transitCalculation(
                      data[index + 1]?.DepTime?.split("+")[0],
                      data[index - 1 + 1]?.ArrTime?.split("+")[0]
                    )}
                    <br />
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      {data[index + 1]?.DepFrom}{" "}
                    </span>
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--secondary-color)",
                      fontSize: "11px",
                      fontWeight: 500,
                    }}
                  >
                    {data[index + 1]?.DepLocation?.slice(0, -2)} <br />
                    {data[index + 1]?.MarketingCarrier}{" "}
                    {data[index + 1]?.MarketingFlightNumber}
                    <br />
                    {new Date(
                      data[index + 1]?.DepTime?.split("+")[0]
                    ).toLocaleString("en-uk", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })}
                  </Typography>
                </Box>
              ))}
            </Box>
          </React.Fragment>
        }
        followCursor
      >
        <Box>
          <Typography
            sx={{
              color: "var(--gray)",
              fontWeight: 500,
              fontSize: {
                xs: "10px",
                sm: "10px",
                md: "12px",
              },
            }}
          >
            {transitCalculation(depTime, arrTime)}
          </Typography>
          <Box className="stop-bar-parent">
            <CircleIcon
              sx={{
                color: "var(--transit)",
                fontSize: "14px",
                zIndex: 2,
              }}
            />

            {data?.map((d, k, carr) => (
              <CircleIcon
                sx={{
                  display: `${k === carr?.length - 1 ? "none" : "block"}`,
                  color: "var(--primary-color)",
                  fontSize: "12px",
                  zIndex: 2,
                }}
              />
            ))}

            <CircleIcon
              sx={{
                color: "var(--transit)",
                fontSize: "14px",
                zIndex: 2,
              }}
            />
            <Box className="stop-bar-line"></Box>
          </Box>
          <Typography
            sx={{
              color: "var(--gray)",
              fontWeight: 500,
              fontSize: {
                xs: "12px",
                sm: "10px",
                md: "12px",
              },
            }}
          >
            {`${data?.length - 1} Stop`}
          </Typography>
        </Box>
      </HtmlTooltip>
    </Box>
  );
};

export default React.memo(Transit);
