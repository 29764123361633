import React, { useState, useEffect, useRef } from "react";
import { Container } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import {
  Box,
  Grid,
  Button,
  Typography,
  Stack,
  Pagination,
  Skeleton,
} from "@mui/material";
import Swal from "sweetalert2";
import { addDays } from "date-fns/esm";
import { format } from "date-fns";
import Tooltip from "@mui/material/Tooltip";
import secureLocalStorage from "react-secure-storage";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Search from "../../images/undraw/undraw_web_search_re_efla.svg";
import SingleFlight from "../../components/SingleFlight/SingleFlight";
import OneWayFilter from "../../components/OneWayFilter";
import OneWayFilterDrawer from "../../components/OneWayFilterDrawer";
// import FlightSearchBox from "../../components/FlightSearchBox/FlightSearchBox";
import Commission from "../../components/Commission";
import Preloader from "../../components/Preloader/Preloader";
import SessionTimer from "../../components/Shared/SessionTimer/SessionTimer";
import styled from "@emotion/styled";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightSearchBox from "../../components/FlightSearchBox/FlightSearchBox";
// import Header from "../../components/Header/Header";
import { AirlineSlider } from "../../components/AirlineSlider/AirlineSlider";
import "../SearchReslut/SearchResult.css";
import Header from "./../../components/Header/Header";

const modalStyle = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "70vw", md: "90vw", sm: "90vw", xs: "90vw" },
  height: "fit-content",
  padding: "20px",
  background: "#fff",
  borderRadius: "10px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
};

const SearchResult = () => {
  const commissionData = secureLocalStorage.getItem("commissionData");
  const [noData, setNoData] = useState("No Data");
  const searchData = secureLocalStorage.getItem("search-data");
  const user = secureLocalStorage.getItem("user-info");
  const navigate = useNavigate();
  const location = useLocation();
  console.log("searchData", location.state);
  const requiredSearchData = location.state;

  const {
    toSendData,
    adultCount,
    childCount,
    departureDate,
    returningDate,
    infant,
    tripType,
    faddress,
    toAddress,
    fromSearchText,
    toSearchText,
    fromSendData,
    className,
    directFlightOnly,
    handleSearch,
  } = requiredSearchData;

  const [changeState, setChangeState] = useState(
    requiredSearchData?.changeState
  );

  const multicity = secureLocalStorage.getItem("multi-city");
  const [multiCitySearchData, setMultiCitySearchData] = useState(
    multicity !== null
      ? multicity.searchData
      : {
          agentId: user?.user?.agentId || "",
          adultCount: adultCount,
          childCount: childCount,
          infantCount: infant,
          CityCount: 1,
          segments: [
            {
              id: 0,
              openFrom: false,
              DepFrom: fromSendData.trim(),
              depFromText: fromSearchText.trim(),
              ArrTo: "DXB",
              arrToText: "Dubai Intl Airport [DXB]",
              openTo: false,
              Date: new Date().toLocaleDateString("sv"),
              openDate: false,
              open: false,
            },
          ],
        }
  );

  //todo: state for from date change
  const [changeFrom, setChangeFrom] = useState(false);
  //todo: End state for from date change

  const [options, setOptions] = useState({
    showCalendarAvailability: false,
    directFlightOnly: false,
    includeCheckedBaggage: false,
  });

  //todo: End for retrigger useEffect
  const [changeStateSession, setChangeStateSession] = useState(null);
  const [type, setType] = React.useState("flight");
  const [value, setValue] = React.useState(tripType);
  const [oneWayFromSearchText, setOneWayFromSearchText] =
    useState(fromSearchText);
  const [oneWayToSearchText, setOneWayToSearchText] = useState(toSearchText);

  const now = useRef(new Date(departureDate));
  const [from, setFrom] = useState(now.current);
  const [to, setTo] = useState(addDays(now.current, 3));
  const [fromSearchDate, setFromSearchDate] = useState(new Date(departureDate));
  const [toSearchDate, setToSearchDate] = useState(returningDate);
  const [oneWayFaddress, setOneWayFaddress] = useState(faddress);
  const [oneWayToAddress, setOneWayToAddress] = useState(toAddress);
  const [oneWayFromSendData, setOneWayFromSendData] = useState(fromSendData);
  const [oneWayToSendData, setOneWayToSendData] = useState(toSendData);
  const [oneWayAdultCount, setOneWayAdultCount] = useState(adultCount);
  const [oneWayChildCount, setOneWayChildCount] = useState(childCount);
  const [oneWayInfant, setOneWayInfant] = useState(infant);
  const [result, setResult] = useState(adultCount + childCount + infant);
  const [oneWayClassName, setOneWayClassName] = useState(className);
  const [isPrevClicked, setIsPrevCliked] = useState(false);
  const [isNextClicked, setIsNextCliked] = useState(false);
  //end

  //CM Box States

  const [openCm, setOpenCm] = useState(false);
  const [agentFarePrice, setAgentFarePrice] = useState(true);
  const [commisionFarePrice, setCommisionFarePrice] = useState(true);
  const [defaultCommissionRate, setDefaultCommissionRate] = useState(7);
  const [defaultCommissionRateAmount, setDefaultCommissionRateAmount] =
    useState(0);
  const [customerFare, setCustomerFare] = useState(true);

  //end
  const [modifyOpen, setModifyOpen] = useState(false);
  const modifyHandleOpen = () => setModifyOpen(true);
  const modifyHandleClose = () => setModifyOpen(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchDate, setSearchDate] = useState(
    new Date(from).toLocaleDateString("sv")
  );
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  const [departureLocation, setDepartureLocation] = useState("");
  const departureLocationCode =
    location?.state?.fromSendData?.slice(1, 5) ||
    searchData.fromSendData?.slice(1, 5);

  const [arrivalLocation, setArrivalLocation] = useState("");

  // todo:next day previous day variables
  let tomorrow = new Date(fromSearchDate);
  tomorrow.setDate(tomorrow.getDate() + 1);
  let yesterday = new Date(fromSearchDate);
  yesterday.setDate(yesterday.getDate() - 1);

  // Sets the state of the const for the given page and state.
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  let size = 30;

  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setData2(data?.slice((value - 1) * size, value * size));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  //todo Searches for flights today's date.
  useEffect(() => {
    let unSubscribed = false;
    setIsLoaded(false);
    modifyHandleClose();
    let url = "https://api.flyjatt.com/v1/Flight/airsearch.php";
    let body = {
      agentId: user?.user?.agentId || "FJA100000",
      adultCount: adultCount,
      childCount: childCount,
      infantCount: infant,
      connection: directFlightOnly ? 1 : "",
      cabinclass: className || "Y",
      segments: [
        {
          id: 0,
          openFrom: false,
          depAddress: faddress || "",
          depFromText: toAddress || "",
          arrAddress: faddress || "",
          arrToText: toAddress || "",
          openTo: false,
          openDate: false,
          open: false,
          DepFrom: `${fromSendData?.replace(/\s+/g, "")}`,
          ArrTo: toSendData,
          Date: `${new Date(departureDate).toLocaleDateString("sv")}`,
        },
      ],
    };

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        return res.json();
      })

      .then((data) => {
        if (!unSubscribed) {
          if (data.status !== "error") {
            const uniqueData = data;
            const count = uniqueData.length;
            const pageNumber = Math.ceil(count / size);
            setPageCount(pageNumber);
            setData(uniqueData);
            setData2(uniqueData);
            setIsLoaded(true);
          } else {
            Swal.fire({
              imageUrl: Search,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              title: "No Flights Found",
              confirmButtonText: "Search Again...",
              confirmButtonColor: "var(--primary-color)",
            }).then(function () {
              navigate("/#searchbar");
            });
          }
        }
      })
      .catch(async (err) => {
        await Swal.fire({
          imageUrl: Search,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "No Flights Found",
          confirmButtonText: "Search Again...",
          confirmButtonColor: "var(--primary-color)",
        }).then(function () {
          navigate("/#searchbar");
        });
      });
    return () => {
      unSubscribed = true;
    };
  }, [
    changeState,
    changeStateSession,
    size,
    departureDate,
    fromSendData,
    toSendData,
    adultCount,
    childCount,
    infant,
    tripType,
    requiredSearchData,
    navigate,
  ]);

  return (
    <Box>
      <Header />
      <Box mt={2}>
        <Container sx={{ position: "relative" }}>
          <Grid container columnSpacing={2}>
            <Grid
              item
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "block",
                },
              }}
              md={2.7}
            >
              {isLoaded ? (
                <OneWayFilter
                  data={data}
                  setData={setData}
                  filteredData={data2}
                  setfilteredData={setData2}
                  noData={noData}
                  setNoData={setNoData}
                  departureDate={departureDate}
                  setFrom={setFrom}
                  setPageCount={setPageCount}
                />
              ) : (
                <Box
                  style={{
                    height: "100%",
                    width: "100%",
                    margin: "10px 0px",
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                  />
                </Box>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={9.3}>
              <Box>
                <Grid container>
                  {/* //todo: show search result section*/}
                  <Grid container className="modify-search" columnSpacing={2}>
                    <Grid item md={8} lg={8} mb={2}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <FlightTakeoffIcon
                          style={{
                            width: "25px",
                            height: "25px",
                            padding: "5px",
                            backgroundColor: "var(--primary-color)",
                            color: "var(--white)",
                            borderRadius: "100%",
                          }}
                        />
                        <Typography
                          sx={{
                            color: "var(--secondary-color)",
                            fontSize: "24px",
                            ml: 1,
                          }}
                        >
                          Flight Search Result
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          color: "var(--secondary-color)",
                          fontSize: "15px",
                        }}
                      >
                        {fromSearchText || ""}
                        <span> - </span>
                        {toSearchText || ""}
                      </Typography>
                      <Typography
                        sx={{
                          color: "var(--gray)",
                          fontSize: "13px",
                        }}
                      >
                        {format(
                          new Date(
                            isNextClicked || isPrevClicked
                              ? fromSearchDate
                              : fromSearchDate
                          ),
                          "dd MMM yyyy"
                        )}
                        <span> | </span>{" "}
                        {adultCount > 0 && `Adult(${adultCount})`}
                        {childCount > 0 && `Children(${childCount})`}
                        {infant > 0 && `Infant(${infant})`}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {/* //todo:session timer  */}
                      <Tooltip title="Session Time">
                        <Button
                          style={{
                            width: "fit-content",
                            border: "1.2px solid var(--secondary-color)",
                            color: "var(--secondary-color)",
                          }}
                        >
                          <SessionTimer />
                        </Button>
                      </Tooltip>
                      <Box>
                        <Button
                          onClick={modifyHandleOpen}
                          style={{
                            backgroundColor: "var(--secondary-color)",
                            color: "var(--white)",
                            padding: "5px 10px",
                            cursor: "pointer",
                          }}
                        >
                          Modify Search
                        </Button>

                        <Modal open={modifyOpen} onClose={modifyHandleClose}>
                          <Container>
                            <Box sx={modalStyle}>
                              <FlightSearchBox
                                options={options}
                                setOptions={setOptions}
                                type={type}
                                setType={setType}
                                value={value}
                                setValue={setValue}
                                fromSearchText={oneWayFromSearchText}
                                setFromSearchText={setOneWayFromSearchText}
                                toSearchText={oneWayToSearchText}
                                setToSearchText={setOneWayToSearchText}
                                from={from}
                                setFrom={setFrom}
                                to={to}
                                setTo={setTo}
                                faddress={oneWayFaddress}
                                setfaddress={setOneWayFaddress}
                                toAddress={oneWayToAddress}
                                setToAddress={setOneWayToAddress}
                                fromSendData={oneWayFromSendData}
                                setFromSendData={setOneWayFromSendData}
                                toSendData={oneWayToSendData}
                                setToSendData={setOneWayToSendData}
                                adultCount={oneWayAdultCount}
                                setAdultCount={setOneWayAdultCount}
                                childCount={oneWayChildCount}
                                setChildCount={setOneWayChildCount}
                                infant={oneWayInfant}
                                setInfant={setOneWayInfant}
                                result={result}
                                setResult={setResult}
                                className={oneWayClassName}
                                setClassName={setOneWayClassName}
                                changeFrom={changeFrom}
                                setChangeFrom={setChangeFrom}
                                searchData={multiCitySearchData}
                                setSearchData={setMultiCitySearchData}
                                directFlightOnly={directFlightOnly}
                                changeState={changeState}
                                setChangeState={setChangeState}
                                // handleSearch={handleSearch}
                              />
                            </Box>
                          </Container>
                        </Modal>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{ mt: 0.5, mb: 1.5 }}
                    >
                      {/* <PriceSlider
                      agentId={user?.user?.agentId}
                      tripType={tripType}
                      depFrom={`${fromSendData?.replace(/\s+/g, "")}`}
                      depDate={`${new Date(departureDate).toLocaleDateString(
                        "sv"
                      )}`}
                      arrTo={`${toSendData?.replace(/\s+/g, "")}`}
                      arrDate={`${new Date(returningDate).toLocaleDateString(
                        "sv"
                      )}`}
                      adultCount={adultCount}
                      childCount={childCount}
                      infantCount={infant}
                      data={data}
                      setData={setData}
                      data2={data2}
                      setData2={setData2}
                      setPageCount={setPageCount}
                      setIsLoaded={setIsLoaded}
                      setFromSearchDate={setFromSearchDate}
                      setToSearchDate={setToSearchDate}
                    /> */}
                    </Grid>
                    {/* <Grid item md={12} sm={12} xs={12} sx={{ my: 0.5 }}>
                    <AirlineSlider
                      data={data}
                      setData={setData}
                      filteredData={data2}
                      setfilteredData={setData2}
                    />
                  </Grid> */}
                  </Grid>

                  {/* //todo:main search result */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      height: "fit-content",
                    }}
                  >
                    {isLoaded
                      ? data2?.slice(0, size).map((data, index) => {
                          return (
                            <SingleFlight
                              key={index}
                              flightData={data}
                              tripType={tripType}
                              adultCount={adultCount}
                              childCount={childCount}
                              infant={infant}
                              from={fromSendData}
                              to={toSendData}
                              fromAddress={faddress}
                              toAddress={toAddress}
                              dDate={searchDate}
                              agentFarePrice={agentFarePrice}
                              setAgentFarePrice={setAgentFarePrice}
                              commisionFarePrice={commisionFarePrice}
                              setCommisionFarePrice={setCommisionFarePrice}
                              customerFare={customerFare}
                              setCustomerFare={setCustomerFare}
                            />
                          );
                        })
                      : [...new Array(5)].map((data, index) => (
                          <Box
                            key={index}
                            style={{
                              width: "100%",
                              height: "150px",
                              margin: "10px 0px",
                              borderRadius: "5px",
                              overFlow: "hidden",
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={"100%"}
                              height={"100%"}
                            />
                          </Box>
                        ))}
                  </Grid>
                  {/* //todo: pagination*/}
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        width: "100%",
                        my: 3,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Stack spacing={2}>
                        <Pagination
                          count={pageCount}
                          onChange={handlePageChange}
                          shape="rounded"
                          color="primary"
                        />
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default SearchResult;
