import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
  Menu,
  ClickAwayListener,
  Avatar,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import AddIcon from "@mui/icons-material/Add";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import commaNumber from "comma-number";
import { Container } from "@mui/system";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import useAuthentication from "../../hooks/useAuthentication";
import { GiTwoCoins } from "react-icons/gi";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import "./Header.css";

const Header = () => {
  const user = secureLocalStorage.getItem("user-info");
  const { logout } = useAuthentication();
  const location = useLocation();
  const navigate = useNavigate();
  const [balance, setBalance] = useState(0);
  const [openSearch, setOpenSearch] = useState(false);
  const [agentId, setAgentId] = useState("");
  const [userData, setUserData] = useState([]);
  const [subAgent, setSubAgent] = useState({});
  // console.log(subAgent);

  // subAgent
  useEffect(() => {
    fetch(
      `https://api.flyjatt.com/v.1.0.0/WhiteLabel/SubAgent/MyAccount.php?agentId=${user?.user?.agentId}&subagentId=${user?.user?.subagentId}`
    )
      .then((res) => res.json())
      .then((data) => {
        setSubAgent(data[0]);
        setBalance(data[0]?.balance);
      });
  }, [user?.user?.agentId, user?.user?.subagentId]);

  // admin
  useEffect(() => {
    let url = `https://api.flyjatt.com/v.1.0.0/Accounts/MyAccount.php?agentId=${agentId}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setUserData(data[0]);
      });
  }, [agentId]);

  // todo: for mobile device
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleClickAway = () => {};

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        sx={{
          position: "relative",
          borderBottom: "1px solid var(--secondary-color)",
        }}
      >
        <Container>
          <Box
            style={{
              height: "70px",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* //todo:Logo  */}
            <Box
              sx={{
                width: { lg: "20%", md: "20%", sm: "50%", xs: "50%" },
                bgcolor: "var(--white)",
                height: "100%",
                display: "flex",
                justifyContent: {
                  lg: "start",
                  md: "start",
                  sm: "center",
                  xs: "center",
                },
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              <Box
                sx={{ height: "60px", display: "flex", alignItems: "center" }}
              >
                <Typography
                  noWrap
                  sx={{
                    color: "var(--primary-color)",
                    fontSize: "calc(15px + 0.390625vw)",
                  }}
                >
                  Fly Jatt
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                width: "80%",
                height: "70px",
                display: { md: "flex", sm: "none", xs: "none" },
                alignItems: "center",
                justifyContent: "end",
                gap: "10px",
                position: "relative",
              }}
            >
              <CloseIcon
                style={{
                  display: openSearch ? "block" : "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenSearch(false);
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  background: "var(--primary-color)",
                  color: "var(--white)",
                  borderRadius: "20px",
                  "& input": {
                    display: openSearch ? "block" : "none",
                    border: "none",
                    outline: "none",
                    fontSize: "14px",
                    background: "transparent",
                    color: "var(--secondary-color)",
                  },
                }}
              >
                <input type="text" name="" placeholder="Search..." />
                <SearchIcon
                  style={{
                    fontSize: "24px",
                    color: "var(--white)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenSearch(true);
                  }}
                />
              </Box>
              <Box
                sx={{
                  padding: "5px 20px",
                  background: "var(--secondary-color)",
                  color: "var(--white)",
                  borderRadius: "20px",
                }}
              >
                ৳ balance
              </Box>
            </Box>
            {/* //todo:Normal Mobile Navbar */}
            <Box
              sx={{
                width: "80%",
                height: "70px",
                display: { md: "none", sm: "flex", xs: "flex" },
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Tooltip title="Open Menu">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar>
                    {/* {user?.user?.name[0].toUpperCase() || ""} */}F
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleCloseUserMenu}>
                  <Box
                    sx={{
                      color: "var(--secondary-color)",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      fontSize: "17px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Tooltip title={`Balance: ${commaNumber(balance)}৳`}>
                      <Box>
                        <GiTwoCoins style={{ fontSize: "30px" }} />
                      </Box>
                    </Tooltip>
                    <Box>{commaNumber(balance)}৳</Box>
                    <NavLink
                      to="/adddeposite"
                      style={{
                        color: "var(--secondary-color)",
                        cursor: "pointer",
                      }}
                    >
                      <Tooltip title="Add Money">
                        <AddIcon style={{ fontSize: "30px" }} />
                      </Tooltip>
                    </NavLink>
                  </Box>
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu}>
                  <Button
                    style={{
                      background: "var(--mateBlack)",
                      color: "var(--white)",
                      fontWeight: 400,
                      padding: "10px",
                      cursor: "pointer",
                      width: "100%",
                      zIndex: "999",
                    }}
                    onClick={() => {
                      logout();
                    }}
                  >
                    <LogoutIcon />
                    Logout
                  </Button>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Container>
      </Box>
    </ClickAwayListener>
  );
};

export default Header;
