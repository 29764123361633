import { Box, Container, Typography } from "@mui/material";
import React, { useState } from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TabContext from "@material-ui/lab/TabContext";
import "./Markup.css";
import Flight from "./Flight";
import AdminHeader from "../../Admin/AdminHeader/AdminHeader";

const Markup = () => {
  const [desire2, setDesire2] = useState(0);
  return (
    <Box>
      <AdminHeader />
      <Container>
        <Typography
          my={2}
          style={{
            color: "var(--mateBlack)",
            fontSize: "22px",

            fontWeight: "500",
          }}
        >
          Add Markup
        </Typography>
        <Box className="markUp">
          <Box className="parent-markUp">
            <Box>
              <Tabs
                selectedIndex={desire2}
                onSelect={(index) => setDesire2(index)}
              >
                <TabList
                  style={{
                    paddingLeft: "0px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "13px",
                    marginBottom: "5px",
                  }}
                >
                  <Box style={{ display: "flex", gap: "11px" }}>
                    <Tab>Flight </Tab>
                    <Tab>Group Fare </Tab>
                    <Tab>Hotel </Tab>
                    <Tab>Tour</Tab>
                    <Tab>Visa</Tab>
                  </Box>
                </TabList>
                <TabPanel>
                  <TabContext>
                    <Flight />
                  </TabContext>
                </TabPanel>
                <TabPanel>
                  <TabContext>
                    <Flight />
                  </TabContext>
                </TabPanel>
                <TabPanel>
                  <TabContext>
                    <Flight />
                  </TabContext>
                </TabPanel>
                <TabPanel>
                  <TabContext>
                    <Flight />
                  </TabContext>
                </TabPanel>
              </Tabs>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Markup;
