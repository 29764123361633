import { Box } from "@mui/system";
import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import NoEncryptionGmailerrorredOutlinedIcon from "@mui/icons-material/NoEncryptionGmailerrorredOutlined";
import secureLocalStorage from "react-secure-storage";
import useAuthentication from "../../hooks/useAuthentication";
import Footer from "../Footer/Footer";
import { LandingHeader } from "../LandingHeader/LandingHeader";
import loginBg from "../../images/loginPage/loginBg.jpg";
import loginPattern from "../../images/loginPage/loginPattern.jpg";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState([]);

  const rememberUser = secureLocalStorage.getItem("remember");
  const [loginData, setLoginData] = useState({
    email: rememberUser?.email || "",
    password: rememberUser?.password || "",
  });

  const { loginUser, isLoading, error, adminLogin } = useAuthentication();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [checkBox, setCheckBox] = useState(rememberUser?.isChecked);
  const handleCheckBox = (e) => {
    setCheckBox(e.target.checked);
    if (e.target.checked) {
      secureLocalStorage.setItem("remember", {
        email: loginData.email,
        password: loginData.password,
        isChecked: e.target.checked,
      });
    } else {
      secureLocalStorage.removeItem("remember");
    }
  };

  const handleOnChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newLoginData = { ...loginData };
    newLoginData[field] = value;
    setLoginData(newLoginData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser(loginData, location, navigate);
    e.target.reset();
  };

  return (
    <Box>
      <Box sx={{ height: "100vh", width: "100vw", display: "flex" }}>
        <Box
          sx={{
            width: "50%",
            height: "100%",
            backgroundImage: `url(${loginBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
          }}
        ></Box>
        <Box
          className="login-box"
          sx={{
            width: "50%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: { md: "350px" },
              height: "fit-content",
              background: "var(--white)",
              padding: "0px",
              borderRadius: "20px",
            }}
          >
            <form onSubmit={handleSubmit}>
              <Box
                my={2}
                sx={{
                  width: "100%",
                  height: "60px",
                  background: "var(--primary-color)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "end",
                  borderRadius: "20px 20px 0px 0px",
                  position: "relative",
                  marginTop: "0px",
                }}
              >
                <GppGoodOutlinedIcon
                  sx={{
                    position: "absolute",
                    top: "-10%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    background: "white",
                    color: "var(--primary-color)",
                    padding: "10px",
                    fontSize: "50px",
                    borderRadius: "50%",
                  }}
                />
                <Typography
                  style={{
                    color: "var(--secondary-color)",
                    fontSize: "20px",
                    fontWeight: "normal",
                    textTransform: "capitalize",
                  }}
                >
                  Login
                </Typography>
              </Box>
              <Grid container spacing={2} sx={{ padding: "0px 20px" }}>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography
                    style={{
                      color: "var(--secondary-color)",
                      fontSize: "14px",
                      fontWeight: "normal",
                      textTransform: "capitalize",
                      textAlign: "center",
                    }}
                  >
                    Sign In to continue to the F
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Box sx={{ position: "relative" }}>
                    <input
                      placeholder="Enter Your Email ID"
                      value={loginData.email}
                      name="email"
                      type="email"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      onChange={handleOnChange}
                      style={{
                        height: "40px",
                        width: "calc(100% - 20px)",
                        outline: "none",
                        border: "1px solid black",
                        borderRadius: "20px",
                        padding: "0px 10px",
                        color: "var(--secondary-color)",
                        background: "transparent",
                        fontSize: "16px",
                      }}
                    />
                    <EmailOutlinedIcon
                      sx={{
                        color: "var(--primary-color)",
                        position: "absolute",
                        top: "50%",
                        right: "5px",
                        transform: "translate(-5px,-50%)",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Box sx={{ position: "relative" }}>
                    <input
                      placeholder="Enter Your Password"
                      required
                      name="password"
                      value={loginData.password}
                      type={showPassword ? "text" : "password"}
                      onChange={handleOnChange}
                      style={{
                        height: "40px",
                        width: "calc(100% - 20px)",
                        outline: "none",
                        border: "1px solid black",
                        borderRadius: "20px",
                        padding: "0px 10px",
                        color: "var(--secondary-color)",
                        background: "transparent",
                        fontSize: "16px",
                      }}
                    />
                    {showPassword ? (
                      <LockOutlinedIcon
                        onClick={handleClickShowPassword}
                        sx={{
                          color: "var(--primary-color)",
                          position: "absolute",
                          top: "50%",
                          right: "5px",
                          transform: "translate(-5px,-50%)",
                        }}
                      />
                    ) : (
                      <NoEncryptionGmailerrorredOutlinedIcon
                        onClick={handleClickShowPassword}
                        sx={{
                          color: "var(--primary-color)",
                          position: "absolute",
                          top: "50%",
                          right: "5px",
                          transform: "translate(-5px,-50%)",
                        }}
                      />
                    )}
                  </Box>
                </Grid>
                {/* <Grid item md={12} sm={12} xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        rememberUser?.isChecked === true ? (
                          <Checkbox defaultChecked onChange={handleCheckBox} />
                        ) : (
                          <Checkbox onChange={handleCheckBox} />
                        )
                      }
                      label="Remember"
                    />
                  </FormGroup>
                </Grid> */}
                <Grid item md={12} sm={12} xs={12}>
                  <Box>
                    <NavLink
                      style={{ textDecoration: "none" }}
                      to="/resetpassword"
                    >
                      <Typography
                        sx={{
                          color: "var(--black)",
                          fontSize: "14px",
                          width: "100%",
                          textAlign: "right",
                        }}
                      >
                        Forget Password ?
                      </Typography>
                    </NavLink>
                  </Box>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Button
                    type="submit"
                    disabled={!isLoading ? true : false}
                    sx={{
                      width: "100%",
                      borderRadius: "20px",
                      background: "var(--primary-color)",
                      color: "var(--white)",
                      "&:hover": {
                        background: "var(--primary-color)",
                        color: "var(--white)",
                      },
                    }}
                  >
                    {isLoading ? (
                      `Sign In →`
                    ) : (
                      <CircularProgress
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
              <Box mt={1} sx={{ padding: "0px 20px 20px" }}>
                <Box>
                  <Box>
                    {error && (
                      <Alert
                        severity="error"
                        style={{
                          margin: "0px",
                          padding: "0px",
                        }}
                      >
                        <AlertTitle color="red" m={0} p={0}>
                          Error !
                        </AlertTitle>{" "}
                        <strong textAlign={"center"}>{error} !</strong>
                      </Alert>
                    )}
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "var(--primary-color)",
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    Don't have an account?
                    <Link
                      to="/signup"
                      style={{ textDecoration: "none", color: "var(--black)" }}
                    >
                      Register Now
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SignIn;
