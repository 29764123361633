import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import Swal from "sweetalert2";
import Success from "../../../images/undraw/undraw_completed_tasks_vs6q.svg";
import Invalid from "../../../images/undraw/undraw_warning_re_eoyh.svg";
import './Markup.css'

const EveryoneMarkup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [amountType, setAmountType] = useState("");
  const [amount, setAmount] = useState("7");
  const [agentId, setAgentId] = useState("");

  useEffect(() => {
    //todo: fetching the data
    axios
      .get(
        `https://api.flyjatt.com/v.1.0.0/WhiteLabel/MyAccount/all.php?website=${window.location.hostname.replace(
          "www.",
          ""
        )}`
      )
      .then((res) => {
        // console.log(res);
        setAgentId(res?.data?.agentId);
        setAmount(res?.data?.markup);
        setAmountType(res?.data?.markuptype);
      });
  }, []);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const url = `https://api.flyjatt.com/v.1.0.0/WhiteLabel/MyAccount/add.php?option=markup`;
    const body = {
      method: "POST",
      "application-type": "application/json",
      body: JSON.stringify({
        agentId: agentId,
        data: amount,
        markuptype: amountType,
      }),
    };
    await fetch(url, body)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.status.toLowerCase() === "success") {
          Swal.fire({
            imageUrl: Success,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: data.message,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            setIsLoading(false);
            caches
              .keys()
              .then((keyList) =>
                Promise.all(keyList.map((key) => caches.delete(key)))
              );
          });
        } else {
          Swal.fire({
            imageUrl: Invalid,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: data.message,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            setIsLoading(false);
            caches
              .keys()
              .then((keyList) =>
                Promise.all(keyList.map((key) => caches.delete(key)))
              );
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          imageUrl: Invalid,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: err.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          setIsLoading(false);
          caches
            .keys()
            .then((keyList) =>
              Promise.all(keyList.map((key) => caches.delete(key)))
            );
        });
      });
  };

  //  markup reset handle
  const handleMarkupReset = async () => {
    setIsLoading(true);
    const url = `https://api.flyjatt.com/v.1.0.0/WhiteLabel/MyAccount/add.php?option=markup`;
    const body = {
      method: "POST",
      "application-type": "application/json",
      body: JSON.stringify({
        agentId: agentId,
        data: amountType === "Percent" ? "7" : 0,
        markuptype: "amount",
      }),
    };
    await fetch(url, body)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.status.toLowerCase() === "success") {
          Swal.fire({
            imageUrl: Success,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: "Markup Reset Successfully",
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            setIsLoading(false);
            caches
              .keys()
              .then((keyList) =>
                Promise.all(keyList.map((key) => caches.delete(key)))
              );
            window.location.reload(true);
          });
        } else {
          Swal.fire({
            imageUrl: Invalid,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: data.message,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            setIsLoading(true);
            caches
              .keys()
              .then((keyList) =>
                Promise.all(keyList.map((key) => caches.delete(key)))
              );
            window.location.reload(true);
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          imageUrl: Invalid,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: err.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          setIsLoading(true);
          caches
            .keys()
            .then((keyList) =>
              Promise.all(keyList.map((key) => caches.delete(key)))
            );
          window.location.reload(true);
        });
      });
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box className="markupBox">
          <Grid container spacing={2}>
            <Grid item md={3} sm={6} xs={12}>
              <Typography
                style={{ color: "var(--primary-color)", fontSize: "14px" }}
              >
                Select Markup Type
              </Typography>
              <select
                name="markup"
                id="markup"
                value={amountType}
                onChange={(e) => setAmountType(e.target.value)}
              >
                {/* <option value="percent">Percent</option> */}
                <option value="amount">Amount</option>
              </select>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Typography
                style={{ color: "var(--primary-color)", fontSize: "14px" }}
              >
                Enter Markup in {amountType}
              </Typography>

              <input
                type="number"
                placeholder="Enter amount or percentage"
                min="0"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={6}
              xs={12}
              display="flex"
              alignItems="flex-end"
            >
              <Box>
                <button
                  disabled={isLoading ? true : false}
                  style={{
                    width: "250px",
                    height: "30px",
                    border: "none",
                    background: "var(--secondary-color)",
                    color: "var(--white)",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  type="submit"
                >
                  {isLoading ? (
                    <CircularProgress
                      style={{ height: "20px", width: "20px" }}
                    />
                  ) : (
                    "Set Markup"
                  )}
                </button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
      <Typography>
        {/*

        

        */}
      </Typography>

      <Box mt={6}>
        <button
          disabled={isLoading ? true : false}
          style={{
            width: "280px",
            height: "30px",
            border: "none",
            background: "var(--tomato-color)",
            color: "var(--white)",
            cursor: "pointer",
            borderRadius: "5px",
          }}
          onClick={handleMarkupReset}
        >
          {isLoading ? (
            <CircularProgress style={{ height: "20px", width: "20px" }} />
          ) : (
            "Reset Markup"
          )}
        </button>
      </Box>
    </Box>
  );
};

export default EveryoneMarkup;
