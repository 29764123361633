import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Slider from "@mui/material/Slider";
import { AiFillCaretDown } from "react-icons/ai";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "../pages/SearchReslut/SearchResult.css";

import {
  Box,
  Checkbox,
  Grid,
  FormGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import commaNumber from "comma-number";
import AirlineCheckBox from "./SingleFlight/AirlineCheckBox";

const OneWayFilter = ({
  filteredData,
  setfilteredData,
  data,
  setData,
  noData,
  setNoData,
  departureDate,
  setFrom,
  setPageCount,
}) => {
  let arr = [];
  let flightprice = data;
  flightprice.map((data) => {
    arr.push(data.NetFare);
    return arr;
  });
  const maxPrice = Math.max(...arr);
  const minPrice = Math.min(...arr);
  const [selectPrice, setSelectPrice] = useState([minPrice, maxPrice]);
  const [refundable, setRefundable] = useState(false);
  const [nonRefundable, setNonRefundable] = useState(false);
  const [directFlight, setDirectFlight] = useState(false);
  const [oneStopFlight, setOneStopFlight] = useState(false);
  const [multiStopFlight, setMultiStopFlight] = useState(false);
  const handleRefundable = (e) => {
    let updatedflight = data;
    setNonRefundable(false);
    setRefundable(e.target.checked);
    if (e.target.checked && selectPrice) {
      updatedflight = updatedflight.filter(
        (item) => item.nonRefundable === true
      );
      setfilteredData(updatedflight);
    } else if (!e.target.checked) {
      setfilteredData(data);
    } else {
      setNoData(noData);
    }
  };

  const handleNonRefundable = (e) => {
    let updatedflight = data;
    setRefundable(false);
    setNonRefundable(e.target.checked);
    if (e.target.checked) {
      updatedflight = updatedflight.filter(
        (item) => item.nonRefundable === false
      );
      setfilteredData(updatedflight);
    } else if (!e.target.checked) {
      setfilteredData(data);
    } else {
      setNoData(noData);
    }
  };
  const handleChangePrice = (event, newPrice) => {
    setSelectPrice(newPrice);
    let updatedflight = data;
    const selectMinPrice = selectPrice[0];
    const selectMaxPrice = selectPrice[1];
    if (selectPrice) {
      updatedflight = updatedflight.filter(
        (item) =>
          item.NetFare >= selectMinPrice && item.NetFare <= selectMaxPrice
      );
      setfilteredData(updatedflight);
    } else {
      setNoData(noData);
    }
  };

  // --------
  const handleDirectFlight = (e) => {
    let updatedflight = data;
    setDirectFlight(e.target.checked);
    setOneStopFlight(false);
    setMultiStopFlight(false);
    if (e.target.checked) {
      updatedflight = updatedflight.filter(
        (item) => item?.AllLegs[0]?.Segments?.length == 1
      );
      setfilteredData(updatedflight);
    } else {
      setNoData(noData);
    }
  };
  const handleOneStopFlight = (e) => {
    let updatedflight = data;
    setDirectFlight(false);
    setOneStopFlight(e.target.checked);
    setMultiStopFlight(false);
    if (e.target.checked) {
      updatedflight = updatedflight.filter(
        (item) => item?.AllLegs[0]?.Segments?.length == 2
      );
      setfilteredData(updatedflight);
    } else {
      setNoData(noData);
    }
  };
  const handleMultiStopFlight = (e) => {
    let updatedflight = data;
    setMultiStopFlight(e.target.checked);
    setDirectFlight(false);
    setOneStopFlight(false);
    if (e.target.checked) {
      updatedflight = updatedflight.filter(
        (item) => item?.AllLegs[0]?.Segments?.length > 2
      );
      setfilteredData(updatedflight);
    } else {
      setNoData(noData);
    }
  };
  const [CarrierFlight, setCarrierFlight] = useState([]);
  let CarrierArray = [];
  data.map((name) => {
    CarrierArray.push(name.CarrierName);
    return CarrierArray;
  });
  let Carrier = [];
  CarrierArray.forEach((i) => (Carrier[i] = false));
  let totalCarrier = Object.keys(Carrier).map((item) => ({ name: item }));

  useEffect(() => {
    setCarrierFlight(totalCarrier);
  }, []);
  const [check, setCheck] = useState(false);
  const handleflightCarrier = (e) => {
    let updatedflight = data;
    const { name, check } = e.target;
    if (name) {
      updatedflight = updatedflight.filter((item) => item.CarrierName === name);
      setCheck(check);
      setfilteredData(updatedflight);
    }
  };

  const handleResetBtn = () => {
    setSelectPrice([minPrice, maxPrice]);
    setRefundable(false);
    setNonRefundable(false);
    setDirectFlight(false);
    setOneStopFlight(false);
    setMultiStopFlight(false);
    setfilteredData(data);
  };

  return (
    <Box
      // className="flight-filter1 filter-side-0"
      sx={{
        boxShadow:
          "-0.452679px 4.97947px 36px rgba(0, 0, 0, 0.09), -0.0905357px 0.995893px 5.85px rgba(0, 0, 0, 0.045)",
        // width: "100%",
      }}
      p={2}
    >
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
        padding="0px"
      >
        <Typography
          sx={{
            color: "var(--secondary-color)",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          FILTER
        </Typography>
        <Typography
          onClick={handleResetBtn}
          sx={{
            cursor: "pointer",
            color: "var(--white)",
            backgroundColor: "var(--primary-color)",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          Reset
        </Typography>
      </Box>
      <Accordion
        defaultExpanded={true}
        sx={{ margin: "0", boxShadow: "none", border: "none" }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--secondary-color)" />}
          sx={{ margin: "0", padding: "0px" }}
        >
          <Typography
            style={{ color: "var(--secondary-color)", fontWeight: 500 }}
          >
            Price Range
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className="AccordionDetails22"
          style={{ margin: "0", paddingLeft: "12px" }}
        >
          <Box>
            <Grid item className="price-slider-line">
              <Slider
                value={selectPrice}
                onChange={handleChangePrice}
                valueLabelDisplay="auto"
                min={minPrice}
                max={maxPrice}
              />
            </Grid>
            <Grid container justifyContent={"space-between"}>
              <Typography
                sx={{
                  color: "var(--black)",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                SAR {commaNumber(minPrice)}
              </Typography>
              <Typography
                sx={{
                  color: "var(--black)",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                SAR {commaNumber(maxPrice)}
              </Typography>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        defaultExpanded={true}
        style={{ margin: "0", boxShadow: "none" }}
      >
        <AccordionSummary
          style={{ margin: "0", padding: "0px" }}
          expandIcon={<AiFillCaretDown color="var(--secondary-color)" />}
          id="panel2a-header"
        >
          <Typography
            sx={{
              color: "var(--secondary-color)",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            Fare Type
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          <Box>
            <FormGroup className="check-box-text09">
              <FormControlLabel
                value={"refundable"}
                control={<Checkbox className="box-0" />}
                checked={refundable}
                onChange={handleRefundable}
                label="Refundable"
              />
              <FormControlLabel
                value={"NonRefundable"}
                control={<Checkbox className="box-0" />}
                checked={nonRefundable}
                onChange={handleNonRefundable}
                label="Non Refundable"
              />
            </FormGroup>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        defaultExpanded={true}
        style={{ margin: "0", boxShadow: "none" }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--secondary-color)" />}
          id="panel2a-header"
          style={{ margin: "0", padding: "0" }}
        >
          <Typography
            sx={{
              color: "var(--secondary-color)",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            Stops
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          <Box sx={{ width: "100%" }}>
            <FormGroup className="check-box-text09">
              <FormControlLabel
                onChange={handleDirectFlight}
                checked={directFlight}
                control={<Checkbox className="box-0" />}
                label="Non Stop"
              />
              <FormControlLabel
                control={<Checkbox className="box-0" />}
                checked={oneStopFlight}
                onChange={handleOneStopFlight}
                label="One Stops"
              />
              <FormControlLabel
                control={<Checkbox className="box-0" />}
                checked={multiStopFlight}
                onChange={handleMultiStopFlight}
                label="One Plus Stops"
              />
            </FormGroup>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        defaultExpanded={true}
        style={{ margin: "0", boxShadow: "none" }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--secondary-color)" />}
          id="panel2a-header"
          style={{ margin: "0", padding: "0" }}
        >
          <Typography
            sx={{
              color: "var(--secondary-color)",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            Airlines
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          <Box sx={{ width: "100%" }}>
            <AirlineCheckBox
              data={data}
              setData={setData}
              filteredData={filteredData}
              setfilteredData={setfilteredData}
              setPageCount={setPageCount}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default OneWayFilter;
