/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { NavLink } from "react-router-dom";
import { FaFacebookSquare } from "react-icons/fa";
import { FaWhatsappSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  const date = new Date().getFullYear();

  return (
    <Box>
      <Box className="footer-bgs">
        <Container>
          <Box>
            <Grid container spacing={1}>
              {/* //todo:Corporate Section */}
              <Grid item xs={6} sm={6} md={2}>
                <Box
                  style={{
                    color: "var(--white)",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    gap: "10px",
                  }}
                >
                  <Typography
                    style={{ fontSize: "20px", color: "var(--white)" }}
                  >
                    Corporate
                  </Typography>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <NavLink
                      to="/"
                      style={{
                        textDecoration: "none",
                        color: "var(--white)",
                        fontSize: "14px",
                      }}
                    >
                      About Us
                    </NavLink>
                    <NavLink
                      to="/"
                      style={{
                        textDecoration: "none",
                        color: "var(--white)",
                        fontSize: "14px",
                      }}
                    >
                      Career
                    </NavLink>
                  </Box>
                </Box>
              </Grid>
              {/* //todo:Legal Section */}
              <Grid item xs={6} sm={6} md={2}>
                <Box
                  style={{
                    color: "var(--white)",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    gap: "10px",
                  }}
                >
                  <Typography
                    style={{ fontSize: "20px", color: "var(--white)" }}
                  >
                    Legal
                  </Typography>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <NavLink
                      to="/"
                      style={{
                        textDecoration: "none",
                        color: "var(--white)",
                        fontSize: "14px",
                      }}
                    >
                      Terms & Conditions
                    </NavLink>
                    <NavLink
                      to="/"
                      style={{
                        textDecoration: "none",
                        color: "var(--white)",
                        fontSize: "14px",
                      }}
                    >
                      Privacy Policy
                    </NavLink>
                  </Box>
                </Box>
              </Grid>
              {/* //todo:Support section */}
              <Grid item xs={6} sm={6} md={2}>
                <Box
                  style={{
                    color: "var(--white)",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    gap: "10px",
                  }}
                >
                  <Typography
                    style={{ fontSize: "20px", color: "var(--white)" }}
                  >
                    Support
                  </Typography>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <NavLink
                      to="/"
                      style={{
                        textDecoration: "none",
                        color: "var(--white)",
                        fontSize: "14px",
                      }}
                    >
                      Contact Us
                    </NavLink>
                    <NavLink
                      to="/"
                      style={{
                        textDecoration: "none",
                        color: "var(--white)",
                        fontSize: "14px",
                      }}
                    >
                      FAQs
                    </NavLink>
                  </Box>
                </Box>
              </Grid>
              {/* //todo:Need Help section */}
              <Grid item xs={6} sm={6} md={3}>
                <Box
                  style={{
                    color: "var(--white)",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    gap: "10px",
                  }}
                >
                  <Typography
                    style={{ fontSize: "20px", color: "var(--white)" }}
                  >
                    Need Help?
                  </Typography>
                  <Typography
                    style={{ fontSize: "14px", color: "var(--white)" }}
                  >
                    Riyadh, Saudia Arabia
                  </Typography>
                </Box>
              </Grid>

              {/* //todo:contact section */}
              <Grid item xs={12} sm={4} md={3}>
                <Box
                  style={{
                    color: "var(--white)",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    justifyContent: "start",
                    gap: "10px",
                  }}
                >
                  <Typography
                    style={{ fontSize: "20px", color: "var(--white)" }}
                  >
                    Contact
                  </Typography>

                  <Box
                    style={{
                      color: "var(--white)",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                      justifyContent: "start",
                      gap: "5px",
                    }}
                  >
                    <Typography
                      style={{ fontSize: "14px", color: "var(--white)" }}
                    >
                    contact@flyjatt.com
                    </Typography>

                    <Typography
                      style={{ fontSize: "14px", color: "var(--white)" }}
                    >
                      +971509517784
                    </Typography>
                    <Box
                      style={{
                        color: "var(--white)",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        gap: "5px",
                      }}
                    >
                      <a
                        href="https://www.facebook.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaFacebookSquare
                          style={{
                            fontSize: "30px",
                            color: "var(--white)",
                          }}
                        />
                      </a>
                      <a
                        href="https://www.whatsapp.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaWhatsappSquare
                          style={{
                            fontSize: "30px",
                            color: "var(--white)",
                          }}
                        />
                      </a>
                      <a
                        href="https://www.linkedin.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaLinkedin
                          style={{
                            fontSize: "30px",
                            color: "var(--white)",
                          }}
                        />
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <hr
          style={{
            margin: "20px 0px 10px",
            width: "100%",
            overflow: "hidden",
          }}
        />

        <Container>
          <Box
            style={{
              width: "100%",
              height: "fit-content",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "var(--white)",
                fontWeight: "normal",
                textAlign: "right",
                fontSize: "12px",
              }}
              noWrap
            >
              &copy; Copyright {date} by Fly Jatt Develop and Designed by Fly Jatt Tech Team
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
