import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import AdminHeader from "../../AdminHeader/AdminHeader";
import Airlines from "../Airlines/Airlines";
import ApiPage from "../ApiPage/ApiPage";
import CurrencyPage from "../CurrencyPage/CurrencyPage";

const PortalControl = () => {
  const [optionValue, setOptionValue] = useState("");
  const handleChangeOption = (e) => {
    setOptionValue(e.target.value);
  };
  return (
    <Box>
      <AdminHeader />
      <Container>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={6} sm={6} md={6}>
            <Typography sx={{ color: "#003566", fontSize: "23px" }}>
              Portal Control
            </Typography>
          </Grid>

          <Grid item xs={6} sm={6} md={6} textAlign="right">
            <select
              onChange={handleChangeOption}
              className="searchList1-select"
              style={{
                width: "25%",
                border: "1px solid var(--secondary-color)",
                outline: "none",
                height: "35px",
                borderRadius: "3px",
              }}
            >
              <option value="Api">&nbsp;API</option>
              <option value="Airlines">&nbsp;Airlines </option>
              <option value="Currency">&nbsp;Currency</option>
            </select>
          </Grid>
        </Grid>

        {(optionValue === "Api" || optionValue === "") && (
          <>
            <ApiPage />
          </>
        )}

        {optionValue === "Airlines" && (
          <>
            <Airlines />
          </>
        )}

        {optionValue === "Currency" && (
          <>
            <CurrencyPage />
          </>
        )}
      </Container>
    </Box>
  );
};

export default PortalControl;
