import React from "react";
import { Box } from "@mui/system";
import Oneway from "../Oneway/Oneway";
import Roundway from "../Roundway/Roundway";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Multicity from "../MultiCity/Multicity";
import { Checkbox, FormControlLabel, FormGroup, Tab } from "@mui/material";
import { useRef } from "react";
import "./FlightSearchBox.css";
import { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { addDays, format } from "date-fns";
import Swal from "sweetalert2";
import ServerDown from "../../images/undraw/undraw_server_down_s-4-lk.svg";

import Search from "../../images/undraw/undraw_web_search_re_efla.svg";

const FlightSearchBox = ({
  options,
  setOptions,
  value,
  setValue,
  fromSearchText,
  setFromSearchText,
  toSearchText,
  setToSearchText,
  from,
  setFrom,
  to,
  setTo,
  faddress,
  setfaddress,
  toAddress,
  setToAddress,
  fromSendData,
  setFromSendData,
  toSendData,
  setToSendData,
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infant,
  setInfant,
  result,
  setResult,
  className,
  setClassName,
  changeFrom,
  setChangeFrom,
  changeState,
  directFlightOnly,
  setDirectFlightOnly,
  searchData,
  setSearchData,
  setChangeState,
}) => {
  const user = secureLocalStorage.getItem("user-info");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeOptions = (event) => {
    setDirectFlightOnly(event.target.checked);
  };
  const handleClassName = (event) => {
    setClassName(event.target.value);
  };
  // const [directFlightOnly, setDirectFlightOnly] = useState(false);
  // const handleChangeOptions = (event) => {
  //   setDirectFlightOnly(event.target.checked);
  // };

  //------------------ for Multicity Search Data

  // const [searchData, setSearchData] = useState({
  //   agentId: user?.user?.agentId || "",
  //   adultCount: adultCount,
  //   childCount: childCount,
  //   infantCount: infant,
  //   connection: directFlightOnly ? 1 : 0,
  //   cabinclass: className || "Y",
  //   segments: [
  //     {
  //       id: 0,
  //       openFrom: false,
  //       DepFrom: fromSendData.trim(),
  //       depFromText: fromSearchText.trim(),
  //       ArrTo: "DXB",
  //       arrToText: "Dubai Intl Airport [DXB]",
  //       openTo: false,
  //       Date: new Date().toLocaleDateString("sv"),
  //       openDate: false,
  //       open: false,
  //     },
  //   ],
  // });
  // const user = secureLocalStorage.getItem("user-info");
  const navigate = useNavigate();
  // //todo: state for from date change
  // const [changeFrom, setChangeFrom] = useState(false);
  // const [changeState, setChangeState] = useState(false);
  // //todo: End state for from date change
  // const [value, setValue] = React.useState("oneway");
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // const [directFlightOnly, setDirectFlightOnly] = useState(false);
  // const handleChangeOptions = (event) => {
  //   setDirectFlightOnly(event.target.checked);
  // };
  // const [departureDate, setDepartureDate] = useState(
  //   format(addDays(new Date(), 1), "dd MMM yy")
  // );
  // const [returningDate, setReturningDate] = useState(
  //   format(addDays(new Date(departureDate), 3), "dd MMM yy")
  // );
  // const [travelDate, setTravelDate] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: addDays(new Date(), 3),
  //     key: "selection",
  //   },
  // ]);

  // const now = useRef(new Date());
  // const [from, setFrom] = useState(addDays(now.current, 1));
  // const [to, setTo] = useState(addDays(now.current, 3));
  // const [fromSendData, setFromSendData] = useState("RUH");
  // const [fromSearchText, setFromSearchText] = useState("King Khaled Intl[RUH]");
  // const [faddress, setfaddress] = useState("Riyadh,SAUDI ARABIA");
  // const [toSendData, setToSendData] = useState("JED");
  // const [toSearchText, setToSearchText] = useState("Jeddah Intl[JED]");
  // const [toAddress, setToAddress] = useState("Jeddah,SAUDI ARABIA");
  // const [adultCount, setAdultCount] = useState(1);
  // const [childCount, setChildCount] = useState(0);
  // const [infant, setInfant] = useState(0);
  // const [result, setResult] = useState(1);

  // const [className, setClassName] = useState("Y");
  // const handleClassName = (event) => {
  //   setClassName(event.target.value);
  // };

  // const [options, setOptions] = useState({
  //   showCalendarAvailability: false,
  //   directFlightOnly: false,
  //   includeCheckedBaggage: false,
  // });

  // const [searchData, setSearchData] = useState({
  //   agentId: user?.user?.agentId || "",
  //   adultCount: adultCount,
  //   childCount: childCount,
  //   infantCount: infant,
  //   connection: 1,
  //   cabinclass: className || "Y",
  //   segments: [
  //     {
  //       id: 0,
  //       openFrom: false,
  //       DepFrom: fromSendData.trim(),
  //       depFromText: fromSearchText.trim(),
  //       ArrTo: "DXB",
  //       arrToText: "Dubai Intl Airport [DXB]",
  //       openTo: false,
  //       Date: new Date().toLocaleDateString("sv"),
  //       openDate: false,
  //       open: false,
  //     },
  //   ],
  // });

  // --------------------New Search Function Start --------------------

  async function handleSearch() {
    if (value === "oneway") {
      navigate("/user/searchresult", {
        state: {
          faddress,
          toAddress,
          fromSearchText,
          toSearchText,
          departureDate: format(new Date(from), "dd MMM yy"),
          adultCount,
          childCount,
          infant,
          tripType: value,
          fromSendData,
          toSendData,
          className,
          changeState,
          directFlightOnly,
        },
      });
    } else if (value === "return") {
      navigate("/user/roundsearchresult", {
        state: {
          faddress,
          toAddress,
          departureDate: format(new Date(from), "dd MMM yy"),
          returningDate: format(new Date(to), "dd MMM yy"),
          adultCount,
          childCount,
          infant,
          tripType: value,
          fromSendData,
          toSendData,
          fromSearchText,
          toSearchText,
          className,
          changeState,
          directFlightOnly,
        },
      });
    } else if (value === "multicity") {
      navigate("/user/multicityaftersearch", {
        state: {
          faddress,
          toAddress,
          fromSearchText,
          toSearchText,
          departureDate: format(new Date(from), "dd MMM yy"),
          adultCount,
          childCount,
          infant,
          tripType: value,
          fromSendData,
          toSendData,
          className,
          searchData,
          changeState,
          directFlightOnly,
        },
      });
    } else {
      Swal.fire({
        imageUrl: ServerDown,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: "Custom image",
        html: `For any query.Please contact us at <strong> contact@flyjatt.com</strong> or Call <strong> +971509517784 </strong>`,
        confirmButtonText: "Search Again...",
        confirmButtonColor: "var(--primary-color)",
      }).then(() => {
        // setClick(false);
        navigate("/user/dashboard");
      });
    }
  }

  return (
    <Box sx={{ width: "100%", padding: "10px 0px" }}>
      <TabContext value={value}>
        <Box
          sx={{
            width: "100%",
            height: { md: "fit-content", sm: "100%", xs: "100%" },
            display: "flex",
            justifyContent: {
              md: "space-between",
              sm: "center",
              xs: "center",
            },
            alignItems: "center",
            opacity: "1",
            ".MuiTabs-root": {
              minHeight: "fit-content",
            },
            "& button": {
              opacity: "1",
              borderRadius: "5px",
              background: "transparent",
              color: "var(--primary-color)",
              marginRight: "2px",
              width: "fit-content",
              minHeight: "fit-content",
              padding: "10px",
            },
            "& button.Mui-selected,& button.Mui-selected >svg": {
              background: "var(--white)",
              color: "var(--secondary-color) !important",
            },
          }}
        >
          <TabList
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              style: { display: "none" },
            }}
          >
            <Tab label="One Way" value="oneway" />
            <Tab label="Round Way" value="return" />
            <Tab label="Multi City" value="multicity" />
          </TabList>
          <FormGroup
            row
            sx={{
              fontSize: "10px",
              "& .MuiCheckbox-root.Mui-checked,.MuiCheckbox-root.Mui-checked > .MuiSvgIcon-root":
                {
                  color: "var(--secondary-color)",
                },
              ".MuiSvgIcon-root": {
                color: "var(--secondary-color)",
              },
              gap: "5px",
              color: "var(--primary-color)",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={directFlightOnly}
                  onChange={handleChangeOptions}
                  name="directFlightOnly"
                />
              }
              label="Direct FLights"
            />
          </FormGroup>
        </Box>
        <TabPanel value="oneway" style={{ padding: "10px 0px 0px" }}>
          <Oneway
            tripType={value}
            iconColor={"#DC143C"}
            bgColor={"#fff"}
            bordercolor={"#003566"}
            faddress={faddress}
            fromSendData={fromSendData}
            setFromSendData={setFromSendData}
            toSendData={toSendData}
            setToSendData={setToSendData}
            setfaddress={setfaddress}
            toAddress={toAddress}
            setToAddress={setToAddress}
            fromSearchText={fromSearchText}
            setFromSearchText={setFromSearchText}
            toSearchText={toSearchText}
            setToSearchText={setToSearchText}
            setValue={setValue}
            adultCount={adultCount}
            setAdultCount={setAdultCount}
            childCount={childCount}
            setChildCount={setChildCount}
            infant={infant}
            setInfant={setInfant}
            result={result}
            setResult={setResult}
            className={className}
            handleClassName={handleClassName}
            to={to}
            setTo={setTo}
            from={from}
            setFrom={setFrom}
            changeFrom={changeFrom}
            setChangeFrom={setChangeFrom}
            directFlightOnly={directFlightOnly}
            setChangeState={setChangeState}
            changeState={changeState}
            // setDirectFlightOnly={setDirectFlightOnly}
            handleSearch={handleSearch}
          />
        </TabPanel>
        <TabPanel value="return" style={{ padding: "10px 0px 0px" }}>
          <Roundway
            tripType={value}
            iconColor={"#DC143C"}
            bgColor={"#fff"}
            bordercolor={"#003566"}
            faddress={faddress}
            setfaddress={setfaddress}
            toAddress={toAddress}
            setToAddress={setToAddress}
            fromSendData={fromSendData}
            setFromSendData={setFromSendData}
            toSendData={toSendData}
            setToSendData={setToSendData}
            fromSearchText={fromSearchText}
            setFromSearchText={setFromSearchText}
            toSearchText={toSearchText}
            setToSearchText={setToSearchText}
            setValue={setValue}
            adultCount={adultCount}
            setAdultCount={setAdultCount}
            childCount={childCount}
            setChildCount={setChildCount}
            infant={infant}
            setInfant={setInfant}
            result={result}
            setResult={setResult}
            className={className}
            handleClassName={handleClassName}
            to={to}
            setTo={setTo}
            from={from}
            setFrom={setFrom}
            changeFrom={changeFrom}
            setChangeFrom={setChangeFrom}
            directFlightOnly={directFlightOnly}
            // setDirectFlightOnly={setDirectFlightOnly}
            handleSearch={handleSearch}
          />
        </TabPanel>

        <TabPanel value="multicity" style={{ padding: "10px 0px 0px" }}>
          <Multicity
            tripType={value}
            iconColor={"#DC143C"}
            bgColor={"#fff"}
            bordercolor={"#003566"}
            faddress={faddress}
            setfaddress={setfaddress}
            toAddress={toAddress}
            setToAddress={setToAddress}
            fromSendData={fromSendData}
            setFromSendData={setFromSendData}
            toSendData={toSendData}
            setToSendData={setToSendData}
            fromSearchText={fromSearchText}
            setFromSearchText={setFromSearchText}
            toSearchText={toSearchText}
            setToSearchText={setToSearchText}
            setValue={setValue}
            adultCount={adultCount}
            setAdultCount={setAdultCount}
            childCount={childCount}
            setChildCount={setChildCount}
            infant={infant}
            setInfant={setInfant}
            result={result}
            setResult={setResult}
            className={className}
            handleClassName={handleClassName}
            to={to}
            setTo={setTo}
            from={from}
            setFrom={setFrom}
            changeFrom={changeFrom}
            setChangeFrom={setChangeFrom}
            searchData={searchData}
            setSearchData={setSearchData}
            directFlightOnly={directFlightOnly}
            // setDirectFlightOnly={setDirectFlightOnly}
            handleSearch={handleSearch}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default FlightSearchBox;
