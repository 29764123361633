import {
  Box,
  IconButton,
  MenuItem,
  Typography,
  Menu,
  ClickAwayListener,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import "./LandingHeader.css";

export const LandingHeader = () => {
  const navigate = useNavigate();
  // todo: for mobile device
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClickAway = () => {};

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ background: "transparent" }}>
        <Container>
          <Box
            sx={{
              height: "fit-content",
              width: "100%",
              display: "flex",
              flexDirection: { md: "column", sm: "row", xs: "row" },
              justifyContent: "center",
              alignItems: "center",
              background: "transparent",
            }}
          >
            <Box
              sx={{
                height: "70px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                background: "transparent",
              }}
            >
              {/* //todo:Logo  */}
              <Box
                sx={{
                  width: { lg: "20%", md: "20%", sm: "80%", xs: "80%" },
                  background: "transparent",
                  height: "100%",
                  display: "flex",
                  justifyContent: {
                    md: "start",
                    sm: "start",
                    xs: "start",
                  },
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              >
                <Typography
                  sx={{
                    color: "var(--primary-color)",
                    fontSize: "23px",
                    fontWeight: "600",
                  }}
                >
                  Fly Jatt
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "80%",
                  background: "transparent",
                  height: "100%",
                  display: { md: "flex", sm: "none", xs: "none" },
                  alignItems: "center",
                  justifyContent: "end",
                  gap: "15px",
                }}
              >
                <Link
                  href="tel:+88081184844844"
                  underline="none"
                  sx={{
                    color: "var(--white)",
                    display: "flex",
                    alignItems: "center",
                    paddingRight: "30px",
                  }}
                >
                  <LocalPhoneIcon />
                  +971509517784
                </Link>
                <NavLink
                  to="/signin"
                  style={{
                    textDecoration: "none",
                    width: "fit-content",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "transparent",
                      color: "var(--white)",
                      padding: "5px 20px",
                      border: "2px solid var(--white)",
                      borderRadius: "5px",
                    }}
                  >
                    Log in
                  </Box>
                </NavLink>
                <Link
                  href="https://agent.flyjatt.com/"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    width: "fit-content",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "transparent",
                      color: "var(--white)",
                      padding: "5px 20px",
                      border: "2px solid var(--white)",
                      borderRadius: "5px",
                    }}
                  >
                    Agent Login
                  </Box>
                </Link>
              </Box>
            </Box>
            {/* //todo:for mobile device */}
            <Box
              sx={{
                width: "20%",
                background: "transparent",
                height: "70px",
                display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
                alignItems: "center",
                justifyContent: "end",
                gap: "10px",
              }}
            >
              <IconButton size="large" onClick={handleOpenNavMenu}>
                <MenuIcon
                  style={{ color: "var(--secondary-color)", fontSize: "35px" }}
                />
              </IconButton>
              <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem
                  onClick={handleCloseNavMenu}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    flexDirection: "column",
                  }}
                >
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive
                        ? "landing-mobile-nav-active"
                        : "landing-mobile-nav"
                    }
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to="/about"
                    className={({ isActive }) =>
                      isActive
                        ? "landing-mobile-nav-active"
                        : "landing-mobile-nav"
                    }
                  >
                    About Us
                  </NavLink>
                  <NavLink
                    to="/contact"
                    className={({ isActive }) =>
                      isActive
                        ? "landing-mobile-nav-active"
                        : "landing-mobile-nav"
                    }
                  >
                    Contact Us
                  </NavLink>
                  <Link
                    href="https://agent.flyjatt.com/"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      width: "fit-content",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "var(--primary-color)",
                    }}
                  >
                    Agent Login
                  </Link>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Container>
      </Box>
    </ClickAwayListener>
  );
};
