import { Container, Tab } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { addDays, format } from "date-fns";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import FlightSearchBox from "../FlightSearchBox/FlightSearchBox";
import FlightIcon from "@mui/icons-material/Flight";
import GroupsIcon from "@mui/icons-material/Groups";
import HotelIcon from "@mui/icons-material/Hotel";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import "./HomeSearchBox.css";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import secureLocalStorage from "react-secure-storage";

const HomeSearchBox = ({ type, setType, handleTypeChange }) => {
  const user = secureLocalStorage.getItem("user-info");
  const navigate = useNavigate();
  //todo: state for retrigger useEffect
  const [changeState, setChangeState] = useState(null);
  //todo: End for retrigger useEffect

  //todo: state for from date change
  const [changeFrom, setChangeFrom] = useState(false);
  //todo: End state for from date change
  // const [type, setType] = React.useState("flight");
  // const handleTypeChange = (event, newValue) => {
  //   setType(newValue);
  // };
  const [value, setValue] = React.useState("oneway");
  const [fromSearchText, setFromSearchText] = useState(
    "Hazrat Shahjalal Intl Airport [DAC]"
  );
  const [toSearchText, setToSearchText] = useState("Cox's Bazar Airport [CXB]");
  const [departureDate, setDepartureDate] = useState(
    format(addDays(new Date(), 1), "dd MMM yy")
  );
  const [returningDate, setReturningDate] = useState(
    format(addDays(new Date(departureDate), 3), "dd MMM yy")
  );
  const [travelDate, setTravelDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 3),
      key: "selection",
    },
  ]);
  // for multiCity
  const now = useRef(new Date());
  const [from, setFrom] = useState(addDays(now.current, 1));
  const [to, setTo] = useState(addDays(now.current, 3));
  const [faddress, setfaddress] = useState("Dhaka,BANGLADESH");
  const [toAddress, setToAddress] = useState("Cox's Bazar,Bangladesh");
  const [fromSendData, setFromSendData] = useState("DAC");
  const [toSendData, setToSendData] = useState("CXB");
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [infant, setInfant] = useState(0);
  const [result, setResult] = useState(1);
  const [className, setClassName] = useState("Economy");
  const [options, setOptions] = useState({
    showCalendarAvailability: false,
    directFlightOnly: false,
    includeCheckedBaggage: false,
  });

  const [searchData, setSearchData] = useState({
    agentId: user?.user?.agentId || "TFA1001",
    adultCount: adultCount,
    childCount: childCount,
    infantCount: infant,
    CityCount: 1,
    segments: [
      {
        id: 0,
        openFrom: false,
        DepFrom: fromSendData.trim(),
        depFromText: fromSearchText.trim(),
        ArrTo: "DXB",
        arrToText: "Dubai Intl Airport [DXB]",
        openTo: false,
        Date: new Date().toLocaleDateString("sv"),
        openDate: false,
        open: false,
      },
    ],
  });

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <TabContext value={type}>
          <Container>
            <Box
              sx={{
                width: "100%",
                height: { md: "fit-content", sm: "100%", xs: "100%" },
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                opacity: "1",
                ".MuiTabs-root": {
                  minHeight: "fit-content",
                  margin: "5px",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  padding: "0px 30px",
                  borderRadius: "5px",
                },
                ".MuiTabs-flexContainer": {
                  gap: "20px",
                },
                "& button": {
                  opacity: "1",
                  background: "transparent",
                  color: "var(--primary-color)",
                  width: "fit-content",
                  minHeight: "fit-content",
                  fontSize: "18px",
                },
                "& button.Mui-selected,& button.Mui-selected >svg": {
                  background: "transparent",
                  color: "var(--secondary-color) !important",
                },
              }}
            >
              <TabList
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                value={type}
                onChange={handleTypeChange}
                TabIndicatorProps={{
                  style: {
                    background: "var(--secondary-color)",
                    height: "3px",
                  },
                }}
              >
                <Tab
                  icon={
                    <FlightIcon
                      style={{
                        fontSize: {
                          xs: "15px",
                          sm: "15px",
                          md: "20px",
                        },
                        color: "var(--primary-color)",
                      }}
                    />
                  }
                  iconPosition="start"
                  label="Flight"
                  value="flight"
                />
                <Tab
                  icon={
                    <HotelIcon
                      sx={{
                        fontSize: {
                          xs: "15px",
                          sm: "20px",
                          color: "var(--primary-color)",
                        },
                      }}
                    />
                  }
                  iconPosition="start"
                  label="Hotel"
                  value="hotel"
                />
                <Tab
                  icon={
                    <TravelExploreIcon
                      sx={{
                        fontSize: {
                          xs: "15px",
                          sm: "20px",
                          color: "var(--primary-color)",
                        },
                      }}
                    />
                  }
                  iconPosition="start"
                  label="Holiday"
                  value="holiday"
                />
                <Tab
                  icon={
                    <AirplaneTicketIcon
                      sx={{
                        fontSize: {
                          xs: "15px",
                          sm: "20px",
                          color: "var(--primary-color)",
                        },
                      }}
                    />
                  }
                  iconPosition="start"
                  label="Visa"
                  value="visa"
                />
              </TabList>
            </Box>
          </Container>

          <Container>
            <TabPanel value={"flight"} style={{ padding: "0px 0px 0px 0px" }}>
              <FlightSearchBox
                options={options}
                setOptions={setOptions}
                value={value}
                setValue={setValue}
                fromSearchText={fromSearchText}
                setFromSearchText={setFromSearchText}
                toSearchText={toSearchText}
                setToSearchText={setToSearchText}
                departureDate={departureDate}
                setDepartureDate={setDepartureDate}
                returningDate={returningDate}
                setReturningDate={setReturningDate}
                travelDate={travelDate}
                setTravelDate={setTravelDate}
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                faddress={faddress}
                setfaddress={setfaddress}
                toAddress={toAddress}
                setToAddress={setToAddress}
                fromSendData={fromSendData}
                setFromSendData={setFromSendData}
                toSendData={toSendData}
                setToSendData={setToSendData}
                adultCount={adultCount}
                setAdultCount={setAdultCount}
                childCount={childCount}
                setChildCount={setChildCount}
                infant={infant}
                setInfant={setInfant}
                result={result}
                setResult={setResult}
                className={className}
                setClassName={setClassName}
                setChangeState={setChangeState}
                changeState={changeState}
                changeFrom={changeFrom}
                setChangeFrom={setChangeFrom}
                searchData={searchData}
                setSearchData={setSearchData}
              />
            </TabPanel>
            <TabPanel value={"hotel"} style={{ padding: "0px 0px 0px 0px" }}>
              <FlightSearchBox
                options={options}
                setOptions={setOptions}
                value={value}
                setValue={setValue}
                fromSearchText={fromSearchText}
                setFromSearchText={setFromSearchText}
                toSearchText={toSearchText}
                setToSearchText={setToSearchText}
                departureDate={departureDate}
                setDepartureDate={setDepartureDate}
                returningDate={returningDate}
                setReturningDate={setReturningDate}
                travelDate={travelDate}
                setTravelDate={setTravelDate}
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                faddress={faddress}
                setfaddress={setfaddress}
                toAddress={toAddress}
                setToAddress={setToAddress}
                fromSendData={fromSendData}
                setFromSendData={setFromSendData}
                toSendData={toSendData}
                setToSendData={setToSendData}
                adultCount={adultCount}
                setAdultCount={setAdultCount}
                childCount={childCount}
                setChildCount={setChildCount}
                infant={infant}
                setInfant={setInfant}
                result={result}
                setResult={setResult}
                className={className}
                setClassName={setClassName}
                setChangeState={setChangeState}
                changeState={changeState}
                changeFrom={changeFrom}
                setChangeFrom={setChangeFrom}
                searchData={searchData}
                setSearchData={setSearchData}
              />
            </TabPanel>
            <TabPanel value={"holiday"} style={{ padding: "0px 0px 0px 0px" }}>
              <FlightSearchBox
                options={options}
                setOptions={setOptions}
                value={value}
                setValue={setValue}
                fromSearchText={fromSearchText}
                setFromSearchText={setFromSearchText}
                toSearchText={toSearchText}
                setToSearchText={setToSearchText}
                departureDate={departureDate}
                setDepartureDate={setDepartureDate}
                returningDate={returningDate}
                setReturningDate={setReturningDate}
                travelDate={travelDate}
                setTravelDate={setTravelDate}
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                faddress={faddress}
                setfaddress={setfaddress}
                toAddress={toAddress}
                setToAddress={setToAddress}
                fromSendData={fromSendData}
                setFromSendData={setFromSendData}
                toSendData={toSendData}
                setToSendData={setToSendData}
                adultCount={adultCount}
                setAdultCount={setAdultCount}
                childCount={childCount}
                setChildCount={setChildCount}
                infant={infant}
                setInfant={setInfant}
                result={result}
                setResult={setResult}
                className={className}
                setClassName={setClassName}
                setChangeState={setChangeState}
                changeState={changeState}
                changeFrom={changeFrom}
                setChangeFrom={setChangeFrom}
              />
            </TabPanel>
            <TabPanel value={"visa"} style={{ padding: "0px 0px 0px 0px" }}>
              <FlightSearchBox
                options={options}
                setOptions={setOptions}
                value={value}
                setValue={setValue}
                fromSearchText={fromSearchText}
                setFromSearchText={setFromSearchText}
                toSearchText={toSearchText}
                setToSearchText={setToSearchText}
                departureDate={departureDate}
                setDepartureDate={setDepartureDate}
                returningDate={returningDate}
                setReturningDate={setReturningDate}
                travelDate={travelDate}
                setTravelDate={setTravelDate}
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                faddress={faddress}
                setfaddress={setfaddress}
                toAddress={toAddress}
                setToAddress={setToAddress}
                fromSendData={fromSendData}
                setFromSendData={setFromSendData}
                toSendData={toSendData}
                setToSendData={setToSendData}
                adultCount={adultCount}
                setAdultCount={setAdultCount}
                childCount={childCount}
                setChildCount={setChildCount}
                infant={infant}
                setInfant={setInfant}
                result={result}
                setResult={setResult}
                className={className}
                setClassName={setClassName}
                setChangeState={setChangeState}
                changeState={changeState}
                changeFrom={changeFrom}
                setChangeFrom={setChangeFrom}
                searchData={searchData}
                setSearchData={setSearchData}
              />
            </TabPanel>
          </Container>
        </TabContext>
      </Box>
    </Box>
  );
};

export default HomeSearchBox;
