/* eslint-disable react/jsx-no-comment-textnodes */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Modal,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useRef, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import flightData from "../../../flightData";
import airlineNames from "./airlineNames";
import commaNumber from "comma-number";
import Loader from "../../../../images/loader/Render.gif";
import CircularProgress from "@mui/material/CircularProgress";
import FlightIcon from "@mui/icons-material/Flight";
import flightImg from "../../../../images/BookingManagement/flight.png";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { SxProps } from "@mui/system";
import swal from "sweetalert2";
import ellips from "../../../../images/Ellipse.png";
import subtract from "../../../../images/Subtract.png";
import airImg from "../../../../images/airImg.png";
import confirm from "../../../../images/Icon/confirm 1.png";
import Swal from "sweetalert2";
import axios from "axios";
import "./QueuesDetail.css";
import secureLocalStorage from "react-secure-storage";
import { format, formatRelative } from "date-fns";
import { Calendar } from "react-date-range";
import cancelImg from "../../../../images/undraw/undraw_cancel_re_pkdm.svg";
import cancelFailed from "../../../../images/undraw/undraw_bug_fixing_oc-7-a.svg";
import ReConfirm from "../../../../images/undraw/undraw_confirmation_re_b6q5.svg";
import Issue from "../../../../images/undraw/undraw_booking_re_gw4j.svg";
import Invalid from "../../../../images/undraw/undraw_warning_re_eoyh.svg";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
// import AllPDF from "./AllPdf";
import Header from "../../../Header/Header";
import FileUploadSection from "../../../Shared/FileUploadSection/FileUploadSection";
import { ToWords } from "to-words";

const QueuesDetail = () => {
  const location = useLocation();
  const {
    bookingId,
    gds,
    flightType,
    status,
    bookDate,
    deptFrom,
    arriveTo,
    pnr,
    airlines,
    netCost,
    tripType,
  } = location?.state?.data;

  const styles = {
    button: {
      padding: "6px 20px",
      marginRight: "20px",
      color: "#fff",
      backgroundColor: "var(--primary-color)",
      border: "none",
      cursor: "pointer",
    },
    buttonDisabled: {
      padding: "6px 20px",
      marginRight: "20px",
      color: "#fff",
      backgroundColor: "var(--primary-color)",
      border: "none",
      cursor: "not-allowed",
    },
  };

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const handleOpenUpdateModal = () => setOpenUpdateModal(true);
  const handleCloseUpdateModal = () => {
    setState((prev) => !prev);
    setOpenUpdateModal(false);
    // navigate(0);
  };
  const updateModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "#fff",
    p: 4,
    borderRadius: "10px",
    overflow: "auto",
  };

  const navigate = useNavigate();
  const [openModalReIssue, setOpenModalReIssue] = useState(false);
  const [openModalRefund, setOpenModalRefund] = useState(false);
  const [openModalVoid, setOpenModalVoid] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [returnBtn, setReturnBtn] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [issueLoading, setIssueLoading] = useState(false);
  const users = secureLocalStorage.getItem("user-info");
  let agentID = users?.user?.agentId;
  let agentName = users?.user?.name;
  let staffName = users?.user?.name;
  let userStaffId = users?.user?.staffId;
  const subagentId = users.user.subagentId;
  const [isDone, setIsDone] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [allData, setAllData] = useState({});
  // todo:for multicity
  const [multiCityAllData, setMultiCityAllData] = useState([]);
  //todo: calculate total flight duration
  const calDuration = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? "0" + h : h; // (or alternatively) h = String(h).padStart(2, '0')
    m = m < 10 ? "0" + m : m; // (or alternatively) m = String(m).padStart(2, '0')
    return `${h}:${m}`;
  };
  // !end
  const toWords = new ToWords();
  const [userData, setUserData] = useState([0]);
  const [invoiceId, setInvoiceId] = useState([]);
  const [date, setDate] = useState(format(new Date(), "dd-MMM-yyyy"));
  //todo:state change
  const [state, setState] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [agentId, setAgentId] = useState("");
  const [siteConfig, setSiteConfig] = useState({});
  useEffect(() => {
    //todo: fetching the data
    axios
      .get(
        `https://api.flyjatt.com/v.1.0.0/WhiteLabel/MyAccount/all.php?website=${window.location.hostname.replace(
          "www.",
          ""
        )}`
      )
      .then((res) => {
        setSiteConfig(res?.data);
        setAgentId(res?.data?.agentId);
        setCompanyName(res?.data?.company_name);
      });
  }, []);

  useEffect(() => {
    setIsFetching(true);
    const url1 = `https://api.flyjatt.com/v.1.0.0/Queues/BookingData.php?agentId=${agentID}&bookingId=${bookingId}`;
    const airRetriveUrl = `https://api.flyjatt.com/v.1.0.0/FlyHub/AirRetrieve.php?BookingID=${pnr}`;
    // console.log(airRetriveUrl);
    fetch(url1)
      .then((res) => res.json())
      .then((data) => {
        if (data[0]?.tripType === "multicity") {
          setAllData({ ...data[0], journeyType: "Outbound" });
        } else {
          setAllData(data[0]);
        }
        setInvoiceId(data[0]?.ticketData);
      })
      .finally(() => setIsFetching(false));

    fetch(airRetriveUrl)
      .then((res) => res.json())
      .then((data) => {
        setMultiCityAllData(data?.Results[0]?.segments);
      })
      .finally(() => setIsFetching(false));
  }, [bookingId, agentID, pnr]);
  // console.log(allData);
  const cancelBooking = (system, pnr) => {
    Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      title: "Are you sure?",
      text: "You Wants to Cancel this Flight ?",
      showCancelButton: true,
      confirmButtonColor: "var(--primary-color)",
      confirmButtonText: "Yes Cancel it !",
      cancelButtonColor: "#dc143c",
      cancelButtonText: "Don't Cancel it !",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        setIsDone(false);
        setOpen(false);
        setIsLoading(false);
        let url = `https://api.flyjatt.com/v.1.0.0/AirBooking/AirCancel.php`;
        let body = JSON.stringify({
          bookingId: bookingId,
          cancelBy: subagentId || staffName || agentID,
          platform: "B2B",
        });
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: body,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data?.status === "success") {
              Swal.fire({
                // icon: "success",
                // imageUrl: cancelImg,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: "Custom image",
                title: "Your Flight is Cancel!",
                html: `For any query.Please contact us at <strong>${siteConfig?.email}</strong> or Call <strong>${siteConfig?.phone}</strong>`,
                confirmButtonText: "OK",
                cancelButtonColor: "#dc143c",
              }).then(() => {
                setIsDone(true);
                navigate("/queues");
              });
            } else {
              throw new Error("error");
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err.message);
            Swal.fire({
              // imageUrl: cancelFailed,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              title: "Booking Cancel Failed!",
              html: `For any query.Please contact us at <strong>${siteConfig?.email}</strong> or Call <strong>${siteConfig?.phone}</strong>`,
              confirmButtonText: "OK",
              cancelButtonColor: "#dc143c",
            }).then(() => {
              setIsDone(true);
              navigate(0);
            });
          });
      }
    });
  };
  const issueData = {
    agentId: agentID || "Agent",
    subagentId: subagentId || "",
    bookingId: bookingId || "BookingId",
  };
  async function handleIssueTicket() {
    Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      title: "Are you sure?",
      text: "You wants to issue this ticket ?",
      showCancelButton: true,
      confirmButtonColor: "var(--primary-color)",
      confirmButtonText: "Yes Issue it !",
      cancelButtonColor: "crimson",
      cancelButtonText: "Don't Issue it !",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        setIsDone(false);
        setOpen(false);
        setIsLoading(false);
        let url = `https://api.flyjatt.com/v.1.0.0/WhiteLabel/Booking/AirTicket.php`;
        let body = JSON.stringify(issueData);
        // console.log(body);
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: body,
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log("response", data);
            if (data?.status === "success") {
              Swal.fire({
                imageUrl: Issue,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: "Custom image",
                title: "Issue Ticket Request In Process",
                html: `Your issue ticket request submitted successfully wait for a response. if you do not receive any email, please contact us at <strong>${siteConfig.email} or Call ${siteConfig.phone}</strong>`,
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              }).then(function () {
                setIsDone(true);
                setIssueLoading(false);
                navigate("/queues");
              });
            } else {
              Swal.fire({
                imageUrl: Invalid,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: "Custom image",
                title: `${data?.message}`,
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              }).then(function () {
                setIsDone(true);
                setIssueLoading(false);
                navigate(0);
              });
            }
            setIsLoading(false);
          })
          .catch((err) => {
            Swal.fire({
              imageUrl: Invalid,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              title: "Issue Ticket Failed!",
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            }).then(function () {
              setIsDone(true);
              setIssueLoading(false);
              navigate(0);
            });
          });
      }
    });
  }

  const adultTotalPrice =
    parseInt(allData?.adultCostBase) + parseInt(allData?.adultCostTax);
  const childTotalPrice =
    parseInt(allData?.childCostBase) + parseInt(allData?.childCostTax);

  const infantTotalPrice =
    parseInt(allData?.infantCostBase) + parseInt(allData?.infantCostTax);

  const totalPrice = adultTotalPrice + childTotalPrice + infantTotalPrice;

  const savingMoney = Math.abs(
    parseInt(allData?.grossCost) - parseInt(allData?.netCost)
  );

  //todo remove last word function

  const [checkBox, setCheckBox] = useState();
  const handleCheckBox = (index) => {
    const e = window.event;
    const tempData = [...invoiceId];
    tempData[index] = { ...tempData[index], checkBox: e.target.checked };
    setCheckBox(e.target.checked);
    setInvoiceId(tempData);
  };

  let reissue = [];

  if (!isDone || isFetching) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "40%",
              objectFit: "center",
            }}
          />
        </Box>
      </Box>
    );
  }
  // Void -Function
  const voideDate = new Date(allData?.lastUpdated);
  const handleVoid = async (e) => {
    setIsLoading(false);
    e.preventDefault();
    let body = JSON.stringify({
      agentId: agentID,
      staffId: userStaffId || "StaffId",
      bookingId: bookingId || "BookingId",
      requestedBy: staffName || agentName,
      passengerData: reissue,
      subagentId: subagentId,
    });
    await fetch(
      "https://api.flyjatt.com/v.1.0.0/WhiteLabel/Booking/Void.php",

      {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: body,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === "success") {
          setOpenModalVoid(false);
          Swal.fire({
            icon: "success",
            title: "Your void request is processing !",
            html: `For any query.Please contact us at <strong>${siteConfig?.email}</strong> or Call <strong>${siteConfig?.phone}</strong>`,
            confirmButtonText: "OK",
          }).then(function () {
            // window.location.href = "/dashboard/account/DepositEntry";
            navigate(0);
          });
        } else {
          setOpenModalVoid(false);
          Swal.fire({
            icon: "error",
            title: "Void Request Failed!",
            html: `For any query.Please contact us at <strong>${siteConfig?.email}</strong> or Call <strong>${siteConfig?.phone}</strong>`,
            confirmButtonText: "OK",
          }).then(() => {
            navigate(-1);
          });
        }
      });

    e.target.reset();
  };
  const handleRefund = async (e) => {
    setIsDisabled(true);
    setIsLoading(false);
    e.preventDefault();
    let body = JSON.stringify({
      agentId: agentID,
      staffId: userStaffId || "StaffId",
      bookingId: bookingId || "BookingId",
      requestedBy: staffName || agentName,
      passengerData: reissue,
      subagentId: subagentId,
    });
    await fetch(
      "https://api.flyjatt.com/v.1.0.0/WhiteLabel/Booking/Refund.php",
      {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: body,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === "success") {
          setOpenModalRefund(false);
          Swal.fire({
            icon: "success",
            title: "Your refund request is processing !",
            html: `For any query.Please contact us at <strong>${siteConfig?.email}</strong> or Call <strong>${siteConfig?.phone}</strong>`,
            confirmButtonText: "OK",
          }).then(function () {
            navigate(0);
          });
        } else {
          setOpenModalRefund(false);
          Swal.fire({
            icon: "error",
            title: "Refund Request Failed!",
            html: `For any query.Please contact us at <strong>${siteConfig?.email}</strong> or Call <strong>${siteConfig?.phone}</strong>`,
            confirmButtonText: "OK",
          }).then(() => {
            navigate(-1);
          });
        }
      });

    e.target.reset();
  };

  const handleReissue = async (e) => {
    setIsLoading(false);
    e.preventDefault();
    let body = JSON.stringify({
      agentId: agentID,
      staffId: userStaffId || "StaffId",
      bookingId: bookingId || "BookingId",
      requestedBy: staffName || agentName,
      passengerData: reissue,
      date: date,
      subagentId: subagentId,
    });
    await fetch(
      "https://api.flyjatt.com/v.1.0.0/WhiteLabel/Booking/Reissue.php",
      {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: body,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === "success") {
          setOpenModalReIssue(false);
          Swal.fire({
            icon: "success",
            title: "Your reissue request is processing !",
            html: `For any query.Please contact us at <strong>${siteConfig?.email}</strong> or Call <strong>${siteConfig?.phone}</strong>`,
            confirmButtonText: "OK",
          }).then(function () {
            navigate(0);
          });
        } else {
          setOpenModalReIssue(false);
          Swal.fire({
            icon: "error",
            title: "Reissue Request Failed!",
            html: `For any query.Please contact us at <strong>${siteConfig?.email}</strong> or Call <strong>${siteConfig?.phone}</strong>`,
            confirmButtonText: "OK",
          }).then(() => {
            navigate(-1);
          });
        }
      });

    e.target.reset();
  };

  const todaydate = new Date().getDate();

  return (
    <Box mb={5}>
      <Header />
      <Container style={{ marginTop: "20px" }}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={9} md={9}>
            <Box
              sx={{
                background: "#D1E9FF",
                height: "40px",
                padding: "0px 15px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span
                style={{
                  color: "#003566",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Flight Details
              </span>
              <span
                style={{
                  color: "#003566",
                  fontSize: "13px",

                  fontWeight: "500",
                }}
              >
                Reference Id: {bookingId}
              </span>
            </Box>

            <Grid container justifyContent={"space-between"} mt={2}>
              <Grid item xs={12} sm={6}>
                {allData?.tripType === "oneway" ? (
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 400,
                      color: "var(--secondary-color)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {allData?.travelDate?.slice(11, 16)} {allData?.deptFrom}
                    &nbsp;&nbsp;
                    <FlightIcon
                      style={{
                        transform: "rotate(90deg)",
                        fontSize: "20px",
                      }}
                    />
                    &nbsp;&nbsp;
                    <>
                      {allData?.flightData?.segment[
                        allData?.flightData?.segment?.length - 1
                      ]?.arrivalTime?.slice(11, 16)}
                      &nbsp;&nbsp;
                      {allData?.arriveTo}&nbsp;&nbsp;
                      {allData?.flightData?.segment?.length === 1
                        ? "Non"
                        : allData?.flightData?.segment?.length - 1}{" "}
                      Stop
                    </>
                  </Typography>
                ) : allData?.tripType === "multicity" ? null : (
                  <>
                    {!returnBtn ? (
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 400,
                          color: "var(--secondary-color)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {allData?.flightData?.Segment_data?.go[
                          allData?.flightData?.Segment_data?.go?.length - 1
                        ]?.goDepartureTime?.slice(11, 16)}{" "}
                        ({allData?.deptFrom}) &nbsp;&nbsp;
                        <FlightIcon
                          style={{
                            transform: "rotate(90deg)",
                            fontSize: "20px",
                          }}
                        />
                        &nbsp;&nbsp;
                        {allData?.flightData?.Segment_data?.go[
                          allData?.flightData?.Segment_data?.go?.length - 1
                        ]?.goArrivalTime?.slice(11, 16)}{" "}
                        ({allData?.arriveTo}){" "}
                        {allData?.flightData?.Segment_data?.go?.length === 1
                          ? "Non"
                          : allData?.flightData?.Segment_data?.go?.length -
                            1}{" "}
                        Stop
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 400,
                          color: "var(--secondary-color)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {allData?.flightData?.Segment_data?.back[
                          allData?.flightData?.Segment_data?.back?.length - 1
                        ]?.backDepartureTime?.slice(11, 16)}{" "}
                        ({allData?.arriveTo}) &nbsp;&nbsp;
                        <FlightIcon
                          style={{
                            transform: "rotate(90deg)",
                            fontSize: "20px",
                          }}
                        />
                        &nbsp;&nbsp;
                        {allData?.flightData?.Segment_data?.back[
                          allData?.flightData?.Segment_data?.back?.length - 1
                        ]?.backArrivalTime?.slice(11, 16)}{" "}
                        ({allData?.deptFrom}){" "}
                        {allData?.flightData?.Segment_data?.back?.length === 1
                          ? "Non"
                          : allData?.flightData?.Segment_data?.back?.length -
                            1}{" "}
                        Stop
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                textAlign={{ xs: "start", sm: "right" }}
              >
                {allData?.tripType === "oneway" ||
                allData?.tripType === "multicity" ? null : (
                  <Box className="returnFlight1">
                    <button
                      style={{
                        fontWeight: "500",
                        fontSize: "12px",
                      }}
                      onClick={() => setReturnBtn(!returnBtn)}
                    >
                      {!returnBtn ? (
                        <>See Return Flight</>
                      ) : (
                        <>See Departure Flight</>
                      )}
                    </button>
                  </Box>
                )}
              </Grid>
            </Grid>
            {allData?.tripType === "oneway" ? (
              <Box>
                {allData?.flightData?.segment.map((data, index, arr) => (
                  <Box key={index}>
                    <Box
                      style={{
                        display: "flex",
                        gap: "12px",
                        alignItems: "center",
                        padding: "12px 0px",

                        fontSize: "18px",
                      }}
                    >
                      <span>{data?.departureLocation}</span>
                      <FlightIcon
                        style={{
                          transform: "rotate(90deg)",
                          fontSize: "25px",
                          color: "#2D669B",
                        }}
                      />
                      {data?.arrivalLocation}
                    </Box>
                    <Box>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="end"
                      >
                        <Grid item>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <Box>
                              <img
                                style={{
                                  width: "45px",
                                  height: "45px",
                                  display: "block",
                                }}
                                src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${data?.marketingCareer}.png`}
                                alt="flight"
                              />
                            </Box>
                            <Box>
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  color: "#222222",
                                }}
                              >
                                {data?.marketingCareerName}
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "11px",
                                  fontWeight: "500",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#2D669B",
                                    paddingRight: "11px",
                                  }}
                                >
                                  {data?.marketingCareer}{" "}
                                  {data?.marketingFlight} | Class:{" "}
                                  {data?.bookingcode}
                                </span>{" "}
                                Flight Duration : {data?.flightDuration}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Typography
                            sx={{
                              color: "#222222",

                              fontSize: "11px",
                              fontWeight: "500",
                            }}
                          >
                            Cabin: 7 KG, Baggage :{" "}
                            {allData?.adultBag !== "" && (
                              <>ADT: {allData?.adultBag?.split("-")[1]}, </>
                            )}
                            {allData?.childBag !== "" && (
                              <>
                                <>CNN: {allData?.childBag?.split("-")[1]}, </>
                              </>
                            )}
                            {allData?.infantBag !== "" && (
                              <>
                                <>INF: {allData?.infantBag?.split("-")[1]}</>
                              </>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="space-between" mt={1}>
                        <Grid item>
                          <Typography
                            sx={{
                              color: "#888888",

                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            Departure Time
                            <br />
                          </Typography>
                          <Typography
                            sx={{
                              color: "#888888",

                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            Airport
                            <br />
                          </Typography>
                        </Grid>
                        <Grid item textAlign={{ xs: "start", md: "end" }}>
                          <Typography
                            style={{
                              color: "#2D669B",

                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            {data?.departureTime
                              ? format(
                                  new Date(data?.departureTime?.toString()),
                                  "dd MMM yyyy hh:mm a"
                                )
                              : ""}
                          </Typography>
                          <Typography
                            style={{
                              color: "var(--primary-color)",

                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            {data?.departure} - {data?.departureAirport}&nbsp;
                            <> Terminal: {data?.departureTerminal}</>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="space-between" mt={2}>
                        <Grid item>
                          <Typography
                            sx={{
                              color: "#888888",

                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            Departure Time
                            <br />
                          </Typography>
                          <Typography
                            sx={{
                              color: "#888888",

                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            Airport
                            <br />
                          </Typography>
                        </Grid>
                        <Grid item textAlign={{ xs: "start", md: "end" }}>
                          <Typography
                            style={{
                              color: "#2D669B",

                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            {data?.arrivalTime
                              ? format(
                                  new Date(data?.arrivalTime?.toString()),
                                  "dd MMM yyyy hh:mm a"
                                )
                              : ""}
                          </Typography>
                          <Typography
                            style={{
                              color: "var(--primary-color)",

                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            {data?.arrival} - {data?.arrivalAirport}&nbsp;
                            {data?.arrivalTerminal === "0" ? (
                              <></>
                            ) : (
                              <> Terminal: {data?.arrivalTerminal}</>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Box
                        sx={{
                          mt: 2,
                          bgcolor: "#D1E9FF",
                          height: "10px",
                          display: `${arr.length - 1 === index ? "none" : ""}`,
                        }}
                      ></Box>
                      {/* <Box
                        style={{
                          backgroundColor: "#D1E9FF",
                          padding: "5px 15px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        my={3}
                      >
                        <span
                          style={{
                            color: "#003566",
                            fontSize: "11px",
                            
                            fontWeight: "500",
                          }}
                        >
                          Transit Time : "working"
                        </span>
                      </Box> */}
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : allData?.tripType === "multicity" ? (
              multiCityAllData.length !== 0 ? (
                multiCityAllData?.map((segment, index) => (
                  <Box key={index} sx={{ padding: "0px 15px" }}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: 400,
                        color: "var(--secondary-color)",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {`${
                        segment?.Origin?.DepTime
                          ? format(new Date(segment?.Origin?.DepTime), "hh:mm")
                          : "Time"
                      } ${segment?.Origin?.Airport?.AirportCode || "Code"}`}

                      <FlightIcon
                        style={{
                          transform: "rotate(90deg)",
                          fontSize: "20px",
                        }}
                      />
                      {`${
                        segment?.Destination?.ArrTime
                          ? format(
                              new Date(segment?.Destination?.ArrTime),
                              "hh:mm"
                            )
                          : "Time"
                      } ${
                        segment?.Destination?.Airport?.AirportCode || "Code"
                      } ${
                        segment?.SegmentGroup == 0
                          ? "Non"
                          : toWords.convert(segment?.SegmentGroup)
                      } Stops`}
                    </Typography>

                    <Box
                      style={{
                        display: "flex",
                        gap: "12px",
                        alignItems: "center",
                        padding: "12px 0px",

                        fontSize: "18px",
                      }}
                    >
                      <span>{`${
                        segment?.Origin?.Airport?.CityName || "city"
                      }, ${
                        segment?.Origin?.Airport?.CountryCode || "code"
                      }`}</span>
                      <FlightIcon
                        style={{
                          transform: "rotate(90deg)",
                          fontSize: "25px",
                          color: "#2D669B",
                        }}
                      />
                      <span>{`${
                        segment?.Destination?.Airport?.CityName || "city"
                      }, ${
                        segment?.Destination?.Airport?.CountryCode || "code"
                      }`}</span>
                    </Box>
                    <Box>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="end"
                      >
                        <Grid item>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <Box>
                              <img
                                style={{
                                  width: "45px",
                                  height: "45px",
                                  display: "block",
                                }}
                                src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${segment?.Airline?.AirlineCode}.png`}
                                alt="flight"
                              />
                            </Box>
                            <Box>
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  color: "#222222",
                                }}
                              >
                                {segment?.Airline?.AirlineName}
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "11px",
                                  fontWeight: "500",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#2D669B",
                                    paddingRight: "11px",
                                  }}
                                >
                                  {segment?.Airline?.OperatingCarrier}{" "}
                                  {segment?.Airline?.FlightNumber} | Class:{" "}
                                  {segment?.Airline?.BookingClass}
                                </span>{" "}
                                Flight Duration :{" "}
                                {calDuration(segment?.JourneyDuration)}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Typography
                            sx={{
                              color: "#222222",

                              fontSize: "11px",
                              fontWeight: "500",
                            }}
                          >
                            Cabin: 7 KG, Baggage :{segment?.Baggage}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="space-between" mt={1}>
                        <Grid item>
                          <Typography
                            sx={{
                              color: "#888888",

                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            Departure Time
                            <br />
                          </Typography>
                          <Typography
                            sx={{
                              color: "#888888",

                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            Airport
                            <br />
                          </Typography>
                        </Grid>
                        <Grid item textAlign={{ xs: "start", md: "end" }}>
                          <Typography
                            style={{
                              color: "#2D669B",

                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            {segment?.Origin?.DepTime
                              ? format(
                                  new Date(
                                    segment?.Origin?.DepTime?.toString()
                                  ),
                                  "dd MMM yy hh:mm a"
                                )
                              : ""}
                          </Typography>
                          <Typography
                            style={{
                              color: "var(--primary-color)",
                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            {segment?.Origin?.Airport?.AirportCode} -{" "}
                            {segment?.Origin?.Airport?.AirportName}
                            &nbsp; Terminal:{" "}
                            {segment?.Origin?.Airport?.Terminal || "0"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="space-between" mt={2}>
                        <Grid item>
                          <Typography
                            sx={{
                              color: "#888888",

                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            Arrival Time
                            <br />
                          </Typography>
                          <Typography
                            sx={{
                              color: "#888888",

                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            Airport
                            <br />
                          </Typography>
                        </Grid>
                        <Grid item textAlign={{ xs: "start", md: "end" }}>
                          <Typography
                            style={{
                              color: "#2D669B",

                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            {segment?.Destination?.ArrTime
                              ? format(
                                  new Date(
                                    segment?.Destination?.ArrTime?.toString()
                                  ),
                                  "dd MMM yy hh:mm a"
                                )
                              : ""}
                          </Typography>
                          <Typography
                            style={{
                              color: "var(--primary-color)",

                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            {segment?.Destination.Airport.AirportCode} -{" "}
                            {segment?.Destination.Airport.AirportName}&nbsp;
                            Terminal:{" "}
                            {segment?.Destination?.Airport?.Terminal || "0"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider sx={{ my: "15px" }} />
                  </Box>
                ))
              ) : (
                <Typography
                  sx={{ color: "var(--secondary-color)", paddingLeft: "15px" }}
                >
                  Loading...
                </Typography>
              )
            ) : (
              <Box>
                {!returnBtn ? (
                  <>
                    {allData?.flightData?.Segment_data?.go?.map(
                      (data, index) => (
                        <Box key={index}>
                          <Box
                            style={{
                              display: "flex",
                              gap: "12px",
                              alignItems: "center",
                              padding: "12px 0px",

                              fontSize: "18px",
                            }}
                          >
                            <span>{data?.goDepartureLocation}</span>
                            <FlightIcon
                              style={{
                                transform: "rotate(90deg)",
                                fontSize: "25px",
                                color: "#2D669B",
                              }}
                            />
                            {data?.goArrivalLocation}
                          </Box>
                          <Box>
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="end"
                            >
                              <Grid item>
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Box>
                                    <img
                                      style={{
                                        width: "45px",
                                        height: "45px",
                                        display: "block",
                                      }}
                                      src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${data?.goMarketingCareer}.png`}
                                      alt="flight"
                                    />
                                  </Box>
                                  <Box>
                                    <Typography
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        color: "#222222",
                                      }}
                                    >
                                      {data?.goMarketingCareerName}
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: "11px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#2D669B",
                                          paddingRight: "11px",
                                        }}
                                      >
                                        {data?.goMarketingCareer}{" "}
                                        {data?.goMarketingFlight} | Class:{" "}
                                        {data?.goBookingCode}
                                      </span>{" "}
                                      Flight Duration : {data?.goFlightDuration}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item>
                                <Typography
                                  sx={{
                                    color: "#222222",

                                    fontSize: "11px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Cabin: 7 KG, Baggage :{" "}
                                  {allData?.adultBag !== "" && (
                                    <>
                                      ADT: {allData?.adultBag?.split("|")[0]},{" "}
                                    </>
                                  )}
                                  {allData?.childBag !== "" && (
                                    <>
                                      <>
                                        CNN: {allData?.childBag?.split("|")[0]},{" "}
                                      </>
                                    </>
                                  )}
                                  {allData?.infantBag !== "" && (
                                    <>
                                      <>
                                        INF: {allData?.infantBag?.split("|")[0]}
                                      </>
                                    </>
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justifyContent="space-between"
                              mt={1}
                            >
                              <Grid item>
                                <Typography
                                  sx={{
                                    color: "#888888",

                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Departure Time
                                  <br />
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#888888",

                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Airport
                                  <br />
                                </Typography>
                              </Grid>
                              <Grid item textAlign={{ xs: "start", md: "end" }}>
                                <Typography
                                  style={{
                                    color: "#2D669B",

                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {data?.goDepartureTime
                                    ? format(
                                        new Date(
                                          data?.goDepartureTime?.toString()
                                        ),
                                        "dd MMM yyyy hh:mm a"
                                      )
                                    : ""}
                                </Typography>
                                <Typography
                                  style={{
                                    color: "var(--primary-color)",

                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {data?.goDeparture} -{" "}
                                  {data?.goDepartureAirport}
                                  &nbsp;
                                  {data?.goDepTerminal === "0" ? (
                                    <></>
                                  ) : (
                                    <> Terminal: {data?.goDepTerminal}</>
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justifyContent="space-between"
                              mt={2}
                            >
                              <Grid item>
                                <Typography
                                  sx={{
                                    color: "#888888",

                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Departure Time
                                  <br />
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#888888",

                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Airport
                                  <br />
                                </Typography>
                              </Grid>
                              <Grid item textAlign={{ xs: "start", md: "end" }}>
                                <Typography
                                  style={{
                                    color: "#2D669B",

                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {data?.goArrivalTime
                                    ? format(
                                        new Date(
                                          data?.goArrivalTime?.toString()
                                        ),
                                        "dd MMM yyyy hh:mm a"
                                      )
                                    : ""}
                                </Typography>
                                <Typography
                                  style={{
                                    color: "var(--primary-color)",

                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {data?.goArrival} - {data?.goArrivalAirport}
                                  &nbsp;
                                  {data?.goArrTerminal === "0" ? (
                                    <></>
                                  ) : (
                                    <> Terminal: {data?.goArrTerminal}</>
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>

                            {data?.goTransit === undefined ||
                            data?.goTransit === "0" ||
                            data?.goTransit === "" ? (
                              <></>
                            ) : (
                              <Box
                                sx={{
                                  bgcolor: "#D1E9FF",
                                  padding: "5px 15px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                my={3}
                              >
                                <span
                                  style={{
                                    color: "#003566",
                                    fontSize: "11px",

                                    fontWeight: "500",
                                  }}
                                >
                                  Transit Time : {data?.goTransit}
                                </span>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      )
                    )}
                  </>
                ) : (
                  <>
                    {allData?.flightData?.Segment_data?.back?.map(
                      (data, index) => (
                        <Box key={index}>
                          <Box
                            style={{
                              display: "flex",
                              gap: "12px",
                              alignItems: "center",
                              padding: "12px 0px",

                              fontSize: "18px",
                            }}
                          >
                            <span>{data?.backDepartureLocation}</span>
                            <FlightIcon
                              style={{
                                transform: "rotate(90deg)",
                                fontSize: "25px",
                                color: "#2D669B",
                              }}
                            />
                            {data?.backArrivalLocation}
                          </Box>
                          <Box>
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="end"
                            >
                              <Grid item>
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Box>
                                    <img
                                      style={{
                                        width: "45px",
                                        height: "45px",
                                        display: "block",
                                      }}
                                      src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${data?.backMarketingCareer}.png`}
                                      alt="flight"
                                    />
                                  </Box>
                                  <Box>
                                    <Typography
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        color: "#222222",
                                      }}
                                    >
                                      {data?.backMarketingCareerName}
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: "11px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#2D669B",
                                          paddingRight: "11px",
                                        }}
                                      >
                                        {data?.backMarketingCareer}{" "}
                                        {data?.backMarketingFlight} | Class:{" "}
                                        {data?.backBookingCode}
                                      </span>{" "}
                                      Flight Duration :{" "}
                                      {data?.backFlightDuration}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item>
                                <Typography
                                  sx={{
                                    color: "#222222",

                                    fontSize: "11px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Cabin: 7 KG, Baggage :{" "}
                                  {allData?.adultBag !== "" && (
                                    <>
                                      ADT: {allData?.adultBag?.split("|")[0]},{" "}
                                    </>
                                  )}
                                  {allData?.childBag !== "" && (
                                    <>
                                      <>
                                        CNN: {allData?.childBag?.split("|")[0]},{" "}
                                      </>
                                    </>
                                  )}
                                  {allData?.infantBag !== "" && (
                                    <>
                                      <>
                                        INF: {allData?.infantBag?.split("|")[0]}
                                      </>
                                    </>
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justifyContent="space-between"
                              mt={1}
                            >
                              <Grid item>
                                <Typography
                                  sx={{
                                    color: "#888888",

                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Departure Time
                                  <br />
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#888888",

                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Airport
                                  <br />
                                </Typography>
                              </Grid>
                              <Grid item textAlign={{ xs: "start", md: "end" }}>
                                <Typography
                                  style={{
                                    color: "#2D669B",

                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {data?.backDepartureTime
                                    ? format(
                                        new Date(
                                          data?.backDepartureTime?.toString()
                                        ),
                                        "dd MMM yyyy hh:mm a"
                                      )
                                    : ""}
                                </Typography>
                                <Typography
                                  style={{
                                    color: "var(--primary-color)",

                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {data?.backDeparture} -{" "}
                                  {data?.backDepartureAirport}
                                  &nbsp;
                                  {data?.backdepTerminal === "0" ? (
                                    <></>
                                  ) : (
                                    <> Terminal: {data?.backdepTerminal}</>
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justifyContent="space-between"
                              mt={2}
                            >
                              <Grid item>
                                <Typography
                                  sx={{
                                    color: "#888888",

                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Departure Time
                                  <br />
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#888888",

                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Airport
                                  <br />
                                </Typography>
                              </Grid>
                              <Grid item textAlign={{ xs: "start", md: "end" }}>
                                <Typography
                                  style={{
                                    color: "#2D669B",

                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {data?.backArrivalTime
                                    ? format(
                                        new Date(
                                          data?.backArrivalTime?.toString()
                                        ),
                                        "dd MMM yyyy hh:mm a"
                                      )
                                    : ""}
                                </Typography>
                                <Typography
                                  style={{
                                    color: "var(--primary-color)",

                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {data?.backArrival} -{" "}
                                  {data?.backArrivalAirport}
                                  &nbsp;
                                  {data?.backArrTerminal === "0" ? (
                                    <></>
                                  ) : (
                                    <> Terminal: {data?.backArrTerminal}</>
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                            {data?.backTransit === undefined ||
                            data?.backTransit === "0" ||
                            data?.backTransit === "" ? (
                              <></>
                            ) : (
                              <Box
                                sx={{
                                  bgcolor: "#D1E9FF",
                                  padding: "5px 15px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                my={3}
                              >
                                <span
                                  style={{
                                    color: "#003566",
                                    fontSize: "11px",

                                    fontWeight: "500",
                                  }}
                                >
                                  Transit Time : {data?.backTransit}
                                </span>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      )
                    )}
                  </>
                )}
              </Box>
            )}

            {/*  flight information End  */}

            <Box
              sx={{
                background: "#D1E9FF",
                height: "40px",
                padding: "0px 15px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span
                style={{
                  color: "#003566",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Passenger Details
              </span>
            </Box>

            {/* passenger information  */}
            <Box mt={2} className="queue-detail-passenger-detail">
              <div>
                {allData?.passenger?.length === 0 ? (
                  <>loading...</>
                ) : (
                  <>
                    {allData?.passenger?.map((traveler, index) => (
                      <>
                        <Box
                          p="1px 15px"
                          display={"flex"}
                          justifyContent={"space-between"}
                          bgcolor="var(--primary-color)"
                          key={index}
                        >
                          <h5
                            style={{
                              color: "var(--secondary-color)",
                              fontWeight: "500",
                              fontSize: "15px",
                            }}
                          >
                            {traveler?.gender === "Male" ? (
                              <>
                                {traveler?.gender === "Male" &&
                                traveler?.type === "ADT" ? (
                                  <>
                                    MR {traveler?.fName} {traveler?.lName}
                                  </>
                                ) : (
                                  <>
                                    MSTR {traveler?.fName} {traveler?.lName}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {traveler?.gender === "Female" &&
                                traveler?.type === "ADT" ? (
                                  <>
                                    MS {traveler?.fName} {traveler?.lName}
                                  </>
                                ) : (
                                  <>
                                    MISS {traveler?.fName} {traveler?.lName}
                                  </>
                                )}
                              </>
                            )}
                          </h5>
                        </Box>
                        <Box
                          border="1px solid #DEDEDE"
                          p="3px 0px 3px 15px"
                          mb={2}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={4} md={2}>
                              <h5>Title</h5>
                              <h6>
                                {traveler?.gender === "Male" ? (
                                  <>
                                    {traveler?.gender === "Male" &&
                                    traveler?.type === "ADT" ? (
                                      <>MR</>
                                    ) : (
                                      <>MSTR</>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {traveler?.gender === "Female" &&
                                    traveler?.type === "ADT" ? (
                                      <>MS</>
                                    ) : (
                                      <>MISS</>
                                    )}
                                  </>
                                )}
                              </h6>
                            </Grid>
                            <Grid item xs={4} md={2}>
                              <h5>First Name</h5>
                              <h6>{traveler?.fName}</h6>
                            </Grid>
                            <Grid item xs={4} md={2}>
                              <h5>Last Name</h5>
                              <h6>{traveler?.lName}</h6>
                            </Grid>
                            <Grid item xs={4} md={2}>
                              <h5>Nationality</h5>
                              <h6>{traveler?.passNation}</h6>
                            </Grid>

                            <Grid item xs={4} md={2}>
                              <h5>Date of Birth</h5>
                              <h6>
                                {traveler?.dob
                                  ? format(
                                      new Date(traveler?.dob),
                                      "dd MMM yyyy"
                                    )
                                  : "Date of Birth"}
                              </h6>
                            </Grid>

                            <Grid item xs={4} md={2}>
                              <h5>Gender</h5>
                              <h6>{traveler?.gender}</h6>
                            </Grid>

                            <Grid item xs={4} md={2}>
                              <h5>Pax Type</h5>
                              <h6>
                                {traveler?.type === "ADT"
                                  ? "Adult"
                                  : traveler?.type === "CNN"
                                  ? "Child"
                                  : "Infant"}
                              </h6>
                            </Grid>

                            <Grid item xs={4} md={2}>
                              <h5>Passport Number</h5>
                              <h6>
                                {allData?.journeyType === "Outbound"
                                  ? traveler?.passNo?.toUpperCase() ||
                                    traveler?.passNo?.toUpperCase() ||
                                    "Passport Number"
                                  : "Domestic Flight"}
                              </h6>
                            </Grid>
                            <Grid item xs={2} md={2}>
                              <h5>Passport Expire Date</h5>

                              <h6>
                                {allData?.journeyType === "Outbound"
                                  ? traveler?.passEx || traveler?.passEx
                                    ? format(
                                        new Date(
                                          traveler?.passEx || traveler?.passEx
                                        ),
                                        "dd MMM yyyy"
                                      )
                                    : "Passport Expire Date"
                                  : "Domestic Flight"}
                              </h6>
                            </Grid>
                            {allData.journeyType === "Outbound" ? (
                              allData.status === "Hold" ||
                              allData.status === "Cancelled" ? (
                                ""
                              ) : (
                                <>
                                  <Grid item xs={2} md={2}>
                                    <h5>Passport Copy</h5>

                                    <h6>
                                      <a
                                        style={{
                                          color: "#003566",
                                          fontWeight: "500",
                                          fontSize: "12px",
                                          textDecoration: "none",
                                          marginRight: "10px",
                                        }}
                                        href={traveler?.passportCopy || ""}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        View
                                      </a>
                                    </h6>
                                  </Grid>
                                  <Grid item xs={2} md={2}>
                                    <h5>Visa Copy</h5>

                                    <h6>
                                      <a
                                        style={{
                                          color: "#003566",
                                          fontWeight: "500",
                                          fontSize: "12px",
                                          textDecoration: "none",
                                        }}
                                        href={traveler?.visaCopy || ""}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {" "}
                                        View
                                      </a>
                                    </h6>
                                  </Grid>
                                </>
                              )
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Box>
                      </>
                    ))}
                  </>
                )}
              </div>
            </Box>
            {/* passenger information end */}
          </Grid>

          <Grid item xs={12} sm={3} md={3}>
            <Box
              sx={{
                bgcolor: "var(--input-bgcolor)",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  color: "var(--primary-color)",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                {allData?.status}
              </Typography>
            </Box>

            {/* accordion start here */}
            <Box sx={{ marginBottom: "20px" }}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  boxShadow: "0px 0px 4px rgba(255, 168, 77, 0.78)",
                }}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      height: "20px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#2564B8",
                        fontWeight: "500",
                      }}
                    >
                      Fare Details
                    </Typography>
                    <Box>
                      {expanded === "panel1" ? (
                        <RemoveIcon
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "25px",
                          }}
                        />
                      ) : (
                        <AddIcon
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "25px",
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Typography
                      style={{
                        color: "var(--secondary-color)000",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      Total Payable
                    </Typography>
                    <Typography
                      style={{
                        color: "var(--secondary-color)000",
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    >
                      {allData?.subagentCost} ৳
                    </Typography>
                  </Box>

                  <Box
                    style={{
                      background: "rgba(217, 217, 217, 0.39)",
                      padding: "2px 10px",
                      display: "flex",
                      alignItems: "center",
                      margin: "8px 0px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#2D669B",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      Price Breakdown
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <Typography
                      style={{
                        color: "#110F0F",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                    >
                      Adult x{allData?.adultCount}
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      mt={0.7}
                    >
                      <Box>
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#888888",
                            fontWeight: "500",
                          }}
                        >
                          Base Fare x{allData?.adultCount}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#888888",
                            fontWeight: "500",
                          }}
                        >
                          Tax x{allData?.adultCount}
                        </Typography>
                      </Box>
                      <Box style={{ textAlign: "right" }}>
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#2D669B",
                            fontWeight: "500",
                          }}
                        >
                          {allData?.adultCostBase} ৳
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#2D669B",
                            fontWeight: "500",
                          }}
                        >
                          {allData?.adultCostTax} ৳
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {allData?.childCount > 0 && (
                    <Box mt={2}>
                      <Typography
                        style={{
                          color: "#110F0F",
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                      >
                        Child x{allData?.childCount}
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        mt={0.7}
                      >
                        <Box>
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: "#888888",
                              fontWeight: "500",
                            }}
                          >
                            Base Fare x{allData?.childCount}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: "#888888",
                              fontWeight: "500",
                            }}
                          >
                            Tax x{allData?.childCount}
                          </Typography>
                        </Box>
                        <Box style={{ textAlign: "right" }}>
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: "#2D669B",
                              fontWeight: "500",
                            }}
                          >
                            {allData?.childCostBase} ৳
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: "#2D669B",
                              fontWeight: "500",
                            }}
                          >
                            {allData?.childCostTax} ৳
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {allData?.infantCount > 0 && (
                    <Box mt={2}>
                      <Typography
                        style={{
                          color: "#110F0F",
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                      >
                        Infant x{allData?.infantCount}
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        mt={0.7}
                      >
                        <Box>
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: "#888888",
                              fontWeight: "500",
                            }}
                          >
                            Base Fare x{allData?.infantCount}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: "#888888",
                              fontWeight: "500",
                            }}
                          >
                            Tax x{allData?.infantCount}
                          </Typography>
                        </Box>
                        <Box style={{ textAlign: "right" }}>
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: "#2D669B",
                              fontWeight: "500",
                            }}
                          >
                            {allData?.infantCostBase} ৳
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: "#2D669B",
                              fontWeight: "500",
                            }}
                          >
                            {allData?.infantCostTax} ৳
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  <Divider sx={{ my: "5px" }} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#888888",
                        fontWeight: "500",
                      }}
                    >
                      Total Base & Tax
                    </Typography>

                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#2D669B",
                        fontWeight: "500",
                      }}
                    >
                      {totalPrice} ৳ <br />
                    </Typography>
                  </Box>
                  <Divider sx={{ my: "5px" }} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#888888",
                          fontWeight: "500",
                        }}
                      >
                        Customer Invoice Total
                        <br />
                        Agent Invoice Total
                        <br />
                        {/* Markup
                              <br /> */}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        style={{
                          textAlign: "right",
                          fontSize: "12px",
                          color: "#2D669B",
                          fontWeight: "500",
                        }}
                      >
                        {allData?.grossCost} ৳
                        <br />
                        {allData?.subagentCost} ৳<br />
                        {/* {allData?.journeyType?.toLowerCase() === "inbound"
                                ? `${commaNumber(siteConfig?.dmarkup)} ৳`
                                : `${commaNumber(siteConfig?.imarkup)} ৳`}
                              <br /> */}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "16px",
                        color: "#222222",
                        fontWeight: "500",
                      }}
                    >
                      Agent Saving
                    </Typography>

                    <Typography
                      style={{
                        textAlign: "right",
                        fontSize: "16px",
                        color: "#222222",
                        fontWeight: "500",
                      }}
                    >
                      {savingMoney} ৳
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
                style={{
                  marginBottom: "10px",

                  boxShadow: "0px 0px 4px rgba(255, 168, 77, 0.78)",
                }}
              >
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      height: "20px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#2564B8",
                        fontWeight: "500",
                      }}
                    >
                      PNR History
                    </Typography>
                    <Box>
                      {expanded === "panel2" ? (
                        <RemoveIcon
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "25px",
                          }}
                        />
                      ) : (
                        <AddIcon
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "25px",
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {allData?.activity?.map((data, index) => (
                    <Box className="lineParentBox" key={index}>
                      <Grid container spacing={4}>
                        <Grid item xs={1}>
                          <Box className="note-line">
                            <Box
                              sx={{
                                width: "18px",
                                height: "18px",
                                bgcolor: "var(--primary-color)",
                                position: "absolute",
                                left: "-10px",
                                borderRadius: "50%",
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item mt="-3px" xs={10}>
                          <Typography
                            style={{
                              color: "#2564B8",
                              fontSize: "16px",
                              fontWeight: 400,
                            }}
                          >
                            {data?.status}
                          </Typography>
                          <Box py={1}>
                            <Typography
                              sx={{
                                color: "var(--primary-color)",
                                fontSize: "11px",
                                fontWeight: 400,
                              }}
                            >
                              {data?.status === "Hold" ||
                              data?.status === "Issue In Processing" ||
                              data?.status === "Reissue In Processing" ||
                              data?.status === "Refund In Processing" ||
                              data?.status === "Void In Processing" ? (
                                <>
                                  {data?.actionBy}, {userData[0]?.company}
                                </>
                              ) : (
                                <>{companyName}</>
                              )}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#767676",
                                fontSize: "11px",
                                fontWeight: 400,
                              }}
                            >
                              {data?.actionAt
                                ? format(
                                    new Date(data?.actionAt?.toString()),
                                    "dd MMM yyyy hh:mm a"
                                  )
                                : "Issue Time"}
                            </Typography>
                            <Typography
                              sx={{
                                color: "crimson",
                                fontSize: "12px",
                                fontWeight: 400,
                                mt: "4px",
                              }}
                            >
                              {data?.remarks === "" || data?.remarks === " " ? (
                                ""
                              ) : (
                                <>Remarks: {data?.remarks}</>
                              )}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
                style={{
                  marginBottom: "10px",

                  boxShadow: "0px 0px 4px rgba(255, 168, 77, 0.78)",
                }}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      height: "20px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#2564B8",
                        fontWeight: "500",
                      }}
                    >
                      Download PDF
                    </Typography>
                    <Box>
                      {expanded === "panel3" ? (
                        <RemoveIcon
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "25px",
                          }}
                        />
                      ) : (
                        <AddIcon
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "25px",
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {/* <AllPDf agentID={agentID} bookingId={bookingId} pnr={pnr} /> */}
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
                style={{
                  boxShadow: "0px 0px 4px rgba(255, 168, 77, 0.78)",
                }}
              >
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      height: "20px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#2564B8",
                        fontWeight: "500",
                      }}
                    >
                      Fare Rules
                    </Typography>
                    <Box>
                      {expanded === "panel4" ? (
                        <RemoveIcon
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "25px",
                          }}
                        />
                      ) : (
                        <AddIcon
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "25px",
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                    No automatic fare rules available, Please mail us for fare
                    rules.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            {/* accordion end here s */}

            {/* Document Upload and Issue request start */}
            {allData?.status === "Hold" &&
            allData?.journeyType === "Outbound" ? (
              <Box>
                {allData?.passenger[0]?.passportCopy === "" &&
                allData?.passenger[0]?.visaCopy === "" ? (
                  <Box>
                    <Box className="upload" mt={2}>
                      <button onClick={() => handleOpenUpdateModal()}>
                        Upload Document
                      </button>
                    </Box>
                    <Box className="issueCancel" mt={2}>
                      <button onClick={cancelBooking}>Cancel Ticket</button>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Box
                      sx={{
                        bgcolor: "green",
                        p: "5px",
                        color: "#fff",
                        textAlign: "center",
                      }}
                      my={2}
                    >
                      Document Uploaded ✔
                    </Box>
                    <Box className="issueTicket" mt={2}>
                      <button onClick={handleIssueTicket}>Issue Ticket</button>
                    </Box>
                    <Box className="issueCancel" mt={2}>
                      <button onClick={cancelBooking}>Cancel Ticket</button>
                    </Box>
                  </Box>
                )}
              </Box>
            ) : allData?.status === "Hold" ? (
              <Box>
                <Box className="issueTicket" mt={2}>
                  <button onClick={handleIssueTicket}>Issue Ticket</button>
                </Box>
                <Box className="issueCancel" mt={2}>
                  <button onClick={cancelBooking}>Cancel Ticket</button>
                </Box>
              </Box>
            ) : null}
            {/* Document Upload and Issue request end */}

            {allData.status === "Ticketed" ? (
              <Box>
                {/* Refundable start */}
                {allData?.refundable === "yes" ? (
                  <Box className="queues-detail-calcel-btn">
                    <Box className="issueTicket" mt={2}>
                      <button onClick={() => setOpenModalRefund(true)}>
                        Refund
                      </button>
                    </Box>
                    <Modal
                      open={openModalRefund}
                      onClose={() => setOpenModalRefund(false)}
                      className="custom-modal-r modal-table-0"
                    >
                      <Box className="modalStyler" bgcolor="#fff" p="25px">
                        <Box className="modal-table">
                          <Typography
                            sx={{
                              color: "#222222",
                              fontSize: "20px",
                              fontWeight: 500,
                              mb: "10px",
                            }}
                          >
                            Make Refund Request
                          </Typography>
                          <table>
                            <tr>
                              <th width="5%">Select</th>
                              <th width="35%">Passenger Name</th>
                              <th width="20%">Gender</th>
                              <th width="20%">Passenger Type</th>
                              <th width="30%">Ticket No</th>
                            </tr>
                            {allData?.ticketData?.map((ticket, index) => (
                              <tr key={index}>
                                <td
                                  width="10px"
                                  style={{
                                    border: "none",
                                  }}
                                >
                                  <FormGroup
                                    style={{
                                      padding: "0px",
                                      margin: "0px",
                                    }}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          style={{
                                            padding: "0px",
                                            margin: "auto",
                                            marginLeft: "20px",
                                          }}
                                          onChange={() => handleCheckBox(index)}
                                        />
                                      }
                                    />
                                  </FormGroup>
                                </td>
                                <td>
                                  {ticket?.gender === "Female" ? (
                                    <>
                                      {ticket?.gender === "Female" &&
                                      ticket?.pType === "ADT" ? (
                                        <>MS {ticket?.passengerName}</>
                                      ) : (
                                        <>MISS {ticket?.passengerName}</>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {ticket?.gender === "Male" &&
                                      ticket?.pType === "ADT" ? (
                                        <>MR {ticket?.passengerName}</>
                                      ) : (
                                        <>MSTR {ticket?.passengerName}</>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>{ticket?.gender}</td>
                                <td>
                                  {ticket?.pType === "ADT" ? (
                                    <>Adult</>
                                  ) : ticket?.pType === "CNN" ? (
                                    <>Child</>
                                  ) : (
                                    <>Infant</>
                                  )}
                                </td>
                                <td>{ticket?.ticketno}</td>
                              </tr>
                            ))}
                          </table>
                          <Grid
                            container
                            justifyContent={"space-between"}
                            alignItems="center"
                          >
                            <Grid item mt={2}></Grid>
                            <Grid item mt={4}>
                              <>
                                <button
                                  disabled={!checkBox === true}
                                  style={
                                    isDisabled
                                      ? styles.buttonDisabled
                                      : styles.button
                                  }
                                  onClick={handleRefund}
                                >
                                  {isDisabled ? "Wait for refund" : "Submit"}
                                </button>
                                <button
                                  style={{
                                    padding: "6px 20px",
                                    color: "#fff",
                                    backgroundColor: "crimson",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                  type="reset"
                                  onClick={() => setOpenModalRefund(false)}
                                >
                                  Cancel
                                </button>
                              </>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                      {/* </from> */}
                    </Modal>
                  </Box>
                ) : null}
                {/* Refundable end */}
                {/* Reissue start */}

                {allData?.status === "Ticketed" ? (
                  <Box className="queues-detail-calcel-btn">
                    <Box className="issueTicket" mt={2}>
                      <button onClick={() => setOpenModalReIssue(true)}>
                        Reissue
                      </button>
                    </Box>
                    <Modal
                      open={openModalReIssue}
                      onClose={() => setOpenModalReIssue(false)}
                      className="custom-modal-r modal-table-0"
                    >
                      <Box className="modalStyler" bgcolor="#fff" p="25px">
                        <Box className="modal-table">
                          <Typography
                            sx={{
                              color: "#222222",
                              fontSize: "20px",
                              fontWeight: 500,
                              mb: "10px",
                            }}
                          >
                            Make Re-Issue Request
                          </Typography>
                          <table>
                            <tr>
                              <th width="5%">Select</th>
                              <th width="35%">Passenger Name</th>
                              <th width="20%">Gender</th>
                              <th width="20%">Passenger Type</th>
                              <th width="30%">Ticket No</th>
                            </tr>
                            {allData?.ticketData?.map((ticket, index) => (
                              <tr key={index}>
                                <td width="10px" style={{ border: "none" }}>
                                  <FormGroup
                                    style={{
                                      padding: "0px",
                                      margin: "0px",
                                    }}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          style={{
                                            padding: "0px",
                                            margin: "auto",
                                            marginLeft: "20px",
                                          }}
                                          onChange={() => handleCheckBox(index)}
                                        />
                                      }
                                    />
                                  </FormGroup>
                                </td>
                                <td>
                                  {ticket?.gender === "Female" ? (
                                    <>
                                      {ticket?.gender === "Female" &&
                                      ticket?.pType === "ADT" ? (
                                        <>MS {ticket?.passengerName}</>
                                      ) : (
                                        <>MISS {ticket?.passengerName}</>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {ticket?.gender === "Male" &&
                                      ticket?.pType === "ADT" ? (
                                        <>MR {ticket?.passengerName}</>
                                      ) : (
                                        <>MSTR {ticket?.passengerName}</>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>{ticket?.gender}</td>
                                <td>
                                  {ticket?.pType === "ADT" ? (
                                    <>Adult</>
                                  ) : ticket?.pType === "CNN" ? (
                                    <>Child</>
                                  ) : (
                                    <>Infant</>
                                  )}
                                </td>
                                <td>{ticket?.ticketno}</td>
                              </tr>
                            ))}
                          </table>
                          <Grid
                            container
                            justifyContent={"space-between"}
                            alignItems="center"
                          >
                            <Grid item mt={2}>
                              <Box>
                                <label htmlFor="date">Select Date</label> <br />
                                <input
                                  style={{
                                    border: "2px solid #C4C4C4",
                                    padding: "5px",
                                    fontSize: "14px",
                                    cursor: "pointer",
                                  }}
                                  required
                                  type="text"
                                  name="date"
                                  readOnly
                                  value={format(new Date(date), "dd MMM yyyy")}
                                  onClick={() => {
                                    setOpenDate((prev) => !prev);
                                  }}
                                />
                                {openDate && (
                                  <Calendar
                                    color={"#222222"}
                                    date={new Date(date)}
                                    onChange={(date) => {
                                      setDate(
                                        new Date(date).toLocaleDateString("sv")
                                      );
                                      setOpenDate(false);
                                    }}
                                    minDate={new Date(date)}
                                    months={1}
                                    direction="horizontal"
                                    className="new-dashboard-calendar"
                                    name="dashboard-calendar"
                                  />
                                )}
                              </Box>
                            </Grid>
                            <Grid item mt={4}>
                              <>
                                <button
                                  disabled={!checkBox === true}
                                  style={
                                    isDisabled
                                      ? styles.buttonDisabled
                                      : styles.button
                                  }
                                  onClick={handleReissue}
                                >
                                  {isDisabled ? "Wait for reissue" : "Submit"}
                                </button>
                                <button
                                  style={{
                                    padding: "6px 20px",
                                    color: "#fff",
                                    backgroundColor: "crimson",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                  type="reset"
                                  onClick={() => setOpenModalReIssue(false)}
                                >
                                  Cancel
                                </button>
                              </>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                      {/* </from> */}
                    </Modal>
                  </Box>
                ) : null}

                {/* Reissue end */}

                {/* Void start */}
                {allData?.status === "Ticketed" && todaydate <= voideDate ? (
                  <Box className="queues-detail-calcel-btn">
                    <Box className="issueTicket" mt={2}>
                      <button onClick={() => setOpenModalVoid(true)}>
                        Void
                      </button>
                    </Box>
                    <Modal
                      open={openModalVoid}
                      onClose={() => setOpenModalVoid(false)}
                      className="custom-modal-r modal-table-0"
                    >
                      <Box className="modalStyler" bgcolor="#fff" p="25px">
                        <Box className="modal-table">
                          <Typography
                            sx={{
                              color: "#222222",
                              fontSize: "20px",
                              fontWeight: 500,
                              mb: "10px",
                            }}
                          >
                            Make Void Request
                          </Typography>
                          <table>
                            <tr>
                              <th width="5%">Select</th>
                              <th width="35%">Passenger Name</th>
                              <th width="20%">Gender</th>
                              <th width="20%">Passenger Type</th>
                              <th width="30%">Ticket No</th>
                            </tr>
                            {invoiceId.map((ticket, index) => (
                              <tr key={index}>
                                <td width="10px" style={{ border: "none" }}>
                                  <FormGroup
                                    style={{
                                      padding: "0px",
                                      margin: "0px",
                                    }}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          style={{
                                            padding: "0px",
                                            margin: "auto",
                                            marginLeft: "20px",
                                          }}
                                          onChange={() => handleCheckBox(index)}
                                        />
                                      }
                                    />
                                  </FormGroup>
                                </td>
                                <td>
                                  {ticket?.gender === "Female" ? (
                                    <>
                                      {ticket?.gender === "Female" &&
                                      ticket?.pType === "ADT" ? (
                                        <>MS {ticket?.passengerName}</>
                                      ) : (
                                        <>MISS {ticket?.passengerName}</>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {ticket?.gender === "Male" &&
                                      ticket?.pType === "ADT" ? (
                                        <>MR {ticket?.passengerName}</>
                                      ) : (
                                        <>MSTR {ticket?.passengerName}</>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>{ticket?.gender}</td>
                                <td>
                                  {ticket?.pType === "ADT" ? (
                                    <>Adult</>
                                  ) : ticket?.pType === "CNN" ? (
                                    <>Child</>
                                  ) : (
                                    <>Infant</>
                                  )}
                                </td>
                                <td>{ticket?.ticketno}</td>
                              </tr>
                            ))}
                          </table>
                          <Grid
                            container
                            justifyContent={"space-between"}
                            alignItems="center"
                          >
                            <Grid item mt={2}></Grid>
                            <Grid item mt={4}>
                              <>
                                <button
                                  disabled={!checkBox === true}
                                  style={
                                    isDisabled
                                      ? styles.buttonDisabled
                                      : styles.button
                                  }
                                  onClick={handleVoid}
                                >
                                  {isDisabled ? "Wait for void" : "Submit"}
                                </button>

                                <button
                                  style={{
                                    padding: "6px 20px",
                                    color: "#fff",
                                    backgroundColor: "crimson",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                  type="reset"
                                  onClick={() => setOpenModalVoid(false)}
                                >
                                  Cancel
                                </button>
                              </>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                      {/* </from> */}
                    </Modal>
                  </Box>
                ) : null}
                {/* Void end */}
              </Box>
            ) : allData?.status === "Issue In Processing" ? (
              <>
                <Box className="queues-detail-wait-btn">
                  <button>Wait For Ticketed</button>
                </Box>
              </>
            ) : allData?.status === "Refund In Processing" ? (
              <Box className="queues-detail-wait-btn">
                <button>Wait For Refunded</button>
              </Box>
            ) : allData?.status === "Reissue In Processing" ? (
              <Box className="queues-detail-wait-btn">
                <button>Wait For Reissued</button>
              </Box>
            ) : allData?.status === "Void In Processing" ? (
              <Box className="queues-detail-wait-btn">
                <button>Wait For Voided</button>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Container>
      {/* //todo: Update Document Modal */}
      <Modal open={openUpdateModal} onClose={handleCloseUpdateModal}>
        <Box sx={updateModalStyle}>
          <FileUploadSection
            setState={setState}
            passengerData={allData?.passenger}
            handleIssueTicket={handleIssueTicket}
            handleCloseUpdateModal={handleCloseUpdateModal}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default QueuesDetail;
