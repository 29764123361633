import React from 'react';
import { Box, Container, Typography } from "@mui/material";
const AdminTransectionReport = () => {





    return <Box>
    <Container maxWidth="xl">
    <Box
    sx={{
      margin: "0px 0px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    <Typography
      variant="span"
      sx={{
        fontWeight: 500,
        fontSize: "24px",
        margin: "10px 0px",
        color: "#222222",
        fontFamily: "poppins",
      }}
    >
    General Ledger
    </Typography>

  
  </Box>

    <Box className="balance-transaction" marginTop={"20px"}>
  <table>
    <tr>
      <th>Sl No</th>
      <th>Ledger Type</th>
      <th>Transaction ID</th>
      <th>Transaction Dtae</th>
      <th>Transaction Dteails</th>
      <th>Transaction Amount</th>
      <th>Last Balance</th>
    </tr>

    <tr>
      <td>01</td>
      <td>Purchase</td>
      <td>51151515355</td>
      <td>27 Nov 2022 11:53 AM</td>
      <td>oneway Air Ticket DAC - DXB - Oman Air By Agent</td>
      <td>12556</td>
      <td>44544</td>
    </tr>

    <tr>
      <td>02</td>
      <td>Deposit</td>
      <td>51151515355</td>
      <td>27 Nov 2022 11:53 AM</td>
      <td>oneway Air Ticket DAC - DXB - Oman Air By Agent</td>
      <td>12556</td>
      <td>44544</td>
    </tr>
    <tr>
      <td>03</td>
      <td>Purchase</td>
      <td>51151515355</td>
      <td>27 Nov 2022 11:53 AM</td>
      <td>oneway Air Ticket DAC - DXB - Oman Air By Agent</td>
      <td>12556</td>
      <td>44544</td>
    </tr>
  </table>
</Box>

    </Container>

</Box>;;
}


export default AdminTransectionReport;