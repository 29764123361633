import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Box,
  Grid,
  Modal,
  Typography,
  Divider,
} from "@mui/material";
import commaNumber from "comma-number";
import confirm from "../../images/Icon/confirm 1.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container } from "@mui/system";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../images/loader/Render.gif";
import axios from "axios";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import { format } from "date-fns";
import Invalid from "../../images/undraw/undraw_warning_re_eoyh.svg";
import ReConfirm from "../../images/undraw/undraw_confirmation_re_b6q5.svg";
import Issue from "../../images/undraw/undraw_booking_re_gw4j.svg";
import AirlineName from "../AirlineName/AirlineName";
import "./Congratulation.css";
import FlightIcon from "@mui/icons-material/Flight";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
// import AllPDF from "../Queues/Queues/QueuesDetail/AllPdf";
import Header from "../Header/Header";
import FileUploadSection from "../Shared/FileUploadSection/FileUploadSection";
import { ToWords } from "to-words";
import flightData from "../flightData";

const AirlineNames = ({ code }) => {
  const flightName = AirlineName;
  let airname;
  {
    flightName?.map((item) => {
      if (item?.code === code?.replace(/ /g, "")) {
        airname = item?.name;
      }
    });
  }
  return airname;
};

const Address = ({ code }) => {
  const flightName = flightData;
  let address;
  {
    flightName?.map((item) => {
      if (item?.code === code?.replace(/ /g, "")) {
        address = item?.name;
      }
    });
  }
  return address;
};

const Congratulation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [expanded, setExpanded] = useState("panel1");
  const [downExpanded, setDownExpanded] = useState();
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleChangeDown = (panel) => (event, newExpanded) => {
    setDownExpanded(newExpanded ? panel : false);
  };
  const [returnBtn, setReturnBtn] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [issueLoading, setIssueLoading] = useState(false);

  const [companyName, setCompanyName] = useState("");

  const [siteConfig, setSiteConfig] = useState({});
  // todo:for multicity
  const [multiCityAllData, setMultiCityAllData] = useState([]);
  //todo: calculate total flight duration
  const calDuration = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? "0" + h : h; // (or alternatively) h = String(h).padStart(2, '0')
    m = m < 10 ? "0" + m : m; // (or alternatively) m = String(m).padStart(2, '0')
    return `${h}:${m}`;
  };
  // !end
  const toWords = new ToWords();

  // --------------------- client information start ---------------------------
  const users = secureLocalStorage.getItem("user-info");
  let agentID = users?.user?.agentId;
  let agentName = users?.user?.name;
  let staffName = users?.user?.name;
  let userStaffId = users?.user?.staffId;

  const [isDone, setIsDone] = useState(true);

  const [allData, setAllData] = useState([]);
  const [userData, setUserData] = useState([0]);
  const [flightName, setFlightName] = useState([]);
  const [passengerData, setPassengerData] = useState([]);
  const [invoiceId, setInvoiceId] = useState([]);

  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState(format(new Date(), "dd-MMM-yyyy"));

  //todo:state change
  const [state, setState] = useState(false);

  // visa and passport copy update state
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const handleOpenUpdateModal = () => setOpenUpdateModal(true);
  const handleCloseUpdateModal = () => {
    setState((prev) => !prev);
    setOpenUpdateModal(false);
    // navigate(0);
  };
  const updateModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "#fff",
    p: 4,
    borderRadius: "10px",
  };
  const agentId = location?.state?.agentId;
  const bookingId = location?.state?.BookingId;
  const tripType = location?.state.tripType;
  const body = {
    agentId,
    bookingId,
  };
  const [flightData, setFlightData] = useState([]);
  const [flightTotal, setFlightTotal] = useState([]);

  const url = `http://api.flyjatt.com/v1/AirBooking/AirRetrive.php`;
  // End of visa and passport copy update
  useEffect(() => {
    setIsLoading(true);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        if (tripType === "multicity") {
          setAllData({ ...data[0], journeyType: "Outbound" });
        } else if (tripType === "return") {
          setPassengerData(data.travelers);
          setFlightData(data.flights);
          setFlightTotal(data.payments.flightTotals);
          setAllData(data[0]);
        } else {
          setPassengerData(data.travelers);
          setFlightData(data.flights);
          setFlightTotal(data.payments.flightTotals);
          setAllData(data[0]);
        }
        // setInvoiceId(data[0]?.ticketData);
      });

    setIsLoading(false);
  }, [bookingId, agentId]);

  const cancelBooking = (system, pnr) => {
    Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      title: "Are you sure?",
      text: "You Wants to Cancel this Flight ?",
      showCancelButton: true,
      confirmButtonColor: "#003566",
      confirmButtonText: "Yes Cancel it !",
      cancelButtonColor: "#dc143c",
      cancelButtonText: "Don't Cancel it !",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        setIsDone(false);
        setOpen(false);
        setIsLoading(false);
        let url = `https://api.flyjatt.com/v.1.0.0/AirBooking/AirCancel.php`;
        let body = JSON.stringify({
          bookingId: bookingId,
          cancelBy: staffName || agentID,
          platform: "B2B",
        });
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: body,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data?.status === "success") {
              Swal.fire({
                // icon: "success",
                // imageUrl: cancelImg,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: "Custom image",
                title: "Your Flight is Cancel!",
                html: `For any query.Please contact us at <strong>${siteConfig?.email}</strong> or Call <strong>${siteConfig?.phone}</strong>`,
                confirmButtonText: "OK",
                cancelButtonColor: "#dc143c",
              }).then(() => {
                setIsDone(true);
                navigate("/queues");
              });
            } else {
              throw new Error("error");
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err.message);
            Swal.fire({
              // imageUrl: cancelFailed,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              title: "Booking Cancel Failed!",
              html: `For any query.Please contact us at <strong>${siteConfig?.email}</strong> or Call <strong>${siteConfig?.phone}</strong>`,
              confirmButtonText: "OK",
              cancelButtonColor: "#dc143c",
            }).then(() => {
              setIsDone(true);
              navigate(0);
            });
          });
      }
    });
  };

  //--------------- Booking cancel handle end ------------------
  //--------------- Issue Ticket Start ------------------
  const issueData = {
    agentId: agentID || "Agent",

    bookingId: bookingId || "BookingId",
  };

  async function handleIssueTicket() {
    Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      title: "Are you sure?",
      text: "You wants to issue this ticket ?",
      showCancelButton: true,
      confirmButtonColor: "var(--primary-color)",
      confirmButtonText: "Yes Issue it !",
      cancelButtonColor: "crimson",
      cancelButtonText: "Don't Issue it !",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        setIsDone(false);
        setOpen(false);
        setIsLoading(false);
        let url = `https://api.flyjatt.com/v.1.0.0/WhiteLabel/Booking/AirTicket.php`;
        let body = JSON.stringify(issueData);
        // console.log(url);
        // console.log(body);
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: body,
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);
            if (data?.status.toLowerCase() === "success") {
              Swal.fire({
                imageUrl: Issue,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: "Custom image",
                title: "Issue Ticket Request In Process",
                html: `For any query.Please contact us at <strong>${siteConfig?.email}</strong> or Call <strong>${siteConfig?.phone}</strong>`,
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              }).then(function () {
                setIsDone(true);
                setIssueLoading(false);
                navigate("/queues");
              });
            } else {
              Swal.fire({
                imageUrl: Invalid,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: "Custom image",
                title: `${data?.message}`,
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              }).then(function () {
                setIsDone(true);
                setIssueLoading(false);
                navigate(0);
              });
            }
            setIsLoading(false);
          })
          .catch((err) => {
            Swal.fire({
              imageUrl: Invalid,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              title: "Issue Ticket Failed!",
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            }).then(function () {
              setIsDone(true);
              setIssueLoading(false);
              navigate(0);
            });
          });
      }
    });
  }

  //--------------- Isssue Ticket end ------------------
  // -------------- data get from queue page -------------
  // ------------ country name find-out ------------

  // ---------------------- Table row handle by filter start------------------=

  //  all passenger total price calculation
  const adultTotalPrice =
    parseInt(allData?.adultCostBase) + parseInt(allData?.adultCostTax);
  const childTotalPrice =
    parseInt(allData?.childCostBase) + parseInt(allData?.childCostTax);

  const infantTotalPrice =
    parseInt(allData?.infantCostBase) + parseInt(allData?.infantCostTax);

  const totalPrice = adultTotalPrice + childTotalPrice + infantTotalPrice;

  const savingMoney = Math.abs(
    parseInt(allData?.grossCost) - parseInt(allData?.netCost)
  );

  // ---------------------- Table row handle by filter end ------------------

  //todo remove last word function

  const [reissueData, setReIssueData] = useState();
  const [checkBox, setCheckBox] = useState();
  const handleCheckBox = (index) => {
    const e = window.event;
    const tempData = [...invoiceId];
    tempData[index] = { ...tempData[index], checkBox: e.target.checked };
    setCheckBox(e.target.checked);
    setInvoiceId(tempData);
  };

  let reissue = [];

  if (!isDone) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "40%",
              objectFit: "center",
            }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Header />
      <Container>
        <Box position={"relative"}>
          {!isLoading ? (
            <Box sx={{ marginTop: "50px" }}>
              <Box display="flex" my={4}>
                <Box marginRight={2}>
                  <img src={confirm} alt="..." />
                </Box>
                <Box>
                  <Typography variant="h5" color="var(--green-color)">
                    Thank You. Your Booking is Confirmed
                  </Typography>
                  <Typography>
                    A Confirmation email has been sent to your provided email
                    address.
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography>Booking Id {bookingId || ""}</Typography>
              </Box>
              <Box my={3}>
                <Typography>Flight Information</Typography>
                <Box mt={2} className="flight-queue-detail">
                  <Box mt={2}>
                    <table width="100%">
                      <tr>
                        <th width="15%">Flight</th>
                        <th width="23%">Departure From</th>
                        <th width="23%">Arrival To</th>
                        <th width="10%">Depart At</th>
                        <th width="10%">Arrive At</th>
                        <th width="19%">Info</th>
                      </tr>

                      {flightData.length === 0 ? (
                        <Box>Loading...</Box>
                      ) : (
                        <>
                          {flightData?.map((data, index) => (
                            <tr>
                              <td>
                                <AirlineNames code={data.airlineCode} />{" "}
                                {data?.airlineCode}-{data?.flightNumber}
                                <br />
                                {data?.aircraftTypeName}
                              </td>
                              <td>
                                ({data.fromAirportCode}){" "}
                                <Address code={data.fromAirportCode} />
                                <br />
                                {data?.departureTerminalName || null}{" "}
                                {data?.departureGate
                                  ? `Gate: ${data?.departureGate || null}`
                                  : null}
                              </td>
                              <td>
                                ({data.toAirportCode}){" "}
                                <Address code={data.toAirportCode} />
                                <br />
                                {data?.arrivalTerminalName || null}{" "}
                                {data?.arrivalGate
                                  ? `Gate: ${data?.arrivalGate || null}`
                                  : null}
                              </td>
                              <td>
                                {data.departureDate}{" "}
                                {data.departureTime?.slice(0, 5)}
                              </td>
                              <td>
                                {data.arrivalDate}{" "}
                                {data.arrivalTime?.slice(0, 5)}
                              </td>
                              <td>
                                {data?.cabinTypeName?.toLowerCase()}-
                                {data?.bookingClass}
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </table>
                  </Box>
                </Box>
              </Box>
              <Box my={3}>
                <Typography>Price Breakdown</Typography>
              </Box>
              <Box my={3}>
                <Typography>Paddenger Details</Typography>
              </Box>
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
                width: "70vw",
                marginInline: "auto",
              }}
            >
              <Box
                style={{
                  width: "50%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Loader}
                  alt="loader"
                  style={{
                    width: "40%",
                    objectFit: "center",
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
        {/* //todo: Update Document Modal */}
        <Modal open={openUpdateModal} onClose={handleCloseUpdateModal}>
          <Box sx={updateModalStyle}>
            <FileUploadSection
              setState={setState}
              passengerData={allData?.passenger}
              handleIssueTicket={handleIssueTicket}
              handleCloseUpdateModal={handleCloseUpdateModal}
            />
          </Box>
        </Modal>
      </Container>
    </Box>
  );
};

export default Congratulation;
