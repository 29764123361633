import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ServerDown from "../images/undraw/undraw_server_down_s-4-lk.svg";

import Swal from "sweetalert2";

const useAuthentication = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const loginUser = (loginData, location, navigate) => {
    setIsLoading(false);
    secureLocalStorage.setItem("state", loginData);
    let body = JSON.stringify({
      email: loginData.email,
      password: loginData.password,
    });
    let url = `https://api.flyjatt.com/v1/Auth/index.php?login`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(true);
        if (data.message === "success") {
          secureLocalStorage.setItem("user-info", data);
          secureLocalStorage.setItem("commissionData", {
            agentFarePrice: true,
            commissionFarePrice: true,
            defaultCommissionRate: 0,
            defaultCommissionRateAmount: 0,
            customizeRadio: "percent",
            openCustomize: false,
            openDefault: true,
            customerFare: true,
          });
          const destination = location?.state?.from || "/user/dashboard";
          navigate(destination);
        } else {
          secureLocalStorage.removeItem("user-info");
          secureLocalStorage.removeItem("commissionData");
          setError(data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
        Swal.fire({
          imageUrl: ServerDown,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "Error!",
          html: `<strong>For any Query contact contact@flyjatt.com or <strong>+971509517784, .</strong>`,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Please Try Again!",
        });
      })
      .finally(() => setIsLoading(true));
  };

  // Admin login
  const adminLogin = (loginData, location, navigate) => {
    setIsLoading(false);
    secureLocalStorage.setItem("state", loginData);
    let url = `https://api.flyjatt.com/v1/Auth/admin.php?login`;
    let body = JSON.stringify({
      email: loginData.email.trim(),
      password: loginData.password.trim(),
    });

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(true);
        if (data?.action === "complete" && data?.message === "success") {
          secureLocalStorage.setItem("admin-info", data);
          const destination = location?.state?.from || "/admin/dashboard";
          navigate(destination);
        } else {
          secureLocalStorage.removeItem("admin-info");
          setError(data.message);
        }
      })
      .finally(() => setIsLoading(true));
  };

  const logout = () => {
    secureLocalStorage.removeItem("user-info");
    secureLocalStorage.removeItem("admin-info");
    secureLocalStorage.removeItem("commissionData");
    secureLocalStorage.removeItem("state");
    navigate("/");
  };

  return {
    adminLogin,
    loginUser,
    logout,
    isLoading,
    error,
  };
};

export default useAuthentication;
