import { Box, Container } from "@mui/material";
import React from "react";
import AdminHeader from "../AdminHeader/AdminHeader";


import AccountDetails from "./AccountDetails";
import StaffDetails from "./StaffDetails";

const AdminAccount = () => {
  return (
    <Box>
      <AdminHeader />

      <Container>
        <Box mt={2}>
          <AccountDetails />
          <StaffDetails />
        </Box>
      </Container>
    </Box>
  );
};

export default AdminAccount;
