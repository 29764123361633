import { Box, Container, Link, Typography } from "@mui/material";
import React from "react";
import Footer from "../../components/Footer/Footer";
import FullScreenSlider from "../../components/FullScreenSlider/FullScreenSlider";
import HomeSearchBox from "../../components/HomeSearchBox/HomeSearchBox";
import { LandingHeader } from "../../components/LandingHeader/LandingHeader";
import { MoreDeals } from "../../components/MoreDeals/MoreDeals";
import { RecentSearch } from "../../components/RecentSearch/RecentSearch";
import { TrendingDeals } from "../../components/TrendingDeals/TrendingDeals";
import homeBg from "../../images/homeBg/homeBg.jpg";
import airplane from "../../images/landingPage/airplane.png";
import hotel from "../../images/landingPage/hotel.png";
import holiday from "../../images/landingPage/holiday.png";
import visa from "../../images/landingPage/visa.png";
import steeringWheel from "../../images/landingPage/steering-wheel.png";
import "./home.css";

const Home = () => {
  const [type, setType] = React.useState("flight");
  const handleTypeChange = (event, newValue) => {
    setType(newValue);
  };
  return (
    <Box>
      <Box
        className="home-banner"
        sx={{
          width: "100%",
          height: "100vh",
          backgroundImage: `url(${homeBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <LandingHeader />
        <Box
          sx={{
            width: "100%",
            height: "calc(100vh - 70px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "70px",
          }}
        >
          <Typography
            sx={{
              color: "var(--white)",
              fontSize: { md: "70px", sm: "24px", xs: "24px" },
              fontWeight: "500px",
              textTransform: "uppercase",
            }}
          >
            let's travel the world
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "40px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              href="#searchbar"
              sx={{
                textDecoration: "none",
                width: "120px",
                height: "120px",
                background: "var(--white)",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px",
                gap: "20px",
              }}
              onClick={() => setType("flight")}
            >
              <Box
                sx={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "50%",
                  background:
                    "radial-gradient(ellipse at center,  var(--landing-page-circle) 0%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%)",
                  backgroundSize: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={airplane} alt="..." />
              </Box>

              <Typography
                sx={{
                  color: "var(--secondary-color)",
                  fontSize: "24px",
                  textTransform: "uppercase",
                  fontWeight: "500",
                }}
              >
                flights
              </Typography>
            </Link>
            <Link
              href="#searchbar"
              sx={{
                textDecoration: "none",
                width: "120px",
                height: "120px",
                background: "var(--white)",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px",
                gap: "20px",
              }}
              onClick={() => setType("hotel")}
            >
              <Box
                sx={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "50%",
                  background:
                    "radial-gradient(ellipse at center,  var(--landing-page-circle) 0%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%)",
                  backgroundSize: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={hotel} alt="..." />
              </Box>

              <Typography
                sx={{
                  color: "var(--secondary-color)",
                  fontSize: "24px",
                  textTransform: "uppercase",
                  fontWeight: "500",
                }}
              >
                hotel
              </Typography>
            </Link>
            <Link
              href="#searchbar"
              sx={{
                textDecoration: "none",
                width: "120px",
                height: "120px",
                background: "var(--white)",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px",
                gap: "20px",
              }}
              onClick={() => setType("holiday")}
            >
              <Box
                sx={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "50%",
                  background:
                    "radial-gradient(ellipse at center,  var(--landing-page-circle) 0%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%)",
                  backgroundSize: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={holiday} alt="..." />
              </Box>

              <Typography
                sx={{
                  color: "var(--secondary-color)",
                  fontSize: "24px",
                  textTransform: "uppercase",
                  fontWeight: "500",
                }}
              >
                holiday
              </Typography>
            </Link>
            <Link
              href="#searchbar"
              sx={{
                textDecoration: "none",
                width: "120px",
                height: "120px",
                background: "var(--white)",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px",
                gap: "20px",
              }}
              onClick={() => setType("visa")}
            >
              <Box
                sx={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "50%",
                  background:
                    "radial-gradient(ellipse at center,  var(--landing-page-circle) 0%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%)",
                  backgroundSize: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={visa} alt="..." />
              </Box>

              <Typography
                sx={{
                  color: "var(--secondary-color)",
                  fontSize: "24px",
                  textTransform: "uppercase",
                  fontWeight: "500",
                }}
              >
                visa
              </Typography>
            </Link>
            <Box
              sx={{
                width: "120px",
                height: "120px",
                background: "var(--white)",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "50%",
                  background:
                    "radial-gradient(ellipse at center,  var(--landing-page-circle) 0%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%,var(--landing-page-circle) 50%)",
                  backgroundSize: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={steeringWheel} alt="..." />
              </Box>

              <Typography
                sx={{
                  color: "var(--secondary-color)",
                  fontSize: "24px",
                  textTransform: "uppercase",
                  fontWeight: "500",
                }}
              >
                license
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ py: "50px" }} id="searchbar">
        <HomeSearchBox
          type={type}
          setType={setType}
          handleTypeChange={handleTypeChange}
        />
      </Box>
      <RecentSearch />
      <TrendingDeals />
      <MoreDeals />
      <FullScreenSlider />
      <Footer />
    </Box>
  );
};

export default Home;
