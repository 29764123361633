import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import commaNumber from "comma-number";
import Loader from "../../../images/loader/Render.gif";
import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Stack } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import "./Queues.css";
import { format } from "date-fns";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "../../Header/Header";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2564b8",
      darker: "#2564b8",
    },
  },
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
const Queues = () => {
  // user get from localStorage

  const users = secureLocalStorage.getItem("user-info");
  let agentID = "TFA1000";
  // let agentID = users?.user?.agentId;
  let staffId = users?.user?.staffId;
  // Modal
  const [open, setOpen] = useState(false);
  const [BookingId, setBookingId] = useState({});
  const [agentId, setAgentId] = useState({});
  const [staff, setStaff] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [modalDetails, setModalDetails] = useState([]);
  const handleClose = () => setOpen(false);
  const [userData, setUserData] = useState([]);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [shortValues, setShortValue] = useState([]);
  const [allShortValues, setAllShortValues] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  // Sets the state of the const for the given page and state.
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState("20");

  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setShortValue(allShortValues?.slice((value - 1) * size, value * size));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  // data load from booking api
  useEffect(() => {
    setIsLoading(false);
    fetch(`https://api.flyjatt.com/v1/Queues/index.php?agentId=${agentID}`)
      .then((res) => res.json())
      .then((data) => {
        const uniqueData = data;
        const count = uniqueData.length;
        const pageNumber = Math.ceil(count / size);
        setPageCount(pageNumber);
        setShortValue(data);
        setAllShortValues(data);
        setIsLoading(true);
      });
    //todo: end of fetch all data
  }, [agentID, size]);
  //  short functionality handle
  const handleChangeOption = (e) => {
    const optionValue = e.target.value;
    fetchData(optionValue);
  };

  async function fetchData(optionValue) {
    await fetch(
      `https://api.flyjatt.com/v.1.0.0/Queues/Booking.php?agentId=${agentID}&search=${optionValue}`
    )
      .then((res) => res.json())
      .then((data) => {
        const uniqueData = data;
        const count = uniqueData.length;
        const pageNumber = Math.ceil(count / size);
        setPageCount(pageNumber);
        setShortValue(data);
        setAllShortValues(data);
        setIsLoading(true);
      });
  }

  const handelSearchItems = (e) => {
    let searchInput = e.target.value;
    if (searchInput !== "") {
      const filterData = bookingDetails.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });

      setShortValue(filterData);
    } else if (searchInput === "") {
      setShortValue(bookingDetails);
    }
  };

  // Booking cancel handle

  // async function cancelBooking(pnrId, gds) {
  //   setOpen(false);
  //   await fetch(
  //     `https://api.flyjatt.com/v.1.0.0/${gds}/AirCancel.php?BookingID=${pnrId}`
  //   )
  //     .then((res) => res.json())
  //     .then((data) => {
  //       if (data?.request?.cancelAll === true) {
  //         swal({
  //           title: "Booking Cancel Successfully!",
  //           icon: "success",
  //           button: "Done!",
  //         }).then(function () {
  //           window.location.href = "/dashboard/queues/queues";
  //         });
  //       } else {
  //         swal({
  //           title: "There is a problem",
  //           icon: "error",
  //           button: "Done!",
  //         });
  //       }
  //     });
  // }
  //  pdf function

  const navigate = useNavigate();
  // const queuePdf = () => {
  //   navigate("/dashboard/queuepdf", {
  //     state: {
  //       modalDetails,
  //     },
  //   });
  // };

  const sendToQueuesDetails = (agentId, BookingId, tripType, Pnr) => {
    navigate("/user/congratulation", {
      state: {
        agentId,
        BookingId,
        tripType,
        Pnr,
      },
    });
  };
  return (
    <Box>
      <Header />
      <Box mt={4}>
        <Container sx={{ padding: { md: "0" } }}>
          {isLoading ? (
            <Box className={"q-box"}>
              <Box className="queues-search-box">
                <Grid
                  className="queues-search-box-flied"
                  container
                  // justifyContent={"space-between"}
                  alignItems={"center"}
                  spacing={2}
                >
                  <Grid
                    className="queues-input-search"
                    item
                    xs={6}
                    sm={6}
                    md={6}
                  >
                    <Typography
                      sx={{ color: "var(--mateBlack)", fontSize: "23px" }}
                    >
                      Queue Management
                    </Typography>
                    <Typography style={{ color: "var(--primary-color)" }}>
                      You can find your all air ticket booking details here
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        justifyContent: "flex-end",
                      }}
                    >
                      <Box>
                        <div class="search-box">
                          <input
                            class="search-text"
                            type="text"
                            placeholder="Search....."
                            onChange={(e) => handelSearchItems(e.target.value)}
                          />
                          <Button class="search-btn">
                            <SearchIcon />
                          </Button>
                        </div>
                      </Box>

                      <select
                        onChange={handleChangeOption}
                        style={{ outline: "none" }}
                      >
                        <option value="all">&nbsp;Show All</option>
                        <option value="hold">&nbsp;Hold</option>
                        <option value="Issue In Processing">
                          &nbsp;Issue In Processing
                        </option>
                        <option value="Ticketed">&nbsp;Ticketed</option>

                        <option value="Void In Processing">
                          &nbsp;Void In Processing
                        </option>
                        <option value="Voided">&nbsp;Voided</option>
                        <option value="Void Rejected">
                          &nbsp;Void Rejected
                        </option>

                        <option value="Reissue In Processing">
                          &nbsp;Reissue In Processing
                        </option>
                        <option value="Reissued">&nbsp;Reissued</option>
                        <option value="Reissue Rejected">
                          &nbsp;Reissue Rejected
                        </option>

                        <option value="Refund In Processing">
                          &nbsp;Refund In Processing
                        </option>
                        <option value="Refunded">&nbsp;Refunded</option>
                        <option value="Refund Rejected">
                          &nbsp;Refund Rejected
                        </option>
                        <option value="Issue Rejected">
                          &nbsp;Issue Rejected
                        </option>
                        <option value="cancelled">&nbsp;Cancelled</option>
                      </select>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Box
                  style={{ overflowX: "auto" }}
                  className="balance-transaction"
                >
                  <table>
                    <thead>
                      <tr>
                        <th>SL. No</th>
                        <th>Reference No</th>
                        <th>Airlines</th>
                        <th>Status</th>
                        <th>Route</th>
                        <th>Flight Type</th>
                        <th colSpan={4}>Booking Information</th>
                        <th>Total Cost</th>
                      </tr>

                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style={{ backgroundColor: "var(--primary-color)" }}>
                          Total Pax
                        </th>
                        <th style={{ backgroundColor: "var(--primary-color)" }}>
                          Booked By
                        </th>
                        <th style={{ backgroundColor: "var(--primary-color)" }}>
                          Booked Date
                        </th>
                        <th style={{ backgroundColor: "var(--primary-color)" }}>
                          Passenger Name
                        </th>

                        {/* <th></th> */}
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {shortValues?.slice(0, size)?.map((bookingDetail) => (
                        <tr>
                          <td>{bookingDetail.serial || "SL"}</td>
                          <td>
                            <button
                              style={{
                                background: "transparent",
                                border: "none",
                                cursor: "pointer",
                                backgroundColor: "#d1e9ff",
                                padding: "5px 15px",
                                color: "#003566",
                                textDecoration: "underline",
                              }}
                              onClick={() =>
                                sendToQueuesDetails(
                                  bookingDetail?.AgentId,
                                  bookingDetail?.BookingId,
                                  bookingDetail?.TripType,
                                  bookingDetail?.Pnr
                                )
                              }
                            >
                              {bookingDetail.BookingId ?? "Reference No"}
                            </button>
                          </td>
                          <td>{bookingDetail.Career ?? "Airlines"}</td>
                          <td>
                            {bookingDetail.Status ? (
                              <button
                                style={{
                                  border: "none",
                                  borderRadius: "5px",
                                  width: "100%",
                                }}
                                className={`${bookingDetail.Status?.toLowerCase()
                                  ?.split(" ")
                                  ?.join("-")}-btn`}
                              >
                                {bookingDetail.Status}
                              </button>
                            ) : (
                              "Status"
                            )}
                          </td>
                          <td>
                            {bookingDetail.DepFrom ?? "From"} -{" "}
                            {bookingDetail.ArrTo ?? "To"}
                          </td>
                          <td>{bookingDetail.TripType ?? "Flight Type"}</td>
                          <td>{bookingDetail.TotalPax ?? "Total Pax"}</td>
                          <td>
                            {bookingDetail?.activity[0]?.actionRef ??
                              "Booked By"}{" "}
                          </td>
                          <td>
                            {bookingDetail?.created_at !== "" || "undefined"
                              ? format(
                                  new Date(bookingDetail?.created_at),
                                  "dd MMM yy hh:mm a"
                                )
                              : "Booked Date"}
                          </td>
                          <td>{bookingDetail?.Name || "Passenger Name"}</td>
                          <td>
                            {commaNumber(bookingDetail.InvoicePrice) ??
                              "Gross Cost"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>

                <Grid container>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        width: "100%",
                        my: 3,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        borderTop: "1px solid var(--primary-color)",
                        marginTop: "30px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          my: 3,
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",

                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "15px", color: "#222222" }}
                        >
                          Showing Results 1 - 10 of 20
                        </Typography>
                        <ThemeProvider theme={theme}>
                          <Stack spacing={2}>
                            <Pagination
                              size="small"
                              count={pageCount}
                              onChange={handlePageChange}
                              color="primary"
                            />
                          </Stack>
                        </ThemeProvider>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
                width: "70vw",
                marginInline: "auto",
              }}
            >
              <Box
                style={{
                  width: "50%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Loader}
                  alt="loader"
                  style={{
                    width: "40%",
                    objectFit: "center",
                  }}
                />
              </Box>
            </Box>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default Queues;
