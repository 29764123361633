import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import AuthProvider from "./components/Contexts/AuthProvider";
import BookingRoute from "./components/Admin/BookingManagement/BookingRoute";
import Traveller from "./pages/Traveller/Traveller";
import AddTraveller from "./components/Traveller/AddTraveller";
import Deposite from "./pages/Deposite/Deposite";
import AddDeposite from "./components/Deposit/AddDeposite";
import BookingDetails from "./components/Admin/BookingDetails/BookingDetails";
import SearchResult from "./pages/SearchReslut/SearchResult";
import FlightInformation from "./pages/FligthInformation/FlightInformation";
import Header from "./components/Header/Header";
import RoundSearchResult from "./pages/SearchReslut/RoundSearchResult";
import RoundFlightUserInfo from "./components/FlightUserinfo/RoundFlightUserInfo";
import BankAccount from "./pages/BankAccount/BankAccount";
import AddBank from "./components/BankAccount/AddBank";
import FlightDetails from "./components/Admin/FlightDetails/FlightDetails";
import MyStaff from "./components/Admin/MyAccount/MyStaff";
import GeneralLedgerRoute from "./pages/General Ledger/GeneralLedgerRoute";
import GeneralLedgerReport from "./components/GeneralLedger/GeneralLedgerReport";
import SearchCountParent from "./components/SearchCountComponent/SearchCountParent/SearchCountParent";

import Admin from "./pages/Admin/Admin";
import SignUp from "./components/SignUp/SignUp";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import MultiCitySearchResult from "./components/MultiCitySearchResult/MultiCitySearchResult";
import Dashboard from "./components/Dashboard/Dashboard";
import Congratulation from "./components/Congratulation/Congratulation";
import NotFound from "./components/NotFound/NotFound";
import AdminLogin from "./components/AdminLogin/AdminLogin";
import SignIn from "./components/SignIn/SignIn";
import AdminAddBank from "./components/Admin/AdminBank/AdminAddBank";

import { Box } from "@mui/material";
import "./App.css";
import AdminDashboardSideBar from "./components/AdminDashboardSideBar/AdminDashboardSideBar";
import UserDashboardSideBar from "./components/UserDashboardSideBar/UserDashboardSideBar";
import ControlPanel from "./components/ControlPanel/ControlPanel";
import Others from "./components/Queues/Queues/Others";
import TransectionReport from "./components/UserReport/TransectionReport";
import LedgerReport from "./components/UserReport/LedgerReport";
import Markup from "./components/Markup/FlightMarkUp/Markup";
import MarkUpDetails from "./components/MarkUpDetails/FlightMarkUpDetails/MarkUpDetails";
import AdminTransectionReport from "./components/AdminReport/AdminTransectionReport";
import AdminLedgerReport from "./components/AdminReport/AdminLedgerReport";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Queues from "./components/Queues/Queues/Queues";
import LiveDashboard from "./components/Dashboard/LiveDashboard/LiveDashboard";
import PortalControl from "./components/Admin/ControlApi/PortalControl/PortalControl";
import AdminBankAccount from "./components/Admin/AdminBank/AdminBankAccount";
import QueuesDetail from "./components/Queues/Queues/QueuesDetail/QueuesDetail";
import MulticityFlightInfo from "./components/MultiCitySearchResult/MulticityFlightInfo";
import AgentManagement from "./components/Admin/AgentManagement/AgentManagement";
import AdminAccount from "./components/Admin/AdminAccount/AdminAccount";
import AdminAddStaff from "./components/Admin/AdminAccount/AdminAddStaff";
import PaymentManagement from "./components/Admin/PaymentManagement/PaymentManagement";
function App() {
  return (
    <Box>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            {/* user dashboard  start */}
            {/* <Route
              path="/user"
              element={
                // <PrivateRoute>
                <UserDashboardSideBar />
                // </PrivateRoute>
              }
            > */}
            <Route path="/user/dashboard" element={<Dashboard />} />
            {/* After searchResult start  */}
            <Route path="/user/searchresult" element={<SearchResult />} />
            <Route
              path="/user/roundsearchresult"
              element={<RoundSearchResult />}
            />
            <Route
              path="/user/multicityaftersearch"
              element={<MultiCitySearchResult />}
            />
            <Route
              path="/user/multicityflightinfo"
              element={<MulticityFlightInfo />}
            />
            {/* After searchResult end  */}
            {/* After Booking start */}
            <Route
              path="/user/flightinformation"
              element={<FlightInformation />}
            />
            <Route
              path="/user/roundflightinformation"
              element={<RoundFlightUserInfo />}
            />

            <Route
              path="/user/congratulation"
              element={
                // <PrivateRoute>
                <Congratulation />
                // </PrivateRoute>
              }
            />
            {/* After Booking end */}

            <Route path="/user/queues" element={<Queues />} />
            <Route path="/user/queuesdetail" element={<QueuesDetail />} />
            <Route path="/user/bookingdetails" element={<BookingDetails />} />
            <Route path="/user/traveller" element={<Traveller />} />
            <Route path="/user/addtraveller" element={<AddTraveller />} />

            <Route path="/user/mystaff" element={<MyStaff />} />
            <Route path="/user/others" element={<Others />} />
            {/* <Route path="/user/account" element={<Account />} /> */}
            <Route path="/user/bankaccount" element={<BankAccount />} />
            <Route path="/user/addbankaccount" element={<AddBank />} />
            <Route path="/user/deposit" element={<Deposite />} />
            <Route path="/user/adddeposit" element={<AddDeposite />} />

            <Route path="/user/transection" element={<TransectionReport />} />
            <Route path="/user/ledger" element={<LedgerReport />} />
            {/* </Route> */}
            {/* user dashboard  end */}

            {/* admin Dashboard start gh*/}

            <Route path="/admin" element={<AdminLogin />} />
            <Route path="/admin" element={<AdminDashboardSideBar />}>
              <Route path="/admin/dashboard" element={<LiveDashboard />} />
              <Route
                path="/admin/searchcount"
                element={<SearchCountParent />}
              />
              <Route path="/admin/portalcontrol" element={<PortalControl />} />
              <Route path="/admin/controlpanel" element={<ControlPanel />} />
              <Route path="/admin/markupdetails" element={<MarkUpDetails />} />
              <Route path="/admin/markup" element={<Markup />} />
              <Route
                path="/admin/agentmanagement"
                element={<AgentManagement />}
              />
              <Route
                path="/admin/paymentManagement"
                element={<PaymentManagement />}
              />
              <Route path="/admin/bookingroute" element={<BookingRoute />} />
              <Route path="/admin/account" element={<AdminAccount />} />
              <Route path="/admin/addstaff" element={<AdminAddStaff />} />

              <Route path="/admin/addbank" element={<AdminAddBank />} />
              <Route
                path="/admin/adminbankacc"
                element={<AdminBankAccount />}
              />

              <Route
                path="/admin/transection"
                element={<AdminTransectionReport />}
              />
              <Route path="/admin/ledger" element={<AdminLedgerReport />} />
            </Route>

            {/* user dashboard  */}

            {/* <Route path="/user" element={<UserDashboardSideBar />}>
              <Route path="/user/dashboard" element={<Dashboard />} />
              <Route path="/user/bookingroute" element={<BookingRoute />} />
              <Route path="/user/bookingdetails" element={<BookingDetails />} />
              <Route path="/user/traveller" element={<Traveller />} />
              <Route path="/user/mystaff" element={<MyStaff />} />
            </Route>

            <Route path="/test" element={<Test />} /> */}

            {/* //todo:home page */}

            {/* Quotes menu */}

            {/* <Route
              path="/account"
              element={
                <PrivateRoute>
                  <AdminAccount />
                </PrivateRoute>
              }
            />

            <Route
              path="/flightdetail"
              element={
                <PrivateRoute>
                  <FlightDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/mystaff"
              element={
                <PrivateRoute>
                  <MyStaff />
                </PrivateRoute>
              }
            />
            <Route
              path="/addstaff"
              element={
                <PrivateRoute>
                  <AddStaff />
                </PrivateRoute>
              }
            />

            <Route
              path="/bankaccount"
              element={
                <PrivateRoute>
                  <BankAccount />
                </PrivateRoute>
              }
            />
            <Route
              path="/addbankaccount"
              element={
                <PrivateRoute>
                  <AddBank />
                </PrivateRoute>
              }
            />

            <Route
              path="/congratulation"
              element={
                <PrivateRoute>
                  <Congratulation />
                </PrivateRoute>
              }
            /> */}
            {/* <Route
              path="/dashboard/queues/cancelqueues"
              element={<CancelQueues />}
            />
            <Route path="/dashboard/queues/others" element={<Others />} />
            <Route
              path="/dashboard/queues/queuesdetails"
              element={<QueuesDetail />}
            />

            <Route path="/dashboard/queues/onhold" element={<OnHold />} />
            <Route path="/dashboard/queues/pending" element={<Pending />} />
            <Route
              path="/dashboard/queues/InProcess"
              element={<InProcess />}
            />
            <Route
              path="/dashboard/queues/ticket"
              element={<Ticketed />}
            />
            <Route
              path="/dashboard/queues/expired"
              element={<Expired />}
            />
            <Route
              path="/dashboard/queues/cancelled"
              element={<Cancelled />}
            />
            <Route
              path="/dashboard/queues/unconfirmed"
              element={<Unconfirmed />}
            />
            <Route
              path="/dashboard/queues/RefundManagement"
              element={<RefundManagement />}
            />
            <Route
              path="/dashboard/queues/VoidManagement"
              element={<VoidManagement />}
            />
            <Route
              path="/dashboard/queues/ReissueManagement"
              element={<ReissueManagement />}
            /> */}

            {/* //todo:admin route */}

            <Route
              path="/admin/dashboard"
              element={
                <PrivateRoute>
                  <Admin />
                </PrivateRoute>
              }
            />

            <Route path="/admin/bookingManagement" element={<BookingRoute />} />

            {/*  //todo:search management section*/}
            <Route
              path="/admin/searchManagement"
              element={<SearchCountParent />}
            />
            <Route
              path="/admin/generalledgerroute"
              element={<GeneralLedgerRoute />}
            />
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </Box>
  );
}

export default App;
