import { Box, Container, Typography } from "@mui/material";
import React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";

const AdminHeader = () => {
  const [count, setCount] = React.useState(1);
  return (
    <Box my={2}>
      <Container>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              style={{
                color: "var(--primary-color)",
                fontSize: "20px",
                fontWeight: "500",
                height: "22px",
              }}
            >
              Ticket{" "}
              {/* <span
                style={{ color: "var(--secondary-color)", fontSize: "14px" }}
              >
                BD
              </span> */}
            </Typography>
            <Typography
              style={{
                color: "var(--primary-color)",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              Factory
            </Typography>
          </Box>
          <Box style={{}}>
            <NotificationsIcon
              style={{
                fontSize: "36px",
                color: "var(--primary-color)",
                position: "relative",
              }}
            />
            <Badge
              color="secondary"
              badgeContent={count}
              sx={{
                position: "absolute",
                top: "28px",

                "& .MuiBadge-badge": {
                  color: "var(--primary-color)",
                  backgroundColor: "var(--secondary-color)",
                },
              }}
            ></Badge>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AdminHeader;
