import { Grid, Box, Typography, Tooltip } from "@mui/material";
import React from "react";
import Transit from "./Transit";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";

const FlightDetails = ({ data }) => {
  return (
    <Grid container spacing={{ xs: 1, sm: 2 }}>
      <Grid item xs={5} sm={6} md={2.5}>
        <Box>
          <Box
            sx={{
              width: {
                xs: "30px",
                sm: "40px",
              },
              height: {
                xs: "30px",
                sm: "40px",
              },
            }}
          >
            <img
              src={
                data?.MarketingCarrier === "XY"
                  ? `https://airlineimages.s3.ap-southeast-1.amazonaws.com/128/XY.png`
                  : `https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${data?.MarketingCarrier}.png`
              }
              alt={data?.MarketingCarrier}
              width="100%"
              height="100%"
            />
          </Box>
          <Typography
            sx={{
              color: "var(--primary-color)",
              fontWeight: 500,
              fontSize: "12px",
            }}
            noWrap
          >
            {data?.MarketingCarrierName}
            <br />
            <span
              style={{
                color: "var(--fontcolor)",
              }}
            >
              {data?.MarketingCarrier} {data?.MarketingFlightNumber} &{" "}
              {data?.SegmentCode?.bookingCode}
            </span>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={7} sm={6} md={3.5}>
        <Typography fontSize={{ xs: 13, sm: 20 }}>
          <span
            style={{
              color: "var(--primary-color)",
            }}
          >
            {data?.DepLocation?.slice(0, -2)},{" "}
          </span>
          <span
            style={{
              color: "var(--primary-color)",
              fontSize: "12px",
            }}
          >
            {data?.DepFrom}
          </span>
          <br />
          <Tooltip title={`${data?.DepAirPort}`}>
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: { xs: 12, sm: 13 },
              }}
              noWrap
            >
              {data?.DepAirPort}
            </Typography>
          </Tooltip>

          <Typography
            style={{
              color: "var(--fontcolor)",
              fontSize: "12px",
            }}
          >
            {moment(data?.DepTime?.split("+")[0])?.format("DD MMM YYYY h:mmA")}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={5} sm={6} md={2.5} margin="auto">
        <Box textAlign="center">
          <Typography
            sx={{
              color: "var(--primary-color)",
              fontWeight: 500,
              fontSize: {
                xs: "10px",
                md: "12px",
              },
            }}
          >
            {data?.Duration}
          </Typography>
          <Box className="stop-bar-parent">
            <CircleIcon
              sx={{
                color: "var(--transit)",
                fontSize: "15px",
              }}
            />
            <Box className="stop-bar-line-details"></Box>
            <CircleIcon
              sx={{
                color: "var(--transit)",
                fontSize: "15px",
              }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={7} sm={6} md={3.5} textAlign="end">
        <Typography fontSize={{ xs: 13, sm: 20 }}>
          <span
            style={{
              color: "var(--primary-color)",
            }}
          >
            {data?.ArrLocation?.slice(0, -2)},{" "}
          </span>
          <span
            style={{
              color: "var(--primary-color)",
              fontSize: "12px",
            }}
          >
            {data?.ArrTo}
          </span>
          <br />

          <Tooltip title={`${data?.ArrAirPort}`}>
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: { xs: 12, sm: 13 },
              }}
              noWrap
            >
              {data?.ArrAirPort}
            </Typography>
          </Tooltip>

          <Typography
            style={{
              color: "var(--fontcolor)",
              fontSize: "12px",
            }}
          >
            {moment(data?.ArrTime?.split("+")[0])?.format("DD MMM YYYY h:mmA")}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FlightDetails;
