import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import PeopleIcon from "@mui/icons-material/People";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LanguageIcon from "@mui/icons-material/Language";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";

import "./LiveDashboard.css";
import AdminHeader from "../../Admin/AdminHeader/AdminHeader";

const LiveDashboard = () => {
  return (
    <Box className="dashboard-live">
      <AdminHeader />
      <Container>
        {/* -------------------------- */}
        <Grid container spacing={2}>
          <Grid item xs={8} md={0.5}>
            <Box
              style={{
                background: "var(--secondary-color)",
                borderRadius: "3px",
              }}
            >
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-nav" : "normal-nav"
                  }
                  style={{
                    boxSizing: "border-box",
                    margin: "12px 0px",
                  }}
                >
                  <AirplanemodeActiveIcon
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      //   background: "red",
                      //   borderRadius: "4px",
                    }}
                  />
                </NavLink>
              </Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-nav" : "normal-nav"
                  }
                  style={{
                    boxSizing: "border-box",
                    margin: "12px 0px",
                  }}
                >
                  <PeopleIcon
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      //   background: "red",
                      //   borderRadius: "4px",
                    }}
                  />
                </NavLink>
              </Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-nav" : "normal-nav"
                  }
                  style={{
                    boxSizing: "border-box",
                    margin: "12px 0px",
                  }}
                >
                  <TravelExploreIcon
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      //   background: "red",
                      //   borderRadius: "4px",
                    }}
                  />
                </NavLink>
              </Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-nav" : "normal-nav"
                  }
                  style={{
                    boxSizing: "border-box",
                    margin: "12px 0px",
                  }}
                >
                  <DarkModeIcon
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      //   background: "red",
                      //   borderRadius: "4px",
                    }}
                  />
                </NavLink>
              </Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-nav" : "normal-nav"
                  }
                  style={{
                    boxSizing: "border-box",
                    margin: "12px 0px",
                  }}
                >
                  <PersonSearchIcon
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      //   background: "red",
                      //   borderRadius: "4px",
                    }}
                  />
                </NavLink>
              </Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-nav" : "normal-nav"
                  }
                  style={{
                    boxSizing: "border-box",
                    margin: "12px 0px",
                  }}
                >
                  <LanguageIcon
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      //   background: "red",
                      //   borderRadius: "4px",
                    }}
                  />
                </NavLink>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} md={9}>
            <Box className="balance-transaction">
              <Typography style={{ color: "var(--secondary-color)" }}>
                Air Ticket Live Booking
              </Typography>
              <table style={{ background: "transparent" }}>
                <tr>
                  <th>Booking ID</th>
                  <th>Status</th>
                  <th>At</th>
                  <th>Company</th>
                  <th>Route</th>
                  <th>Type</th>
                  <th>Travel Date</th>
                  <th>Airlines</th>
                  <th>PAX</th>
                  <th>Net Cost</th>
                  <th>Balance</th>
                </tr>
                <tr>
                  <td>FFA3620</td>
                  <td>Hold</td>
                  <td>21/12/2022</td>
                  <td>PieCoder </td>
                  <td>DAC-DXB </td>
                  <td>Round way</td>
                  <td>21/12/2022</td>
                  <td>Emarates</td>
                  <td>01</td>
                  <td>46,300</td>
                  <td>10.00</td>
                </tr>
                <tr>
                  <td>FFA3620</td>
                  <td>Hold</td>
                  <td>21/12/2022</td>
                  <td>PieCoder </td>
                  <td>DAC-DXB </td>
                  <td>Round way</td>
                  <td>21/12/2022</td>
                  <td>Emarates</td>
                  <td>01</td>
                  <td>46,300</td>
                  <td>10.00</td>
                </tr>
                <tr>
                  <td>FFA3620</td>
                  <td>Hold</td>
                  <td>21/12/2022</td>
                  <td>PieCoder </td>
                  <td>DAC-DXB </td>
                  <td>Round way</td>
                  <td>21/12/2022</td>
                  <td>Emarates</td>
                  <td>01</td>
                  <td>46,300</td>
                  <td>10.00</td>
                </tr>
                <tr>
                  <td>FFA3620</td>
                  <td>Hold</td>
                  <td>21/12/2022</td>
                  <td>PieCoder </td>
                  <td>DAC-DXB </td>
                  <td>Round way</td>
                  <td>21/12/2022</td>
                  <td>Emarates</td>
                  <td>01</td>
                  <td>46,300</td>
                  <td>10.00</td>
                </tr>
                <tr>
                  <td>FFA3620</td>
                  <td>Hold</td>
                  <td>21/12/2022</td>
                  <td>PieCoder </td>
                  <td>DAC-DXB </td>
                  <td>Round way</td>
                  <td>21/12/2022</td>
                  <td>Emarates</td>
                  <td>01</td>
                  <td>46,300</td>
                  <td>10.00</td>
                </tr>
                <tr>
                  <td>FFA3620</td>
                  <td>Hold</td>
                  <td>21/12/2022</td>
                  <td>PieCoder </td>
                  <td>DAC-DXB </td>
                  <td>Round way</td>
                  <td>21/12/2022</td>
                  <td>Emarates</td>
                  <td>01</td>
                  <td>46,300</td>
                  <td>10.00</td>
                </tr>
              </table>
            </Box>
          </Grid>
          <Grid item xs={4} md={2.5}>
            <Box
              style={{
                background: "#D1E9FF",
                padding: "8px 10px",
                borderRadius: "3px",
              }}
              mb={1}
            >
              <Typography
                style={{ color: "var(--secondary-color)", fontSize: "10px" }}
              >
                Today Booking
              </Typography>
              <Typography
                style={{ color: "var(--secondary-color)", fontSize: "25px" }}
              >
                30
              </Typography>
              <Typography
                style={{
                  textAlign: "right",
                  color: "var(--white)",
                  fontSize: "10px",
                }}
              >
                <span
                  style={{
                    background: "var(--secondary-color)",
                    padding: "4px 15px",
                    borderRadius: "3px",
                  }}
                >
                  {" "}
                  12 OCT 2022
                </span>
              </Typography>
            </Box>
            <Box
              style={{
                background: "#F7D800",
                padding: "8px 10px",
                borderRadius: "3px",
              }}
              mb={1}
            >
              <Typography
                style={{ color: "var(--secondary-color)", fontSize: "10px" }}
              >
                Current Issue on Process
              </Typography>
              <Typography
                style={{ color: "var(--secondary-color)", fontSize: "25px" }}
              >
                30
              </Typography>
              <Typography
                style={{
                  textAlign: "right",
                  color: "var(--white)",
                  fontSize: "10px",
                }}
              >
                <span
                  style={{
                    background: "var(--secondary-color)",
                    padding: "4px 15px",
                    borderRadius: "3px",
                  }}
                >
                  {" "}
                  12 OCT 2022
                </span>
              </Typography>
            </Box>
            <Box
              style={{
                background: "#525371",
                padding: "8px 10px",
                borderRadius: "3px",
              }}
              mb={1}
            >
              <Typography
                style={{ color: "var(--secondary-color)", fontSize: "10px" }}
              >
                Today Ticketed
              </Typography>
              <Typography
                style={{ color: "var(--secondary-color)", fontSize: "25px" }}
              >
                30
              </Typography>
              <Typography
                style={{
                  textAlign: "right",
                  color: "var(--white)",
                  fontSize: "10px",
                }}
              >
                <span
                  style={{
                    background: "var(--secondary-color)",
                    padding: "4px 15px",
                    borderRadius: "3px",
                  }}
                >
                  {" "}
                  12 OCT 2022
                </span>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* --------------------------------- */}

        <Grid container spacing={2} mt={3}>
          <Grid item xs={4} md={2.5}>
            <Box
              style={{
                background: "#D1E9FF",
                padding: "8px 10px",
                borderRadius: "3px",
              }}
              mb={1}
            >
              <Typography
                style={{ color: "var(--secondary-color)", fontSize: "10px" }}
              >
                Today Booking
              </Typography>
              <Typography
                style={{ color: "var(--secondary-color)", fontSize: "25px" }}
              >
                30
              </Typography>
              <Typography
                style={{
                  textAlign: "right",
                  color: "var(--white)",
                  fontSize: "10px",
                }}
              >
                <span
                  style={{
                    background: "var(--secondary-color)",
                    padding: "4px 15px",
                    borderRadius: "3px",
                  }}
                >
                  {" "}
                  12 OCT 2022
                </span>
              </Typography>
            </Box>
            <Box
              style={{
                background: "#F7D800",
                padding: "8px 10px",
                borderRadius: "3px",
              }}
              mb={1}
            >
              <Typography
                style={{ color: "var(--secondary-color)", fontSize: "10px" }}
              >
                Current Issue on Process
              </Typography>
              <Typography
                style={{ color: "var(--secondary-color)", fontSize: "25px" }}
              >
                30
              </Typography>
              <Typography
                style={{
                  textAlign: "right",
                  color: "var(--white)",
                  fontSize: "10px",
                }}
              >
                <span
                  style={{
                    background: "var(--secondary-color)",
                    padding: "4px 15px",
                    borderRadius: "3px",
                  }}
                >
                  {" "}
                  12 OCT 2022
                </span>
              </Typography>
            </Box>
            <Box
              style={{
                background: "#525371",
                padding: "8px 10px",
                borderRadius: "3px",
              }}
              mb={1}
            >
              <Typography
                style={{ color: "var(--secondary-color)", fontSize: "10px" }}
              >
                Today Ticketed
              </Typography>
              <Typography
                style={{ color: "var(--secondary-color)", fontSize: "25px" }}
              >
                30
              </Typography>
              <Typography
                style={{
                  textAlign: "right",
                  color: "var(--white)",
                  fontSize: "10px",
                }}
              >
                <span
                  style={{
                    background: "var(--secondary-color)",
                    padding: "4px 15px",
                    borderRadius: "3px",
                  }}
                >
                  {" "}
                  12 OCT 2022
                </span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={5}></Grid>
          <Grid item xs={8} md={0.5}>
            <Box
              style={{
                background: "var(--secondary-color)",
                borderRadius: "3px",
              }}
            >
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-nav" : "normal-nav"
                  }
                  style={{
                    boxSizing: "border-box",
                    margin: "12px 0px",
                  }}
                >
                  <AirplanemodeActiveIcon
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      //   background: "red",
                      //   borderRadius: "4px",
                    }}
                  />
                </NavLink>
              </Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-nav" : "normal-nav"
                  }
                  style={{
                    boxSizing: "border-box",
                    margin: "12px 0px",
                  }}
                >
                  <PeopleIcon
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      //   background: "red",
                      //   borderRadius: "4px",
                    }}
                  />
                </NavLink>
              </Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-nav" : "normal-nav"
                  }
                  style={{
                    boxSizing: "border-box",
                    margin: "12px 0px",
                  }}
                >
                  <TravelExploreIcon
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      //   background: "red",
                      //   borderRadius: "4px",
                    }}
                  />
                </NavLink>
              </Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-nav" : "normal-nav"
                  }
                  style={{
                    boxSizing: "border-box",
                    margin: "12px 0px",
                  }}
                >
                  <DarkModeIcon
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      //   background: "red",
                      //   borderRadius: "4px",
                    }}
                  />
                </NavLink>
              </Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-nav" : "normal-nav"
                  }
                  style={{
                    boxSizing: "border-box",
                    margin: "12px 0px",
                  }}
                >
                  <PersonSearchIcon
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      //   background: "red",
                      //   borderRadius: "4px",
                    }}
                  />
                </NavLink>
              </Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-nav" : "normal-nav"
                  }
                  style={{
                    boxSizing: "border-box",
                    margin: "12px 0px",
                  }}
                >
                  <LanguageIcon
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      //   background: "red",
                      //   borderRadius: "4px",
                    }}
                  />
                </NavLink>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box className="balance-transaction">
              <Typography style={{ color: "var(--secondary-color)" }}>
                Air Ticket Live Booking
              </Typography>
              <table style={{ background: "transparent" }}>
                <tr>
                  <th>Company</th>
                  <th>Route</th>
                  <th>Type</th>
                  <th>Travel Data</th>
                  <th>Pax</th>
                </tr>
                <tr>
                  <td>Pie coder</td>
                  <td>DXB-KB</td>
                  <td>Oneway</td>
                  <td>1/22/23</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>Pie coder</td>
                  <td>DXB-KB</td>
                  <td>Oneway</td>
                  <td>1/22/23</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>Pie coder</td>
                  <td>DXB-KB</td>
                  <td>Oneway</td>
                  <td>1/22/23</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>Pie coder</td>
                  <td>DXB-KB</td>
                  <td>Oneway</td>
                  <td>1/22/23</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>Pie coder</td>
                  <td>DXB-KB</td>
                  <td>Oneway</td>
                  <td>1/22/23</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>Pie coder</td>
                  <td>DXB-KB</td>
                  <td>Oneway</td>
                  <td>1/22/23</td>
                  <td>5</td>
                </tr>
              </table>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LiveDashboard;
