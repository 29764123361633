import { Grid, Box, Typography, Tooltip, Stack } from "@mui/material";
import React from "react";
import Transit from "./Transit";
import moment from "moment";

const FlightLayout = ({ flightData, allData }) => {
  const options = {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2 }}>
      <Grid item xs={5} sm={2.5} md={2.5}>
        <Box>
          <img
            src={
              flightData?.Segments[0]?.MarketingCarrier === "XY"
                ? `https://airlineimages.s3.ap-southeast-1.amazonaws.com/128/XY.png`
                : `https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${flightData?.Segments[0]?.MarketingCarrier}.png`
            }
            // className={`${flightData?.System?.toLowerCase()}`}
            alt={`${flightData?.Segments[0]?.MarketingCarrier}`}
            width="40px"
            height="40px"
          />
          <Tooltip title={`${flightData?.Segments[0]?.MarketingCarrierName}`}>
            <Typography
              fontSize={{ xs: "10px", md: "13px" }}
              sx={{
                width: "100%",
                cursor: "pointer",
                color: "var(--secondary-color)",
              }}
              noWrap
            >
              {`${flightData?.Segments[0]?.MarketingCarrierName}`}
            </Typography>
          </Tooltip>
          <Typography>
            {allData?.nonRefundable === true ? (
              <Typography
                sx={{
                  mt: { xs: 0, sm: 1 },
                  color: "var(--gray)",
                  fontSize: { xs: "10px", md: "12px" },
                  fontWeight: 500,
                }}
              >
                Refundable
              </Typography>
            ) : (
              <Typography
                sx={{
                  mt: 1,
                  color: "var(--gray)",
                  fontSize: { xs: "10px", md: "12px" },
                  fontWeight: 500,
                }}
              >
                Non Refundable
              </Typography>
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={7} sm={3.5} md={3.5}>
        <Box>
          <Stack direction="row" spacing={0.3} alignItems="center">
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: 500,
                fontSize: {
                  xs: "14px",
                  sm: "14px",
                  md: "15px",
                  lg: "15px",
                },
              }}
            >
              {moment(flightData?.Segments[0]?.DepTime?.split("+")[0])?.format(
                "h:mmA"
              )}
            </Typography>
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontWeight: 500,
                fontSize: {
                  xs: "16px",
                  sm: "16px",
                  md: "18px",
                  lg: "20px",
                },
              }}
            >
              {flightData?.Segments[0]?.DepLocation?.slice(0, -2)}
            </Typography>
          </Stack>

          <Tooltip
            title={`${flightData?.Segments[0]?.DepAirPort}${", "}${
              flightData?.Segments[0]?.DepFrom
            }`}
          >
            <Typography
              sx={{
                width: "100%",
                cursor: "pointer",
                color: "var(--secondary-color)",
                fontWeight: 400,
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "14px",
                },
              }}
              noWrap
            >
              {flightData?.Segments[0]?.DepAirPort}
              {", "}
              {flightData?.Segments[0]?.DepFrom}
            </Typography>
          </Tooltip>
          <Typography
            sx={{
              color: "var(--secondary-color)",
              fontWeight: 400,
              fontSize: {
                xs: "11px",
                sm: "11px",
                md: "13px",
              },
            }}
          >
            {new Date(flightData?.Segments[0]?.DepTime)?.toLocaleDateString(
              "en-UK",
              options
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={5} sm={2.5} md={2.5} mt={{ xs: 1.5, sm: "0" }}>
        <Transit transit={flightData} />
      </Grid>
      <Grid item xs={7} sm={3.5} md={3.5}>
        <Box>
          <Stack direction="row" spacing={0.3} alignItems="center">
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: 500,
                fontSize: {
                  xs: "14px",
                  sm: "14px",
                  md: "15px",
                  lg: "15px",
                },
              }}
            >
              {moment(
                flightData?.Segments[
                  flightData?.Segments?.length - 1
                ]?.ArrTime?.split("+")[0]
              )?.format("h:mmA")}
            </Typography>
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontWeight: 500,
                fontSize: {
                  xs: "16px",
                  sm: "16px",
                  md: "18px",
                  lg: "20px",
                },
              }}
            >
              {flightData?.Segments[
                flightData?.Segments?.length - 1
              ]?.ArrLocation?.slice(0, -2)}
            </Typography>
          </Stack>

          <Tooltip
            title={`${
              flightData?.Segments[flightData?.Segments?.length - 1]?.ArrAirPort
            }${", "}${
              flightData?.Segments[flightData?.Segments?.length - 1]?.ArrTo
            }`}
          >
            <Typography
              sx={{
                width: "100%",
                cursor: "pointer",
                color: "var(--secondary-color)",
                fontWeight: 400,
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "14px",
                },
              }}
              noWrap
            >
              {
                flightData?.Segments[flightData?.Segments?.length - 1]
                  ?.ArrAirPort
              }
              {", "}
              {flightData?.Segments[flightData?.Segments?.length - 1]?.ArrTo}
            </Typography>
          </Tooltip>
          <Typography
            sx={{
              color: "var(--secondary-color)",
              fontWeight: 400,
              fontSize: {
                xs: "11px",
                sm: "11px",
                md: "13px",
              },
            }}
          >
            {new Date(
              flightData?.Segments[flightData?.Segments?.length - 1]?.ArrTime
            )?.toLocaleDateString("en-UK", options)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(FlightLayout);
