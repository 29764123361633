import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import FlightIcon from "@mui/icons-material/Flight";
import seat1 from "../../images/Icon/bag.svg";
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import commaNumber from "comma-number";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import { AiFillCaretDown } from "react-icons/ai";
import anemy from "../../images/anemy.png";
import Loader from "../../images/loader/Render.gif";
import NotFound from "../../images/undraw/undraw_web_search_re_efla.svg";
import RoundFlightInfoDetails from "../FlightInfoDetails/RoundFlightInfoDetails";
import RoundFlightUserInfoGalileo from "./RoundFlightUserInfoGalileo";
import RoundFlightUserInfoSabre from "./RoundFlightUserInfoSabre";
import RoundFlightUserInfoFlyHub from "./RoundFlightUserInfoFlyHub";
import RoundFlightInformationDetails from "../RoundFlightInformationDetails/RoundFlightInformationDetails";
import "./RoundFlightUserInfo.css";
import Header from "../Header/Header";

const HtmlTooltip = styled(({ className, ...propss }) => (
  <Tooltip {...propss} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "crimson",
    maxWidth: 220,
    fontSize: "5px",
    borderRadius: "8px 0px 8px 0px",
  },
}));

const RoundFlightUserInfo = () => {
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(true);
  const [progress, setProgress] = useState(0);
  const totalFares = parseInt(location?.state?.roundData?.TotalFare);
  //todo: Baggage Information
  const [goAdultBagage, setGoAdultBagage] = useState();
  const [goChildBagage, setGoChildBagage] = useState();
  const [goInfatBagage, setGoInfatBagage] = useState();
  const [backAdultBagage, setBackAdultBagage] = useState();
  const [backChildBagage, setBackChildBagage] = useState();
  const [backInfantBagage, setBackInfantBagage] = useState();
  //todo: End Baggage Information end

  //todo: cupon
  const [coupon, setCoupon] = useState("");
  const [couponAppliedMessage, setCouponAppliedMessage] = useState({});
  //todo:end cupon

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const { adultCount, childCount, infant } = location.state;

  const timeconvarta1 =
    location?.state?.roundData?.segments?.go[0]?.arrivalTime;
  const ArrivalTime1 = new Date(timeconvarta1).toUTCString();
  const timeconvarta2 =
    location?.state?.roundData?.segments?.go[1]?.arrivalTime;
  const ArrivalTime2 = new Date(timeconvarta2).toUTCString();

  const [loadData, setLoadData] = useState([]);
  const navigate = useNavigate();

  let url;
  let body;
  let go = [];
  let back = [];

  // for segment go data

  location?.state?.roundData?.segments?.go?.map((data) =>
    go.push({
      departure: data?.departure,
      arrival: data?.arrival,
      dpTime: data?.departureTime,
      arrTime: data?.arrivalTime,
      bCode: data?.bookingcode,
      mCarrier: data.marketingcareer,
      mCarrierFN: data?.marketingflight,
      oCarrier: data?.operatingcareer,
      oCarrierFN: data.operatingflight,
    })
  );

  // for segment back data

  location?.state?.roundData?.segments?.back?.map((data) =>
    back.push({
      departure: data?.departure,
      arrival: data?.arrival,
      dpTime: data?.departureTime,
      arrTime: data?.arrivalTime,
      bCode: data?.bookingcode,
      mCarrier: data.marketingcareer,
      mCarrierFN: data?.marketingflight,
      oCarrier: data?.operatingcareer,
      oCarrierFN: data.operatingflight,
    })
  );

  if (location.state?.roundData?.system === "Sabre") {
    url = "https://api.flyjatt.com/v1/AirBooking/AirPrice.php";

    body = {
      adultCount: location.state.adultCount,
      childCount: location.state.childCount,
      infantCount: location.state.infant,
      segment: location.state?.roundData?.segment,
      tripType: location.state?.tripType,
      segments: {
        go: go,
        back: back,
      },
    };
  }

  useEffect(() => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (
          data?.OTA_AirLowFareSearchRS.PricedItineraries.PricedItinerary[0]
            .AirItineraryPricingInfo[0].ItinTotalFare.TotalFare.Amount !==
          totalFares
        ) {
          setLoadData(data);
          Swal.fire({
            icon: "success",
            imageAlt: "Custom image",
            title: "The price has gone up",
            confirmButtonText: "Please again aelect the flight...",
            confirmButtonColor: "var(--primary-color)",
          }).then(function () {
            navigate(-1);
          });
        } else if (data?.OTA_AirLowFareSearchRS?.PricedItinCount === 1) {
          setLoadData(data);
        } else {
          throw new Error(data);
        }
      })
      .catch((err) => {
        Swal.fire({
          imageUrl: NotFound,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "No Data Found",
          confirmButtonText: "Search Another Flights...",
          confirmButtonColor: "var(--primary-color)",
        }).then(function () {
          navigate(-1);
        });
      });
  }, [
    body.adultCount,
    body.childCount,
    body.infant,
    body.segment,
    body.tripType,
    navigate,
  ]);

  if (!isLoaded) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "40%",
              objectFit: "center",
            }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Header />
      <Box mt={4}>
        {" "}
        {Object.keys(loadData).length !== 0 ? (
          <Container sx={{ padding: { md: "0" } }}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={8} lg={8.5}>
                    <RoundFlightInformationDetails
                      flightData={location.state.roundData}
                    />
                    <Box mt={3}>
                      <RoundFlightUserInfoSabre
                        tripType={location.state?.tripType}
                        userData={location.state.roundData}
                        searchResult={loadData}
                        adultCount={location?.state?.adultCount}
                        childCount={location?.state?.childCount}
                        infant={location?.state?.infant}
                        isLoaded={isLoaded}
                        setIsLoaded={setIsLoaded}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3.5}>
                    {/* Price Breakdown  */}
                    <RoundFlightInfoDetails
                      loadData={loadData}
                      totalFares={totalFares}
                      searchData={location?.state?.roundData}
                      adultCount={location?.state?.adultCount}
                      childCount={location?.state?.childCount}
                      infant={location?.state?.infant}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "70vh",
              width: "70vw",
              marginInline: "auto",
            }}
          >
            <Box
              style={{
                width: "50%",
                height: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={Loader}
                alt="loader"
                style={{
                  width: "40%",
                  objectFit: "center",
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RoundFlightUserInfo;
