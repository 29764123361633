import { Container, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { addDays } from "date-fns/esm";
import { Typography } from "@mui/material";
import Slider from "react-slick";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useRef } from "react";

import "./RecentSearch.css";

export const RecentSearch = () => {
  const slider = useRef(null);
  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);

  // Sets the state of the const for the given page and state.
  const users = secureLocalStorage.getItem("user-info");
  let agentID = users?.user?.agentId;
  useEffect(() => {
    setIsLoaded(false);
    fetch(
      `https://api.flyjatt.com/v.1.0.0/SearchHistory/allHistory.php?agentId=FFA1926&page=1`
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data?.data);
        if (data?.data?.length > 0) {
          setIsLoaded(true);
          setSearchHistory(data?.data);
        }
      });
  }, [agentID]);
  const handleSearchBtn = (searchData) => {
    searchData.searchtype === "oneway"
      ? navigate("/user/searchresult", {
          state: {
            faddress: searchData?.DepFrom,
            toAddress: searchData?.ArrTo,
            fromSearchText: searchData?.DepAirport,
            toSearchText: searchData?.ArrAirport,
            departureDate:
              new Date(searchData?.depTime) <= new Date()
                ? addDays(new Date(), 1)
                : new Date(searchData?.depTime),
            adultCount: Number(searchData?.adult),
            childCount: Number(searchData?.child),
            infant: Number(searchData?.infant),
            tripType: searchData?.searchtype,
            fromSendData: searchData?.DepFrom,
            toSendData: searchData?.ArrTo,
            className: searchData?.class,
          },
        })
      : navigate("/dashboard/roundtrip", {
          state: {
            faddress: searchData?.DepFrom,
            toAddress: searchData?.ArrTo,
            fromSearchText: searchData.DepAirport,
            toSearchText: searchData.ArrAirport,
            departureDate:
              new Date(searchData?.depTime) <= new Date()
                ? addDays(new Date(), 1)
                : new Date(searchData?.depTime),
            returningDate:
              new Date(searchData?.returnTime) <= new Date()
                ? addDays(new Date(), 3)
                : new Date(searchData?.returnTime),
            adultCount: Number(searchData?.adult),
            childCount: Number(searchData?.child),
            infant: Number(searchData?.infant),
            tripType: searchData?.searchtype,
            fromSendData: searchData?.DepFrom,
            toSendData: searchData?.ArrTo,
            className: searchData?.class,
          },
        });
  };
  return (
    <Container>
      <Box sx={{ margin: "20px 0px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "26px",
              color: "var(--secondary-color)",
              fontWeight: "600",
              margin: "10px 0px",
              paddingLeft: "5px",
            }}
          >
            Recent Search
          </Typography>
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Box
              sx={{
                border: "1px solid var(--primary-color)",
                borderRadius: "50%",
                background: "var(--white)",
                color: "var(--primary-color)",
                height: "40px",
                width: "40px",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                cursor: "pointer",
                "&:hover": {
                  color: "var(--primary-color)",
                  background: "var(--white)",
                  justifyContent: "center",
                },
              }}
              onClick={() => slider?.current?.slickPrev()}
            >
              <ArrowBackIosIcon />
            </Box>
            <Box
              sx={{
                border: "1px solid var(--secondary-color)",
                borderRadius: "50%",
                background: "var(--secondary-color)",
                color: "var(--white)",
                height: "40px",
                width: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                transition: "all .5 ease",
                "&:hover": {
                  color: "var(--white)",
                  justifyContent: "end",
                },
              }}
              onClick={() => slider?.current?.slickNext()}
            >
              <ArrowForwardIosIcon />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            ".slick-slide > div": {
              margin: "1px 10px",
              width: "auto",
            },
            ".slick-list": {
              margin: "0px -10px",
            },
          }}
        >
          <Slider ref={slider} {...settings}>
            {searchHistory.map((item, index) => (
              <Box
                key={index}
                sx={{
                  width: "fit-content",
                  height: "fit-content",
                  background: "var(--white)",
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "fit-content",
                  }}
                >
                  <Typography
                    sx={{
                      width: "fit-content",
                      fontSize: "14px",
                      fontWeight: "500",
                      padding: "5px",
                    }}
                  >
                    {item?.searchtype?.toUpperCase() || "SearchType"}
                  </Typography>

                  <FlightTakeoffIcon
                    style={{
                      fontSize: "14px",
                      height: "20px",
                      width: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      backgroundColor: "var(--primary-color)",
                      color: "var(--white)",
                      padding: "2px",
                      margin: "5px",
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    width: "fit-content",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "var(--secondary-color)",
                    paddingLeft: "5px",
                  }}
                >
                  {`${item?.DepFrom} - ${item?.ArrTo}`}
                </Typography>
                <Typography
                  sx={{
                    width: "fit-content",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "var(--primary-color)",
                    paddingLeft: "5px",
                  }}
                >
                  {`${item?.depTime} ${
                    item?.returnTime ? "- " + item?.returnTime : ""
                  }`}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "fit-content",
                  }}
                >
                  <Typography
                    sx={{
                      width: "fit-content",
                      fontSize: "14px",
                      fontWeight: "500",
                      paddingLeft: "5px",
                    }}
                  >
                    {item?.adult > 0 ? `${item.adult} ADULT` : null}
                    {item?.child > 0 ? `${item?.child} CHILDREN` : null}
                    {item?.infant > 0 ? `${item?.infant} INFANT` : null}
                  </Typography>

                  <Box
                    sx={{
                      color: "var(--primary-color)",
                      textDecoration: "underline",
                      paddingRight: "5px",
                    }}
                    onClick={() => handleSearchBtn(item)}
                  >
                    Search
                  </Box>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </Container>
  );
};
