import {
  Box,
  ClickAwayListener,
  Grid,
  Typography,
  Button,
  Collapse,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import GroupsIcon from "@mui/icons-material/Groups";
import ServerDown from "../../images/undraw/undraw_server_down_s-4-lk.svg";
import flightData from "../flightData";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto #003566",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "var(--primary-color)",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "var(--secondary-color)",
  },
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
const Oneway = ({
  tripType,
  iconColor,
  bgColor,
  borderColor,
  faddress,
  setfaddress,
  toAddress,
  setToAddress,
  fromSearchText,
  setFromSearchText,
  fromSendData,
  setFromSendData,
  toSendData,
  setToSendData,
  toSearchText,
  setToSearchText,
  departureDate,
  setDepartureDate,
  setValue,
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infant,
  setInfant,
  result,
  setResult,
  className,
  handleClassName,
  travelDate,
  setTravelDate,
  to,
  setTo,
  from,
  setFrom,
  directFlightOnly,
  changeFrom,
  setChangeFrom,
  handleSearch,
}) => {
  const data = flightData; // json data from flight Data

  const initialData = [
    {
      code: "RUH",
      name: "King Khaled Intl ",
      Address: "Riyadh,SAUDI ARABIA",
    },
    { code: "JED", name: "Jeddah Intl ", Address: "Jeddah,SAUDI ARABIA" },
    {
      code: "ABT",
      name: "Al Aqiq Airport",
      Address: "Al Baha,SAUDI ARABIA",
    },
    {
      code: "AHB",
      name: "Abha Intl Airport",
      Address: "Abha,SAUDI ARABIA",
    },
    { code: "AJF", name: "JOUF Airport", Address: "Al Jouf,SAUDI ARABIA" },
    {
      code: "AQI",
      name: "Qaisumah Intl Airport",
      Address: "Qaisumah,SAUDI ARABIA",
    },
    { code: "DHA", name: "Dhahran Intl ", Address: "Dhahran,SAUDI ARABIA" },
    {
      code: "DMM",
      name: "King Fahad Intl Airport",
      Address: "Dammam,SAUDI ARABIA",
    },
    {
      code: "DWD",
      name: "Dawadmi Intl Airport",
      Address: "Dawadmi Intl Airport,SAUDI ARABIA",
    },
  ];
  //todo: users section
  const [users, setUsers] = useState("");
  useEffect(() => {
    const users = secureLocalStorage.getItem("user-info");
    if (users) {
      setUsers(users);
    }
  }, []);
  // todo: end of users section
  //todo: is Click state
  const [click, setClick] = useState(false);
  //todo: end of click state
  const [fromSuggest, setFromSuggest] = useState(initialData);
  const [toSuggest, setToSuggest] = useState(initialData);

  const [open, setOpen] = useState(false);
  const [openDate, setOpenDate] = useState(false);

  const navigate = useNavigate();

  const [openFrom, setOpenFrom] = useState(false);
  const [openTo, setOpenTo] = useState(false);

  const handleClickAway = () => {
    setOpenFrom(false);
    setOpenTo(false);
    setOpenDate(false);
    setOpen(false);
    setResult(adultCount + childCount + infant);
  };

  const formOnChange = (e) => {
    setOpen(false);
    const searchvalue = e.target.value;

    if (searchvalue.length > 2) {
      const suggestion = data.filter((item) =>
        item.code.toLowerCase().includes(searchvalue.toLowerCase())
      );
      setFromSuggest(suggestion);
      if (suggestion.length === 0) {
        const suggestion = data.filter(
          (item) =>
            item.code.toLowerCase().includes(searchvalue.toLowerCase()) ||
            item.Address.toLowerCase().includes(searchvalue.toLowerCase())
        );
        setFromSuggest(suggestion);
      }
    } else {
      setFromSuggest(initialData);
    }
  };

  const fromSuggestedText = (name, code, address) => {
    setFromSendData(code);
    setFromSearchText(`${name} [${code}]`);
    setFromSuggest([]);
    setfaddress(address);
    setOpen(false);
    setOpenFrom(false);
    setOpenTo(true);
  };

  const toOnChange = (e) => {
    const searchvalue = e.target.value;
    if (searchvalue.length > 2) {
      const suggestion = data.filter((item) =>
        item.code.toLowerCase().includes(searchvalue.toLowerCase())
      );
      setToSuggest(suggestion);
      if (suggestion.length === 0) {
        const suggestion = data.filter(
          (item) =>
            item.code.toLowerCase().includes(searchvalue.toLowerCase()) ||
            item.Address.toLowerCase().includes(searchvalue.toLowerCase())
        );
        setToSuggest(suggestion);
      }
    } else {
      setToSuggest(initialData);
    }
  };
  const toSuggestedText = (name, code, address) => {
    setToSendData(code);
    setToSearchText(`${name} [${code}]`);
    setToSuggest([]);
    setToAddress(address);
    setOpenTo(false);
    setTimeout(() => setOpenDate(true), 200);
  };

  const fromGetSuggetion = () => {
    return (
      <Box
        style={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          sx={{
            maxHeight: "230px",
            overflowY: "auto",
            background: "#fff",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            "&::-webkit-scrollbar": { width: "5px" },
          }}
        >
          {fromSuggest.length !== 0 ? (
            fromSuggest.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    paddingLeft: "20px",
                    paddingRight: "10px",
                    backgroundColor: "var( --secondary-color)",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      backgroundColor: "var(--primary-color)",
                      color: "var(--white)",
                    },
                    "&:hover .address": { color: "var(--white)" },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      fromSuggestedText(
                        ` ${item.name}`,
                        ` ${item.code} `,
                        `${item.Address}`
                      );
                    }} //suggest to display name select with multiple data pass parameter
                  >
                    <Box>
                      <Typography
                        className="address"
                        sx={{
                          fontSize: "12px",
                          color: "var(--primary-color)",
                          display: "block",
                          textAlign: "left",
                          fontWeight: "500",
                        }}
                      >
                        {item.Address}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "11px",
                          display: "block",
                          textAlign: "left",
                          color: "var(--white)",
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "13px",
                          display: "block",
                          textAlign: "left",
                          paddingRight: "5px",
                          color: "#999",
                          fontWeight: "600",
                        }}
                      >
                        {item.code}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle-2"
                style={{
                  color: "#DC143C",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const toGetSuggetion = () => {
    return (
      <Box
        style={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            maxHeight: "230px",
            overflowY: "auto",
            background: "#fff",
            "&::-webkit-scrollbar": { width: "5px" },
          }}
        >
          {toSuggest.length !== 0 ? (
            toSuggest.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    paddingLeft: "20px",
                    paddingRight: "5px",
                    backgroundColor: "var( --secondary-color)",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      backgroundColor: "var(--primary-color)",
                      color: "var(--white)",
                    },
                    "&:hover .address": { color: "var(--white)" },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() =>
                      toSuggestedText(
                        ` ${item.name}`,
                        `${item.code}`,
                        `${item.Address}`
                      )
                    } //suggest to display name select with multiple data pass parameter
                  >
                    <Box>
                      <Typography
                        className="address"
                        sx={{
                          fontSize: "12px",
                          color: "var(--primary-color)",
                          display: "block",
                          textAlign: "left",
                          fontWeight: "500",
                        }}
                      >
                        {item.Address}
                      </Typography>
                      <span
                        style={{
                          fontSize: "11px",
                          display: "block",
                          color: "var(--white)",
                          textAlign: "left",
                        }}
                      >
                        {item.name}
                      </span>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          paddingRight: "10px",
                          display: "block",
                          textAlign: "left",
                          color: "#999",
                          fontWeight: "600",
                        }}
                      >
                        {item.code}
                      </span>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle2"
                style={{
                  color: "#DC143C",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };
  // SearchingField End

  // Opens the dialog when the user clicks.
  const handleClickOpen = () => {
    setOpen((prev) => !prev);
    setOpenFrom(false);
    setOpenTo(false);
    setOpenDate(false);
  };

  // Closes the child process.
  const handleClose = () => {
    setOpen(false);
    setOpenFrom(false);
    setOpenTo(false);
    setOpenDate(false);
    setResult(adultCount + childCount + infant);
  };

  // Sets the number of children.
  function adultInclement(e) {
    e.preventDefault();
    if (adultCount < 9 - (childCount + infant)) {
      setAdultCount(adultCount + 1);
    }
  }

  // Decrement the count of children.
  function adultDecrement(e) {
    e.preventDefault();
    if (adultCount > 1) {
      setAdultCount(adultCount - 1);
      if (infant === adultCount) {
        if (infant > 1) {
          setInfant(infant - 1);
        }
      }
    }
  }

  function childInclement(e) {
    e.preventDefault();
    if (childCount < 9 - (adultCount + infant)) {
      setChildCount(childCount + 1);
    }
  }

  function childDecrement(e) {
    e.preventDefault();
    if (childCount > 0) {
      setChildCount(childCount - 1);
    }
  }

  // Increment the default value if the value is not a child.
  function infantIncrement(e) {
    e.preventDefault();
    if (infant < 9 - (adultCount + childCount)) {
      if (infant < adultCount) {
        setInfant(infant + 1);
      }
    }
  }

  // Decrement the infant by 1.
  function infantDecrement(e) {
    e.preventDefault();
    if (infant > 0) {
      setInfant(infant - 1);
    }
  }
  //todo: form Submit function
  // async function handleSearch(e) {
  //   e.preventDefault();
  //   // todo: setClick to true to start loader
  //   setClick(true);
  //   secureLocalStorage.setItem("search-data", {
  //     faddress,
  //     toAddress,
  //     fromSearchText,
  //     toSearchText,
  //     departureDate: format(new Date(from), "dd MMM yy"),
  //     adultCount,
  //     childCount,
  //     infant,
  //     tripType,
  //     fromSendData,
  //     toSendData,
  //     className,
  //     directFlightOnly,
  //   });

  //   if (click === false) {
  //     //todo: setClick false to stop loading
  //     setClick(false);
  //     navigate("/user/searchresult", {
  //       state: {
  //         faddress,
  //         toAddress,
  //         fromSearchText,
  //         toSearchText,
  //         departureDate: format(new Date(from), "dd MMM yy"),
  //         adultCount,
  //         childCount,
  //         infant,
  //         tripType,
  //         fromSendData,
  //         toSendData,
  //         className,
  //         directFlightOnly,
  //       },
  //     });
  //   } else {
  //     Swal.fire({
  //       imageUrl: ServerDown,
  //       imageWidth: 400,
  //       imageHeight: 200,
  //       imageAlt: "Custom image",
  //       html: `For any query.Please contact us at <strong> contact@flyjatt.com</strong> or Call <strong> +971509517784 </strong>`,
  //       confirmButtonText: "Search Again...",
  //       confirmButtonColor: "var(--primary-color)",
  //     }).then(() => {
  //       setClick(false);
  //       navigate(0);
  //     });
  //   }
  // }
  //todo: end of form Submit section
  const handleSelect = (date) => {
    setFrom(date);
    setChangeFrom(true);
    setOpenDate(false);
    setTimeout(() => setOpen(true), 200);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box style={{ position: "relative" }}>
        <form>
          <Grid
            sx={{
              height: "fit-content",
              width: "100%",
            }}
            container
            rowSpacing={{ md: 0, sm: 1, xs: 1 }}
            columnSpacing={0}
          >
            {/* //todo destination section */}
            <Grid
              container
              alignItems="center"
              md={4}
              sx={{
                height: "90px",
                borderRadius: "10px",
                backgroundColor: "var(--white)",
              }}
            >
              {/* //todo: Departure City section */}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  position: "relative",
                  border: "1px solid var(--gray)",
                  borderRadius: "10px 10px 0px 0px",
                  padding: "5px",
                  height: "50%",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    gap: "5px",
                  }}
                  onClick={() => {
                    setOpenFrom((prev) => !prev);
                    setOpenTo(false);
                    setOpenDate(false);
                    setOpen(false);
                  }}
                >
                  <Box
                    style={{
                      width: "10%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "25px",
                        width: "25px",
                        borderRadius: "50%",
                        backgroundColor: "var(--primary-color)",
                        color: "var(--white)",
                      }}
                    >
                      <FlightTakeoffIcon sx={{ fontSize: "16px" }} />
                    </Box>
                  </Box>
                  <Box
                    style={{
                      width: "90%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    {/* <Tooltip title={`Departure From: ${fromSearchText}`}> */}
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "var(--black)",
                        }}
                        noWrap
                      >
                        {fromSearchText}
                      </Typography>
                    </Box>
                    {/* </Tooltip> */}
                  </Box>
                </Box>
                <Collapse
                  in={openFrom}
                  timeout="auto"
                  unmountOnExit
                  sx={{ width: "100%" }}
                >
                  <Box
                    style={{
                      position: "absolute",
                      top: "90%",
                      left: "0",
                      right: "0",
                      width: "96%",
                      backgroundColor: "var( --secondary-color)",
                      height: "fit-content",
                      borderBottom: "1px solid var(  --gray)",
                      borderLeft: "1px solid var(  --gray)",
                      borderRight: "2px solid var(  --gray)",
                      borderRadius: "0px 0px 5px 5px",
                      zIndex: "999",
                      padding: "3px 5px 0px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "var(--secondary-color)",
                        zIndex: 10,
                      }}
                      backgroundColor="#fff"
                    >
                      <input
                        autoComplete="off"
                        autoFocus
                        onChange={formOnChange}
                        placeholder="Search a airport..."
                        className="customPlaceholder"
                        style={{
                          color: "var(--secondary-color)",
                          fontWeight: 500,
                          paddingLeft: "20px",
                          width: "100%",
                          height: "40px",
                          backgroundColor: "transparent",
                          border: "none",
                          outline: "none",
                        }}
                      />
                    </Box>
                    <Box>{fromGetSuggetion()}</Box>
                  </Box>
                </Collapse>
              </Grid>
              {/* //todo: Arrival City section */}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  position: "relative",
                  border: "1px solid var(--gray)",
                  borderTop: "none",
                  borderRadius: "0px 0px 10px 10px",
                  padding: "5px",
                  height: "50%",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    gap: "5px",
                  }}
                  onClick={() => {
                    setOpenFrom(false);
                    setOpenTo((prev) => !prev);
                    setOpenDate(false);
                    setOpen(false);
                  }}
                >
                  <Box
                    style={{
                      width: "10%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "25px",
                        width: "25px",
                        borderRadius: "50%",
                        backgroundColor: "var(--primary-color)",
                        color: "var(--white)",
                      }}
                    >
                      <FlightLandIcon sx={{ fontSize: "16px" }} />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "90%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    {/* <Tooltip title={`Arrival To: ${toSearchText}`}> */}
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "var(--black)",
                        }}
                      >
                        {toSearchText}
                      </Typography>
                    </Box>
                    {/* </Tooltip> */}
                  </Box>
                </Box>
                <Collapse
                  in={openTo}
                  timeout="auto"
                  unmountOnExit
                  sx={{ width: "100%" }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "90%",
                      left: "0",
                      width: "96%",
                      backgroundColor: "var( --secondary-color)",
                      height: "fit-content",
                      borderBottom: "1px solid var(  --gray)",
                      borderLeft: "1px solid var(  --gray)",
                      borderRight: "2px solid var(  --gray)",
                      borderRadius: "0px 0px 5px 5px",
                      zIndex: "999",
                      padding: "5px 5px 0",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "var(--secondary-color)",
                        zIndex: 10,
                      }}
                      backgroundColor="var(--white)"
                    >
                      <input
                        autoComplete="off"
                        autoFocus
                        onChange={toOnChange}
                        className="customPlaceholder"
                        placeholder="Search a airport..."
                        style={{
                          color: "var(--secondary-color)",
                          fontWeight: 500,
                          paddingLeft: "20px",
                          width: "100%",
                          height: "40px",
                          backgroundColor: "transparent",
                          border: "none",
                          outline: "none",
                        }}
                      />
                    </Box>
                    <Box>{toGetSuggetion()}</Box>
                  </Box>
                </Collapse>
              </Grid>
            </Grid>
            {/* //todo:Travel Date */}
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              sx={{
                position: "relative",
                padding: {
                  md: "0px 10px",
                  sm: "0px",
                  xs: "0px",
                },
                height: "90px",
              }}
            >
              <Box
                style={{
                  border: "1px solid var(--gray)",
                  borderRadius: "10px",
                  background: "var(--white)",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  style={{
                    borderRight: "1px solid var(--gray)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "50%",
                  }}
                  onClick={() => {
                    setTimeout(() => setOpenDate((prev) => !prev), 200);
                    setOpenFrom(false);
                    setOpenTo(false);
                    setOpen(false);
                  }}
                >
                  <Box
                    sx={{
                      width: "20%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                    }}
                  >
                    <CalendarMonthIcon
                      sx={{
                        fontSize: "35px",
                        color: "var(--primary-color)",
                        padding: "10px 0px 0px 10px",
                      }}
                    />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "80%",
                      height: "fit-content",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--secondary-color)",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {`${format(new Date(from), "MMMM")}`}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--secondary-color)",
                        fontSize: "30px",
                        fontWeight: "500",
                        lineHeight: "1",
                      }}
                    >
                      {`${format(new Date(from), "dd")}`}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {`${format(new Date(from), "EEEE")}`}
                    </Typography>
                  </Box>
                </Box>
                {/* //todo: return date section */}
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "50%",
                  }}
                  onClick={() => setValue("return")}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CalendarMonthIcon
                      sx={{
                        fontSize: "35px",
                        color: "var(--primary-color)",
                        padding: "10px 0px 0px 10px",
                      }}
                    />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "center",
                      width: "100%",
                      height: "50%",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--secondary-color)",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Add Return
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {openDate && (
                <Box>
                  <Calendar
                    color="#ffa84d"
                    date={new Date(from)}
                    onChange={handleSelect}
                    months={1}
                    minDate={new Date()}
                    className={"dashboard-calendar"}
                  />
                </Box>
              )}
            </Grid>
            {/* //todo: Passenger Box section */}
            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              style={{
                position: "relative",
                padding: {
                  md: "0 5px 0 0",
                  sm: "0px",
                  xs: "0px",
                },
                height: "90px",
                zIndex: "9",
              }}
            >
              <Box
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    background: "var(--white)",
                  }}
                  onClick={handleClickOpen}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "50%",
                      border: "1px solid var(--gray)",
                      borderRadius: "10px 10px 0 0",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AirlineSeatReclineNormalIcon
                      sx={{
                        fontSize: "35px",
                        color: "var(--primary-color)",
                        padding: "0px 0px 0px 10px",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "var(--secondary-color)",
                        fontSize: "14px",
                        fontWeight: "500",
                        padding: "0px 0px 0px 10px",
                      }}
                    >
                      {`${
                        className === "S"
                          ? "Premium Economy"
                          : className === "C"
                          ? "Business"
                          : className === "J"
                          ? "Premium Business"
                          : className === "P"
                          ? "First Class"
                          : "Economy"
                      }`}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "50%",
                      border: "1px solid var(--gray)",
                      borderTop: "none",
                      borderRadius: "0px 0px 10px 10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <GroupsIcon
                      sx={{
                        fontSize: "35px",
                        color: "var(--primary-color)",
                        padding: "0px 0px 0px 10px",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "var(--secondary-color)",
                        fontSize: "14px",
                        fontWeight: "500",
                        padding: "0px 0px 0px 10px",
                      }}
                    >
                      {result} Traveler
                    </Typography>
                  </Box>
                </Box>
                {open && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: { lg: "110%", md: "110%", sm: "100%", xs: "100%" },
                      right: "0px",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "var(--secondary-color)",
                        padding: "15px",
                        overflow: "hidden",
                        width: "300px",
                        border: "1px solid var(--gray)",
                        borderRadius: "10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "start",
                      }}
                    >
                      <Box>
                        <Box>
                          <Typography
                            style={{
                              textAlign: "left",
                              marginBottom: "5px",
                              color: "var(--white)",
                              fontFamily: "poppins",
                              fontWeight: "500",
                            }}
                          >
                            Passenger
                          </Typography>
                        </Box>

                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          height="25px"
                          mb={1}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <button
                              onClick={adultDecrement}
                              style={{
                                backgroundColor: "var(--primary-color)",
                                color: "var(--white)",
                                border: "none",
                                width: "25px",
                                height: "25px",
                                fontSize: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "2px",
                              }}
                            >
                              -
                            </button>
                            <h5 style={{ color: "var(--white)" }}>
                              {adultCount}
                            </h5>
                            <button
                              onClick={adultInclement}
                              style={{
                                backgroundColor: "var(--primary-color)",
                                color: "var(--white)",
                                border: "none",
                                width: "25px",
                                height: "25px",
                                fontSize: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "2px",
                              }}
                            >
                              +
                            </button>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "start",

                              flexDirection: "column",
                              color: "var(--white)",
                            }}
                          >
                            <Typography
                              sx={{ color: "var(--white)", fontSize: "13px" }}
                            >
                              Adult (12+ Years)
                            </Typography>
                          </Box>
                        </Stack>

                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          height="25px"
                          mb={1}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            <button
                              onClick={childDecrement}
                              style={{
                                backgroundColor: "var(--primary-color)",
                                color: "var(--white)",
                                border: "none",
                                width: "25px",
                                height: "25px",
                                fontSize: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "2px",
                              }}
                            >
                              -
                            </button>
                            <h5 style={{ color: "var(--white)" }}>
                              {childCount}
                            </h5>
                            <button
                              onClick={childInclement}
                              style={{
                                backgroundColor: "var(--primary-color)",
                                color: "var(--white)",
                                border: "none",
                                width: "25px",
                                height: "25px",
                                fontSize: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "2px",
                              }}
                            >
                              +
                            </button>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "start",
                              flexDirection: "column",
                              color: "var(--white)",
                            }}
                          >
                            <Typography
                              sx={{ color: "var(--white)", fontSize: "13px" }}
                            >
                              Children (2 - 11 years)
                            </Typography>
                          </Box>
                        </Stack>

                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          height="25px"
                        >
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <button
                              onClick={infantDecrement}
                              style={{
                                backgroundColor: "var(--primary-color)",
                                color: "var(--white)",
                                border: "none",
                                width: "25px",
                                height: "25px",
                                fontSize: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "2px",
                              }}
                            >
                              -
                            </button>
                            <h5 style={{ color: "var(--white)" }}>{infant}</h5>
                            <button
                              onClick={infantIncrement}
                              style={{
                                backgroundColor: "var(--primary-color)",
                                color: "var(--white)",
                                border: "none",
                                width: "25px",
                                height: "25px",
                                fontSize: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "2px",
                              }}
                            >
                              +
                            </button>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "start",
                              flexDirection: "column",
                              color: "var(--white)",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "13px", color: "var(--white)" }}
                            >
                              Infant (Under 2 years)
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                      <Box my={1} sx={{ width: "100%" }}>
                        <hr />
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            span: {
                              fontSize: 12,
                            },
                          }}
                        >
                          <FormControl>
                            <RadioGroup
                              value={className}
                              row
                              onChange={handleClassName}
                            >
                              <FormControlLabel
                                value="Y"
                                control={<BpRadio />}
                                label="Economy"
                                sx={{
                                  color: "var(--white)",
                                }}
                              />
                              <FormControlLabel
                                value="S"
                                control={<BpRadio />}
                                label="Premium Economy"
                                sx={{
                                  color: "var(--white)",
                                }}
                              />
                              <FormControlLabel
                                value="C"
                                control={<BpRadio />}
                                label="Business"
                                sx={{
                                  mr: 2.5,
                                  color: "var(--white)",
                                }}
                              />
                              <FormControlLabel
                                value="J"
                                control={<BpRadio />}
                                label="Premium Business"
                                sx={{
                                  color: "var(--white)",
                                }}
                              />
                              <FormControlLabel
                                value="P"
                                control={<BpRadio />}
                                label="First Class"
                                sx={{
                                  color: "var(--white)",
                                }}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box mt={2} style={{ textAlign: "right" }}>
                          <Button
                            size="small"
                            onClick={handleClose}
                            className="shine-effect"
                            style={{
                              backgroundColor: "var(--primary-color)",
                              color: "var(--white)",
                            }}
                          >
                            DONE
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
            {/* //todo: Search Button */}
            <Grid
              md={2}
              sm={12}
              xs={12}
              justifyContent="center"
              alignItems={"center"}
            >
              <Box
                sx={{
                  height: { md: "100%", sm: "70%", xs: "70%" },
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Tooltip title="Click To Search">
                  <Button
                    // type="submit"
                    onClick={handleSearch}
                    disabled={
                      faddress?.split(",")[0] === toAddress?.split(",")[0] &&
                      !click
                        ? true
                        : faddress?.split(",")[0] !==
                            toAddress?.split(",")[0] && click
                        ? true
                        : false
                    }
                    sx={{
                      height: { md: "100%", sm: "100%", xs: "100%" },
                      width: {
                        md: "90%",
                        sm: "100%",
                        xs: "100%",
                      },
                      borderRadius: "10px",
                      mt: { md: "0px", sm: "10px", xs: "10px" },
                      backgroundColor: "var(--primary-color)",
                      color: "var(--white)",
                      "&:hover": {
                        backgroundColor: "var(--primary-color)",
                        color: "var(--white)",
                        cursor: "pointer",
                      },
                    }}
                  >
                    {click ? (
                      "Wait..."
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "row", sm: "column" },
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <SearchIcon style={{ fontSize: "40px" }} />
                        <Typography
                          sx={{ fontSize: "16px", color: "var(--white)" }}
                        >
                          Search
                        </Typography>
                      </Box>
                    )}
                  </Button>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </ClickAwayListener>
  );
};

export default Oneway;
