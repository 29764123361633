import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import WorkIcon from "@mui/icons-material/Work";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Box,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AiFillCaretDown } from "react-icons/ai";
import commaNumber from "comma-number";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import seat1 from "../../images/Icon/bag.svg";
import bag from "../../images/Icon/seat.svg";
import anemy from "../../images/anemy.png";
import FlightUserInfo from "../../components/FlightUserinfo/FlightUserInfo";
import FlightUserInfoFlyHub from "../../components/FlightUserinfo/FlightUserInfoFlyHub";
import FlightUserInfoSabre from "../../components/FlightUserinfo/FlightUserInfoSabre";
import Loader from "../../images/loader/Render.gif";
import NotFound from "../../images/undraw/undraw_not_found_re_bh2e.svg";
import FlightInfoDetails from "../../components/FlightInfoDetails/FlightInfoDetails";
import FlightIcon from "@mui/icons-material/Flight";
import "./FlightInformation.css";
import { format } from "date-fns";
import Header from "../../components/Header/Header";

const HtmlTooltip = styled(({ className, ...propss }) => (
  <Tooltip {...propss} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "crimson",
    maxWidth: 220,
    fontSize: "5px",
    borderRadius: "8px 0px 8px 0px",
  },
}));

const FlightInformation = (props) => {
  const location = useLocation();
  const totalFares = parseInt(location?.state?.flightData?.TotalFare);
  const [loadData, setLoadData] = useState([]);
  const { adultCount, childCount, infant } = location.state;
  const [isLoaded, setIsLoaded] = useState(true);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  //todo: cupon
  const [coupon, setCoupon] = useState("");
  const [couponAppliedMessage, setCouponAppliedMessage] = useState({});
  //todo:end cupon
  //todo: Baggage Information
  const [adultBaggage, setAdultBaggage] = useState(0);
  const [childBaggage, setChildBaggage] = useState(0);
  const [infantBaggage, setInfantBaggage] = useState(0);
  //todo: End Baggage Information end

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  //console.log(location.state?.flightData);

  let url;
  let body;
  let segments = [];
  location.state?.flightData?.segments?.map((data) =>
    segments.push({
      departure: data.departure,
      arrival: data.arrival,
      dpTime: data.departureTime,
      arrTime: data.arrivalTime,
      bCode: data.bookingcode,
      mCarrier: data.marketingcareer,
      mCarrierFN: data.marketingflight,
      oCarrier: data.marketingcareer,
      oCarrierFN: data.operatingflight,
    })
  );

  if (location.state?.flightData?.system === "Sabre") {
    // url = "https://api.flyjatt.com/v.1.0.0/Sabre/AirPrice.php";
    url = "https://api.flyjatt.com/v1/AirBooking/AirPrice.php";
    body = {
      adultCount: adultCount,
      childCount: childCount,
      infantCount: infant,
      segment: location.state.flightData.segment,
      tripType: location.state.tripType,
      segments: segments,
    };
  }
  useEffect(() => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (
          data?.OTA_AirLowFareSearchRS.PricedItineraries.PricedItinerary[0]
            .AirItineraryPricingInfo[0].ItinTotalFare.TotalFare.Amount !==
          totalFares
        ) {
          setLoadData(data);
          Swal.fire({
            icon: "success",
            imageAlt: "Custom image",
            title: "The price has gone up",
            confirmButtonText: "Please again aelect the flight...",
            confirmButtonColor: "var(--primary-color)",
          }).then(function () {
            navigate(-1);
          });
        } else if (data?.OTA_AirLowFareSearchRS?.PricedItinCount === 1) {
          setLoadData(data);
        } else {
          throw new Error(data);
        }
      })
      .catch((err) => {
        //console.log(err);
        Swal.fire({
          imageUrl: NotFound,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "No Data Found",
          confirmButtonText: "Search Another Flights...",
          confirmButtonColor: "var(--primary-color)",
        }).then(function () {
          navigate(-1);
        });
      });
  }, [
    body.adultCount,
    body.childCount,
    body.infant,
    body.segment,
    body.tripType,
    navigate,
  ]);

  let adultPrice = 0,
    adultTaxPrice = 0,
    childPrice = 0,
    childTaxPrice = 0,
    infTaxPrice = 0,
    infPrice = 0,
    inTotalBaseFare = 0,
    totalTax = 0,
    totalFare = 0,
    totalBaseFare = 0,
    serviceFeeAdult = 0,
    serviceFeeChild = 0,
    serviceFeeInfant = 0,
    discount = 0,
    agentTotal = 0,
    limitTime;

  // if (Object.keys(loadData).length !== 0) {
  //   if (adultCount > 0) {
  //     adultPrice =
  //       location.state?.flightData?.system === "Sabre"
  //         ? loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
  //             .pricingInformation[0].fare.passengerInfoList[0].passengerInfo
  //             .passengerTotalFare.equivalentAmount * location?.state?.adultCount
  //         : location?.state?.flightData?.system === "Galileo"
  //         ? loadData?.airAirPriceResult?.airAirPricingSolution[0]?.attributes?.EquivalentBasePrice?.slice(
  //             3
  //           ) * location?.state?.adultCount
  //         : loadData?.Results[0].Fares[0].BaseFare * adultCount;

  //     adultTaxPrice =
  //       location.state?.flightData?.system === "Sabre"
  //         ? loadData.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
  //             .pricingInformation[0].fare.passengerInfoList[0].passengerInfo
  //             .passengerTotalFare.totalTaxAmount * location.state?.adultCount
  //         : location.state?.flightData?.system === "Galileo"
  //         ? loadData?.airAirPriceResult?.airAirPricingSolution[0]?.attributes?.Taxes?.slice(
  //             3
  //           ) *
  //             location.state?.adultCount +
  //           adultPrice
  //         : loadData?.Results[0].Fares[0].Tax * adultCount;
  //     serviceFeeAdult =
  //       location.state?.flightData?.system === "Sabre"
  //         ? 0
  //         : location.state?.flightData?.system === "Galileo"
  //         ? 0
  //         : loadData?.Results[0]?.Fares[0]?.ServiceFee
  //         ? loadData?.Results[0]?.Fares[0]?.ServiceFee
  //         : 0 * location.state?.adultCount;
  //     // //console.log(loadData);
  //   }
  //   if (childCount > 0) {
  //     childPrice =
  //       location.state.flightData?.system === "Sabre"
  //         ? loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
  //             .pricingInformation[0].fare.passengerInfoList[1].passengerInfo
  //             .passengerTotalFare.equivalentAmount * location.state?.childCount
  //         : location.state.flightData?.system === "Galileo"
  //         ? loadData.airAirPriceResult?.airAirPricingSolution[0]?.attributes.EquivalentBasePrice?.slice(
  //             3
  //           ) * location.state?.childCount
  //         : loadData?.Results[0].Fares[1].BaseFare * childCount;
  //     childTaxPrice =
  //       location.state.flightData?.system === "Sabre"
  //         ? loadData.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
  //             .pricingInformation[0].fare.passengerInfoList[1].passengerInfo
  //             .passengerTotalFare.totalTaxAmount * location.state?.childCount
  //         : location.state.flightData?.system === "Galileo"
  //         ? loadData?.airAirPriceResult?.airAirPricingSolution[0]?.attributes?.Taxes?.slice(
  //             3
  //           ) *
  //             location.state?.childCount +
  //           childPrice
  //         : loadData?.Results[0]?.Fares[1]?.Tax * location.state?.childCount;
  //     serviceFeeChild =
  //       location.state?.flightData?.system === "Sabre"
  //         ? 0
  //         : location.state?.flightData?.system === "Galileo"
  //         ? 0
  //         : loadData?.Results[0]?.Fares[1]?.ServiceFee
  //         ? loadData?.Results[0]?.Fares[1]?.ServiceFee
  //         : 0 * location.state?.childCount;
  //   }

  //   if (infant > 0) {
  //     infPrice =
  //       location.state.flightData?.system === "Sabre"
  //         ? loadData?.groupedItineraryResponse?.itineraryGroups[0]
  //             ?.itineraries[0]?.pricingInformation[0]?.fare.passengerInfoList[2]
  //             ?.passengerInfo?.passengerTotalFare?.equivalentAmount ||
  //           loadData?.groupedItineraryResponse?.itineraryGroups[0]
  //             ?.itineraries[0]?.pricingInformation[0]?.fare.passengerInfoList[1]
  //             ?.passengerInfo.passengerTotalFare.equivalentAmount *
  //             location.state?.infant
  //         : location.state.flightData?.system === "Galileo"
  //         ? loadData.airAirPriceResult?.airAirPricingSolution[0]?.attributes.EquivalentBasePrice?.slice(
  //             3
  //           ) * location.state?.infant
  //         : loadData?.Results[0]?.Fares[2]?.BaseFare ||
  //           loadData?.Results[0]?.Fares[1]?.BaseFare * location.state?.infant;

  //     infTaxPrice =
  //       location.state.flightData?.system === "Sabre"
  //         ? loadData.groupedItineraryResponse?.itineraryGroups[0]
  //             ?.itineraries[0]?.pricingInformation[0]?.fare
  //             ?.passengerInfoList[2]?.passengerInfo?.passengerTotalFare
  //             ?.totalTaxAmount ??
  //           loadData?.groupedItineraryResponse?.itineraryGroups[0]
  //             ?.itineraries[0].pricingInformation[1]?.fare?.passengerInfoList[1]
  //             ?.passengerInfo?.passengerTotalFare?.totalTaxAmount *
  //             location.state?.infant
  //         : location.state.flightData?.system === "Galileo"
  //         ? loadData?.airAirPriceResult?.airAirPricingSolution[0]?.attributes?.Taxes?.slice(
  //             3
  //           ) *
  //             location.state?.infant +
  //           infPrice
  //         : loadData?.Results[0]?.Fares[2]?.Tax ??
  //           loadData?.Results[0]?.Fares[1]?.Tax * location.state?.infant;
  //     serviceFeeInfant =
  //       location.state?.flightData?.system === "Sabre"
  //         ? 0
  //         : location.state?.flightData?.system === "Galileo"
  //         ? 0
  //         : loadData?.Results[0]?.Fares[2]?.ServiceFee
  //         ? loadData?.Results[0]?.Fares[2]?.ServiceFee
  //         : 0 * location.state?.infant;
  //   }

  //   totalTax =
  //     location.state?.flightData?.system === "Sabre"
  //       ? loadData.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
  //           .pricingInformation[0].fare.totalFare.totalTaxAmount
  //       : location.state.flightData?.system === "Galileo"
  //       ? loadData.airAirPriceResult?.airAirPricingSolution[0]?.attributes.Taxes?.slice(
  //           3
  //         )
  //       : adultTaxPrice + childTaxPrice + infTaxPrice;
  //   totalBaseFare =
  //     location.state.flightData?.system === "Sabre"
  //       ? adultPrice + childPrice + infPrice
  //       : location.state.flightData?.system === "Galileo"
  //       ? loadData?.airAirPriceResult?.airAirPricingSolution[0]?.attributes?.EquivalentBasePrice?.slice(
  //           3
  //         )
  //       : adultPrice + childPrice + infPrice;
  //   totalFare =
  //     location.state.flightData?.system === "Sabre"
  //       ? totalBaseFare + totalTax
  //       : location.state.flightData?.system === "Galileo"
  //       ? loadData?.airAirPriceResult?.airAirPricingSolution[0]?.attributes?.EquivalentBasePrice?.slice(
  //           3
  //         )
  //       : totalBaseFare +
  //         totalTax +
  //         serviceFeeAdult +
  //         serviceFeeChild +
  //         serviceFeeInfant;

  //   agentTotal =
  //     couponAppliedMessage?.status === "success"
  //       ? Number(location.state.agentFare - 100)
  //       : Number(location.state.agentFare);
  //   discount = Number(location.state.commission);

  //   limitTime =
  //     location.state.roundData?.system === "Sabre"
  //       ? new Date()
  //       : location.state.roundData?.system === "Galileo"
  //       ? new Date()
  //       : loadData?.Results
  //       ? loadData?.Results[0]?.LastTicketDate
  //       : new Date();
  // }
  const timeconvarta1 = location?.state?.flightData?.segments[0]?.departureTime;
  const departureTime1 = new Date(timeconvarta1).toUTCString();
  const timeconvarta2 = location?.state?.flightData?.segments[1]?.departureTime;
  const departureTime2 = new Date(timeconvarta2).toUTCString();

  if (!isLoaded) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "40%",
              objectFit: "center",
            }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Header />
      {Object.keys(loadData).length !== 0 ? (
        <Container sx={{ padding: { md: "0" } }}>
          <Grid container mt={4}>
            <Grid item xs={12} sm={9} md={12} lg={12}>
              <Grid container>
                <Grid item xs={12} sm={12} md={8} lg={8.5}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "22px",
                        color: "var(--secondary-color)",
                        fontWeight: 500,
                      }}
                    >
                      Flight Information Details
                    </Typography>
                    <Box>
                      {location?.state?.flightData?.segments.map(
                        (data, index, arr) => (
                          <Box my={2}>
                            <Grid container spacing={2}>
                              <Grid item sm={6} md={2.5}>
                                <Box
                                  width="50px"
                                  height="50px"
                                  borderRadius="50%"
                                  boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                                  overflow="hidden"
                                >
                                  <img
                                    src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${data?.marketingcareer}.png`}
                                    alt="flight logo"
                                    width="50px"
                                    height="50px"
                                  />
                                </Box>
                                <Typography
                                  sx={{
                                    color: "var(--primary-color)",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                  }}
                                >
                                  {data?.marketingcareerName}
                                  <br />
                                  <span
                                    style={{
                                      color: "var(--fontcolor)",
                                    }}
                                  >
                                    {data?.marketingcareer}{" "}
                                    {data?.marketingflight} &{" "}
                                    {data?.bookingcode}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item sm={6} md={3.5}>
                                <Typography>
                                  <span
                                    style={{
                                      color: "var(--primary-color)",
                                      fontSize: "20px",
                                    }}
                                  >
                                    {data?.departureLocation?.split(" ,")[0]},{" "}
                                  </span>
                                  <span
                                    style={{
                                      color: "var(--primary-color)",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {data?.departure}
                                  </span>
                                  <br />
                                  <Tooltip title={`${data?.departureAirport}`}>
                                    <Typography
                                      style={{
                                        color: "var(--secondary-color)",
                                        fontSize: "13px",
                                      }}
                                      noWrap
                                    >
                                      {data?.departureAirport}
                                    </Typography>
                                  </Tooltip>

                                  <span
                                    style={{
                                      color: "var(--fontcolor)",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {format(
                                      new Date(data?.departureTime?.toString()),
                                      "dd MMM yyyy hh:mm a"
                                    )}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item sm={6} md={2.5} margin="auto">
                                <Box textAlign="center">
                                  <Typography
                                    sx={{
                                      color: "var(--primary-color)",
                                      fontWeight: 500,
                                      fontSize: {
                                        xs: "12px",
                                        sm: "10px",
                                        md: "12px",
                                      },
                                    }}
                                  >
                                    {data?.flightduration}
                                  </Typography>
                                  <Box className="stop-bar-parent">
                                    <CircleIcon
                                      sx={{
                                        color: "var(--transit)",
                                        fontSize: "15px",
                                      }}
                                    />
                                    <Box className="stop-bar-line-details"></Box>
                                    <CircleIcon
                                      sx={{
                                        color: "var(--transit)",
                                        fontSize: "15px",
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item sm={6} md={3.5} textAlign="end">
                                <Typography>
                                  <span
                                    style={{
                                      color: "var(--primary-color)",
                                      fontSize: "20px",
                                    }}
                                  >
                                    {data?.arrivalLocation?.split(" ,")[0]},{" "}
                                  </span>
                                  <span
                                    style={{
                                      color: "var(--primary-color)",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {data?.arrival}
                                  </span>
                                  <br />

                                  <Tooltip title={`${data?.arrivalAirport}`}>
                                    <Typography
                                      style={{
                                        color: "var(--secondary-color)",
                                        fontSize: "13px",
                                      }}
                                      noWrap
                                    >
                                      {data?.arrivalAirport}
                                    </Typography>
                                  </Tooltip>

                                  <span
                                    style={{
                                      color: "var(--fontcolor)",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {format(
                                      new Date(data?.arrivalTime?.toString()),
                                      "dd MMM yyyy hh:mm a"
                                    )}
                                  </span>
                                </Typography>
                              </Grid>
                            </Grid>
                            {/* Transit time start  */}
                            <Box textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  fontSize: "12px",
                                  textAlign: "center",
                                  color: "var(--secondary-color)",
                                  display:
                                    index === arr.length - 1 ? "none" : "block",
                                }}
                              >
                                {index === 0 && (
                                  <Box
                                    sx={{
                                      width: "50%",
                                      margin: "auto",
                                      bgcolor: "var(--transit)",
                                      py: "3px",
                                      textAlign: "center",
                                      borderRadius: "25px",
                                    }}
                                  >
                                    Transit Time:{" "}
                                    {
                                      location?.state?.flightData?.transit
                                        ?.transit1
                                    }
                                  </Box>
                                )}
                                {index === 1 && (
                                  <Box
                                    sx={{
                                      width: "50%",
                                      margin: "auto",
                                      bgcolor: "var(--transit)",
                                      py: "3px",
                                      textAlign: "center",
                                      borderRadius: "25px",
                                    }}
                                  >
                                    Transit Time:{" "}
                                    {
                                      location?.state?.flightData?.transit
                                        ?.transit2
                                    }
                                  </Box>
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        )
                      )}
                    </Box>
                    <FlightUserInfoSabre
                      loadData={loadData}
                      flightData={location.state.flightData}
                      userData={location.state}
                      searchResult={loadData}
                      adultPrice={adultPrice}
                      childPrice={childPrice}
                      infPrice={infPrice}
                      adultTaxPrice={adultTaxPrice}
                      childTaxPrice={childTaxPrice}
                      infTaxPrice={infTaxPrice}
                      serviceFeeAdult={serviceFeeAdult}
                      serviceFeeChild={serviceFeeChild}
                      serviceFeeInfant={serviceFeeInfant}
                      inTotalBaseFare={inTotalBaseFare}
                      totalBaseFare={totalBaseFare}
                      totalTax={totalTax}
                      totalFare={totalFare}
                      limitTime={limitTime}
                      isLoaded={isLoaded}
                      setIsLoaded={setIsLoaded}
                      clientFare={location.state.clientFare}
                      coupon={coupon}
                      setCoupon={setCoupon}
                      couponAppliedMessage={couponAppliedMessage}
                      setCouponAppliedMessage={setCouponAppliedMessage}
                      adultBaggage={adultBaggage}
                      setAdultBaggage={setAdultBaggage}
                      childBaggage={childBaggage}
                      setChildBaggage={setChildBaggage}
                      infantBaggage={infantBaggage}
                      setInfantBaggage={setInfantBaggage}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3.5}>
                  {/*// todo: price breakdown section */}
                  <FlightInfoDetails
                    loadData={loadData}
                    totalFares={totalFares}
                    searchData={location?.state?.flightData}
                    adultCount={location?.state?.adultCount}
                    childCount={location?.state?.childCount}
                    infant={location?.state?.infant}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "70vh",
            width: "70vw",
            marginInline: "auto",
          }}
        >
          <Box
            style={{
              width: "50%",
              height: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={Loader}
              alt="loader"
              style={{
                width: "40%",
                objectFit: "center",
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default FlightInformation;
