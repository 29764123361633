import { Box } from "@mui/material";
import React from "react";
import Header from "../Header/Header";
import HomeSearchBox from "../HomeSearchBox/HomeSearchBox";
import { RecentSearch } from "../RecentSearch/RecentSearch";

import "./Dashboard.css";

const Dashboard = () => {
  const [type, setType] = React.useState("flight");
  const handleTypeChange = (event, newValue) => {
    setType(newValue);
  };
  return (
    <Box>
      <Header />
      <Box sx={{ marginTop: "50px" }}>
        <HomeSearchBox
          type={type}
          setType={setType}
          handleTypeChange={handleTypeChange}
        />
      </Box>
      {/* <RecentSearch /> */}
    </Box>
  );
};

export default Dashboard;
