import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useRef } from "react";
import Slider from "react-slick";
import img9 from "../../images/TrendingDeals/img1.webp";
import img8 from "../../images/TrendingDeals/img2.webp";
import img7 from "../../images/TrendingDeals/img3.webp";
import img6 from "../../images/TrendingDeals/img4.webp";
import img5 from "../../images/TrendingDeals/img5.webp";
import img4 from "../../images/TrendingDeals/img6.webp";
import img3 from "../../images/TrendingDeals/img7.webp";
import img2 from "../../images/TrendingDeals/img8.webp";
import img1 from "../../images/TrendingDeals/img9.webp";
import img0 from "../../images/TrendingDeals/img10.webp";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./MoreDeals.css";

const imgArr = [
  {
    id: 0,
    img: img0,

    name: "Riyadh",
  },
  {
    id: 1,
    img: img1,

    name: "Makkah",
  },
  {
    id: 2,
    img: img2,

    name: "Manama",
  },
  {
    id: 3,
    img: img3,
    name: "London",
  },
  {
    id: 4,
    img: img4,
    name: "Jeddah",
  },
  {
    id: 5,
    img: img5,
    name: "Istanbul",
  },
  {
    id: 6,
    img: img6,
    name: "Dubai",
  },
  {
    id: 7,
    img: img7,
    name: "AlUla",
  },
  {
    id: 8,
    img: img8,
    name: "Cairo",
  },
  {
    id: 9,
    img: img9,
    name: "Bangkok",
  },
];

export const MoreDeals = () => {
  const slider = useRef(null);
  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Container>
      <Box
        sx={{
          margin: "50px 0px",
        }}
        className="full-screen-slider"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "26px",
                color: "var(--secondary-color)",
                fontWeight: "600",
                paddingLeft: "5px",
              }}
            >
              More Deals For {new Date().getFullYear()}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                paddingLeft: "5px",
              }}
            >
              Some of our best deals on all Air ticket, Group fare and all
              Domestic Hotels
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Box
              sx={{
                border: "1px solid var(--primary-color)",
                borderRadius: "50%",
                background: "var(--white)",
                color: "var(--primary-color)",
                height: "40px",
                width: "40px",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                cursor: "pointer",
                "&:hover": {
                  color: "var(--primary-color)",
                  background: "var(--white)",
                  justifyContent: "center",
                },
              }}
              onClick={() => slider?.current?.slickPrev()}
            >
              <ArrowBackIosIcon />
            </Box>
            <Box
              sx={{
                border: "1px solid var(--secondary-color)",
                borderRadius: "50%",
                background: "var(--secondary-color)",
                color: "var(--white)",
                height: "40px",
                width: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                transition: "all .5 ease",
                "&:hover": {
                  color: "var(--white)",
                  justifyContent: "end",
                },
              }}
              onClick={() => slider?.current?.slickNext()}
            >
              <ArrowForwardIosIcon />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            ".slick-slide > div": {
              margin: "1px 5px",
              width: "auto",
            },
            ".slick-list": {
              margin: "0px -5px",
            },
          }}
        >
          <Slider ref={slider} {...settings}>
            {imgArr.map((item, index) => (
              <Box key={index} className="more-deals-slider-item">
                <Box
                  sx={{
                    display: "flex",
                    height: "350px",
                    borderRadius: "5px",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      padding: "0px 5px",
                      borderRadius: "5px",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <img
                      src={item.img}
                      alt="..."
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                    <span>{item.name}</span>
                  </Box>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </Container>
  );
};
